/**
 * AgentOnDuty API Documentation
 * The official AgentOnDuty API, documented with the OpenAPI 3.0 specification
 *
 * OpenAPI spec version: THE API VERSION IS NOW PROGRAMMATICALLY SET AND RETRIEVED! Use the public endpoint for reading the current version.
 * Contact: demo@synergogroup.net
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AddressDto } from './addressDto';
import { AgentProfileDto } from './agentProfileDto';
import { GeoLocationDto } from './geoLocationDto';

export interface CommonUserResponseDto { 
    id?: string;
    username?: string;
    firstName?: string;
    lastName?: string;
    displayImageUrl?: string;
    phoneNumber?: string;
    address?: AddressDto;
    /**
     * Available Roles:  * `SuperAdmin` - User having Platform administration responsibilities and access  * `Client` - Consumer users being direct clients of the platform  * `Agent` - Real estate agents using the platform to help them close more deals 
     */
    role?: CommonUserResponseDto.RoleEnum;
    agentProfile?: AgentProfileDto;
    isActive?: boolean;
    isEmailConfirmed?: boolean;
    location?: GeoLocationDto;
    /**
     * String interests as comma separated value
     */
    interests?: string;
    createdOn?: string;
    updatedOn?: string;
}
export namespace CommonUserResponseDto {
    export type RoleEnum = 'SuperAdmin' | 'Client' | 'Agent';
    export const RoleEnum = {
        SuperAdmin: 'SuperAdmin' as RoleEnum,
        Client: 'Client' as RoleEnum,
        Agent: 'Agent' as RoleEnum
    };
}