/**
 * AgentOnDuty API Documentation
 * The official AgentOnDuty API, documented with the OpenAPI 3.0 specification
 *
 * OpenAPI spec version: THE API VERSION IS NOW PROGRAMMATICALLY SET AND RETRIEVED! Use the public endpoint for reading the current version.
 * Contact: demo@synergogroup.net
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { UserAgentDto } from './userAgentDto';
import { UserMetadataDto } from './userMetadataDto';

export interface UserSessionDto { 
    id?: string;
    uuid?: string;
    type?: UserSessionDto.TypeEnum;
    userData?: UserMetadataDto;
    userRole?: string;
    status?: UserSessionDto.StatusEnum;
    ipAddress?: string;
    userAgent?: UserAgentDto;
    connectedOn?: string;
    disconnectedOn?: string;
}
export namespace UserSessionDto {
    export type TypeEnum = 'Credentials' | 'WS';
    export const TypeEnum = {
        Credentials: 'Credentials' as TypeEnum,
        WS: 'WS' as TypeEnum
    };
    export type StatusEnum = 'Active' | 'Dropped';
    export const StatusEnum = {
        Active: 'Active' as StatusEnum,
        Dropped: 'Dropped' as StatusEnum
    };
}