import {Observable, PartialObserver, Subscription} from 'rxjs';
import {OnDestroy} from '@angular/core';
import {IonViewDidLeave} from './ion-view-did-leave';
import {LoadingService} from '../providers/loading.service';

export class Unsubscriber implements OnDestroy, IonViewDidLeave {
  // TODO: App - Perhaps the loader showing should be waited upon.
  // TODO: App - What to do about the await uses? Especially the ones in call component (no loader). Perhaps try/catch them.
  subscriptions: Subscription[] = [];
  promises: Promise<any>[] = [];

  constructor(
    public loadingService?: LoadingService,
  ) {
  }

  subscribe<T>(
    observable: Observable<T>,
    nextOrObserver?: ((value: T) => void) | PartialObserver<T>,
    error?: (error: any) => void,
    complete?: () => void,
    loader = false,
  ): Subscription {
    let next: (value: T) => void;
    if (nextOrObserver) {
      if ((typeof nextOrObserver === 'function')) {
        next = nextOrObserver;
      } else {
        error = nextOrObserver.error;
        complete = nextOrObserver.complete;
        next = nextOrObserver.next;
      }
    }

    let subscription: Subscription = null;
    // Some subscribers may execute the handlers synchronously causing the subscription to be null at the time of execution.
    // For these situations we keep track of the execution time and skip the showing of the loader.
    let subscriptionExecuted = false;
    subscription = observable.subscribe((value: T) => {
      subscriptionExecuted = true;

      if (this.loadingService) {
        this.loadingService.hide(subscription).then();
      }

      if (next) {
        next(value);
      }
    }, (errorArg: any) => {
      subscriptionExecuted = true;

      if (this.loadingService) {
        this.loadingService.hide(subscription).then();
      }

      if (error) {
        error(errorArg);
      }
    }, () => {
      subscriptionExecuted = true;

      if (this.loadingService) {
        this.loadingService.hide(subscription).then();
      }

      if (complete) {
        complete();
      }
    });

    if (loader && !subscription.closed && !subscriptionExecuted) {
      if (this.loadingService) {
        this.loadingService.show(subscription).then();
      } else {
        console.error('No loading service');
      }
    }

    if (this.subscriptions.indexOf(subscription) === -1) {
      this.subscriptions.push(subscription);
    }

    return subscription;
  }

  then<TResult1, TResult2 = never>(
    promise: Promise<TResult1 | TResult2> | undefined | null,
    onFulfilled?: ((value: TResult1) => any) | undefined | null,
    onRejected?: ((reason: any) => any) | undefined | null,
    loader = false,
  ): Promise<TResult1 | TResult2> {
    if (!promise) {
      return;
    }

    if (loader) {
      if (this.loadingService) {
        this.loadingService.show(promise).then();
      } else {
        console.error('No loading service');
      }
    }

    if (this.promises.indexOf(promise) === -1) {
      this.promises.push(promise);
    }

    return promise.then((value: TResult1) => {
      if (this.loadingService) {
        this.loadingService.hide(promise).then();
      }

      if (this.promises.indexOf(promise) !== -1 && onFulfilled) {
        return onFulfilled(value);
      }
    }, (reason: any) => {
      if (this.loadingService) {
        this.loadingService.hide(promise).then();
      }

      if (this.promises.indexOf(promise) !== -1 && onRejected) {
        return onRejected(reason);
      }
    });
  }

  catch<TResult1, TResult2 = never>(
    promise: Promise<TResult1 | TResult2> | undefined | null,
    onRejected?: ((reason: any) => TResult2 | PromiseLike<TResult2>) | undefined | null,
    loader = true,
  ): Promise<TResult1 | TResult2> {
    if (!promise) {
      return;
    }

    if (loader) {
      if (this.loadingService) {
        this.loadingService.show(promise).then();
      } else {
        console.error('No loading service');
      }
    }

    if (this.promises.indexOf(promise) === -1) {
      this.promises.push(promise);
    }

    return promise.catch((reason: any) => {
      if (this.loadingService) {
        this.loadingService.hide(promise).then();
      }

      if (this.promises.indexOf(promise) !== -1 && onRejected) {
        return onRejected(reason);
      }
    });
  }

  cancelAllSubscriptionsAndPromises() {
    this.subscriptions.forEach(subscription => {
      if (this.loadingService) {
        this.loadingService.hide(subscription).then();
      }

      subscription.unsubscribe();
    });

    this.promises.forEach(promise => {
      if (this.loadingService) {
        this.loadingService.hide(promise).then();
      }
    });

    this.subscriptions = [];
    this.promises = [];
  }

  ionViewDidLeave(): void {
    this.cancelAllSubscriptionsAndPromises();
  }

  ngOnDestroy(): void {
    this.cancelAllSubscriptionsAndPromises();
  }
}
