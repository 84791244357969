import {Injectable} from '@angular/core';
import {Unsubscriber} from '../utils/unsubscriber';
import {AuthService} from './auth.service';
import {environment} from '../../environments/environment';
import {BehaviorSubject, Subject} from 'rxjs';
import {
  CallSessionDto as Session,
  ConversationDto as Chat,
  GenericClientToAgentConnectionDto as Connection,
  ListingDto as Listing,
  MessageDto as Message,
} from '../../../swagger-client';
import {LanguageService} from './language.service';
import {LoadingService} from './loading.service';
// import * as io from 'socket.io-client';
// import * as io from 'engine.io-client';

@Injectable({
  providedIn: 'root'
})
export class WsService extends Unsubscriber {
  socket;
  connected = new BehaviorSubject(false);
  message = new Subject<Message>();
  connection = new Subject<Connection>();
  chat = new Subject<Chat>();
  callJoined = new Subject<Session>();
  callRejected = new Subject<Session>();
  callFinished = new Subject<Session>();
  property = new Subject<Listing>();
  pushPromiseResolve: Function;

  timeouts: any[] = [];

  constructor(
    public auth: AuthService,
    public language: LanguageService,
    public loadingService: LoadingService,
  ) {
    super(loadingService);
  }

  init() {
    this.subscribe(this.auth.token, token => {
      if (token) {
        this.open();
        return;
      }

      this.close();
    }, () => {
      this.close();
    });

    this.subscribe(this.language.language, () => {
      this.authenticate();
    });
  }

  authenticate() {
    if (!this.socket || !this.connected.getValue()) {
      return;
    }

    this.socket.send(JSON.stringify({
      type: WsService.TypeEnum.WsAuthentication,
      payload: this.auth.token.getValue(),
      language: this.language.language.getValue(),
    }));
  }

  async push(id: string) {
    return new Promise((resolve, reject) => {
      this.pushPromiseResolve = resolve;

      if (environment.oneSignalTimeout) {
        setTimeout(() => {
          reject('OneSignal WS timeout');
          this.pushPromiseResolve = null;
        }, environment.oneSignalTimeout);
      }

      if (!this.socket || !this.connected.getValue()) {
        reject('Socket unavailable');
        this.pushPromiseResolve = null;
        return;
      }

      this.socket.send(JSON.stringify({
        type: WsService.TypeEnum.WsPush,
        payload: id,
        language: this.language.language.getValue(),
      }));
    });
  }

  open() {
    if (this.socket) {
      return;
    }

    if (!this.auth.token.getValue()) {
      return;
    }

    this.socket = new WebSocket(environment.wsUrl);

    if (!this.socket) {
      this.connected.next(false);

      return;
    }

    this.socket.addEventListener('open', () => {
      console.log('Socket: onopen');
      this.connected.next(true);

      this.authenticate();
    });

    this.socket.addEventListener('message', (event: MessageEvent) => {
      console.log('WS event:', event);

      try {
        const data: { isSuccess, type, payload } = JSON.parse(event.data);

        if (!data.type) {
          console.error('Could not understand WS message', event);
          return;
        }

        switch (data.type) {
          case WsService.TypeEnum.ConnectionCreated:
            const connection: Connection = data.payload;
            this.connection.next(connection);
            break;
          case WsService.TypeEnum.CallJoinedBySender:
            const callJoined: Session = data.payload;
            this.callJoined.next(callJoined);
            break;
          case WsService.TypeEnum.CallRejectedByRecipient:
            const callRejected: Session = data.payload;
            this.callRejected.next(callRejected);
            break;
          case WsService.TypeEnum.CallFinishedBySender:
          case WsService.TypeEnum.CallFinishedByRecipient:
            const callFinished: Session = data.payload;
            this.callFinished.next(callFinished);
            break;
          case WsService.TypeEnum.MessagePosted:
            const message: Message = data.payload;
            this.message.next(message);
            break;
          case WsService.TypeEnum.ConversationCreated:
            const chat: Chat = data.payload;
            this.chat.next(chat);
            break;
          case WsService.TypeEnum.WsAuthentication:
            if (!data.isSuccess) {
              console.error('Not authenticated', event);
            }
            break;
          case WsService.TypeEnum.WsPush:
            if (this.pushPromiseResolve) {
              this.pushPromiseResolve(data);
              this.pushPromiseResolve = null;
            }
            if (!data.isSuccess) {
              console.error('Push not registered', event);
            }
            break;
          case WsService.TypeEnum.ListingCreated:
            const property: Listing = data.payload;
            this.property.next(property);
            break;
          default:
            console.error('Could not understand WS message', event);
            break;
        }
      } catch (e) {
        console.error(e, event);
      }
    });

    this.socket.addEventListener('close', () => {
      console.log('Socket: onclose');
      this.connected.next(false);

      this.socket = null;

      this.timeouts.push(setTimeout(() => {
        this.open();
      }, Math.random() * environment.wsReconnectInterval));
    });

    // TODO: API - Perhaps implement full socket.io with socket.io-client on the API for polling transport support as well.
    /*
    this.socket = io(environment.wsUrl, {
      path: '/',
      transports: ['websocket', 'polling'], // default is the other way around
    });

    this.connected.next(this.socket.connected);

    [
      'connect',
      'connect_error',
      'connect_timeout',
      'error',
      'disconnect',
      'reconnect',
      'reconnect_attempt',
      'reconnecting',
      'reconnect_error',
      'reconnect_failed',
      'ping',
      'pong',
    ].forEach(event => {
      this.socket.on(event, (a, b, c) => {
        console.log(event, a, b, c);
        this.connected.next(this.socket ? this.socket.connected : false);
      });
    });
    */
  }

  close() {
    if (!this.socket) {
      return;
    }

    console.log('Socket: close');
    this.socket.close();
    this.socket = null;
  }

  cancelAllSubscriptionsAndPromises() {
    super.cancelAllSubscriptionsAndPromises();

    if (this.timeouts) {
      this.timeouts.forEach(timeout => clearTimeout(timeout));
    }
  }
}

export namespace WsService {
  export type TypeEnum =
    'conversation_created'
    | 'message_posted'
    | 'call_joined_by_sender'
    | 'call_rejected_by_recipient'
    | 'call_finished_by_sender'
    | 'call_finished_by_recipient'
    | 'connection_created'
    | 'phone_call'
    | 'ws_authentication'
    | 'ws_push'
    | 'listing_created';
  export const TypeEnum = {
    ConversationCreated: 'conversation_created' as TypeEnum,
    MessagePosted: 'message_posted' as TypeEnum,
    CallJoinedBySender: 'call_joined_by_sender' as TypeEnum,
    CallRejectedByRecipient: 'call_rejected_by_recipient' as TypeEnum,
    CallFinishedBySender: 'call_finished_by_sender' as TypeEnum,
    CallFinishedByRecipient: 'call_finished_by_recipient' as TypeEnum,
    ConnectionCreated: 'connection_created' as TypeEnum,
    PhoneCall: 'phone_call' as TypeEnum,
    WsAuthentication: 'ws_authentication' as TypeEnum,
    WsPush: 'ws_push' as TypeEnum,
    ListingCreated: 'listing_created' as TypeEnum,
  };
}
