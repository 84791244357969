import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router} from '@angular/router';
import {SplashService} from '../providers/splash.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SplashGuard implements CanActivate, CanLoad {
  constructor(
    public router: Router,
    public splash: SplashService,
  ) {
  }

  handler(
    guard: string,
    path: string,
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.splash.getSplashDone().then(done => {
      console.log('SplashGuard ' + guard + ' route:', path);
      console.log('SplashGuard ' + guard + ' done:', done);
      if (done) {
        this.router.navigate(['/app']).then();
        return false;
      } else {
        return true;
      }
    });
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    // state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.handler('canActivate', '/' + route.url.join('/'));
  }

  canLoad(
    route: Route,
    // segments: UrlSegment[],
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.handler('canLoad', route.path);
  }
}
