import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AnswerComponent} from './answer.component';
import {IonicModule} from '@ionic/angular';
import {FormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {DirectivesModule} from '../../directives/directives.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TranslateModule,
    DirectivesModule,
  ],
  declarations: [
    AnswerComponent,
  ],
  exports: [
    AnswerComponent,
  ],
})
export class AnswerModule {
}
