/**
 * AgentOnDuty API Documentation
 * The official AgentOnDuty API, documented with the OpenAPI 3.0 specification
 *
 * OpenAPI spec version: THE API VERSION IS NOW PROGRAMMATICALLY SET AND RETRIEVED! Use the public endpoint for reading the current version.
 * Contact: demo@synergogroup.net
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AddNoteToCallbackDto } from '../model/addNoteToCallbackDto';
import { BaseApiResponseDto } from '../model/baseApiResponseDto';
import { InlineResponse20027 } from '../model/inlineResponse20027';
import { InlineResponse20028 } from '../model/inlineResponse20028';
import { RegisterCallbackDto } from '../model/registerCallbackDto';
import { UpdateCallbackDto } from '../model/updateCallbackDto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class CallbackService {

    protected basePath = 'https://agentonduty-dev.azurewebsites.net/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * (for Admin) Adds a new note to an existing callback request
     * @param body Add note payload object
     * @param id The Callback request Id to add note for
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addNoteToCallbackRequest(body: AddNoteToCallbackDto, id: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20027>;
    public addNoteToCallbackRequest(body: AddNoteToCallbackDto, id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20027>>;
    public addNoteToCallbackRequest(body: AddNoteToCallbackDto, id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20027>>;
    public addNoteToCallbackRequest(body: AddNoteToCallbackDto, id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling addNoteToCallbackRequest.');
        }

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling addNoteToCallbackRequest.');
        }

        let headers = this.defaultHeaders;

        // authentication (JWTbearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<InlineResponse20027>(`${this.basePath}/callback/add-note/${encodeURIComponent(String(id))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Retrieves all callback requests (for Admin), in a paginated manner, with filtering and sorting.
     * @param search The search term to filter Transactions by
     * @param page Requsted current page of results
     * @param size The amount of results one page must have
     * @param sortBy Attribute name to sort results by
     * @param isAscending Sorting order (true if Ascending, false is Descending)
     * @param status Get transactions having specific transaction status
     * @param fromCreationDate Get callbacks with a creation date greater than or equal to the supplied filter
     * @param toCreationDate Get callbacks with a creation date less than or equal to the supplied filter
     * @param fromAssignDate Get callbacks with an assign date greater than or equal to the supplied filter
     * @param toAssignDate Get callbacks with an assign date less than or equal to the supplied filter
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllCallbackRequests(search?: string, page?: number, size?: number, sortBy?: string, isAscending?: boolean, status?: string, fromCreationDate?: string, toCreationDate?: string, fromAssignDate?: string, toAssignDate?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20028>;
    public getAllCallbackRequests(search?: string, page?: number, size?: number, sortBy?: string, isAscending?: boolean, status?: string, fromCreationDate?: string, toCreationDate?: string, fromAssignDate?: string, toAssignDate?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20028>>;
    public getAllCallbackRequests(search?: string, page?: number, size?: number, sortBy?: string, isAscending?: boolean, status?: string, fromCreationDate?: string, toCreationDate?: string, fromAssignDate?: string, toAssignDate?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20028>>;
    public getAllCallbackRequests(search?: string, page?: number, size?: number, sortBy?: string, isAscending?: boolean, status?: string, fromCreationDate?: string, toCreationDate?: string, fromAssignDate?: string, toAssignDate?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {











        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (search !== undefined && search !== null) {
            queryParameters = queryParameters.set('search', <any>search);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sortBy !== undefined && sortBy !== null) {
            queryParameters = queryParameters.set('sortBy', <any>sortBy);
        }
        if (isAscending !== undefined && isAscending !== null) {
            queryParameters = queryParameters.set('isAscending', <any>isAscending);
        }
        if (status !== undefined && status !== null) {
            queryParameters = queryParameters.set('status', <any>status);
        }
        if (fromCreationDate !== undefined && fromCreationDate !== null) {
            queryParameters = queryParameters.set('fromCreationDate', <any>fromCreationDate);
        }
        if (toCreationDate !== undefined && toCreationDate !== null) {
            queryParameters = queryParameters.set('toCreationDate', <any>toCreationDate);
        }
        if (fromAssignDate !== undefined && fromAssignDate !== null) {
            queryParameters = queryParameters.set('fromAssignDate', <any>fromAssignDate);
        }
        if (toAssignDate !== undefined && toAssignDate !== null) {
            queryParameters = queryParameters.set('toAssignDate', <any>toAssignDate);
        }

        let headers = this.defaultHeaders;

        // authentication (JWTbearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<InlineResponse20028>(`${this.basePath}/callback/admin/all`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Returns the callback for the requested id.
     * @param id The Callback Id to retrieve callback for
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCallback(id: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20027>;
    public getCallback(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20027>>;
    public getCallback(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20027>>;
    public getCallback(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getCallback.');
        }

        let headers = this.defaultHeaders;

        // authentication (JWTbearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<InlineResponse20027>(`${this.basePath}/callback/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * For clients, register a new Callback Request in the database.
     * @param body Callback request to register
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public registerCallbackRequest(body: RegisterCallbackDto, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20027>;
    public registerCallbackRequest(body: RegisterCallbackDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20027>>;
    public registerCallbackRequest(body: RegisterCallbackDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20027>>;
    public registerCallbackRequest(body: RegisterCallbackDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling registerCallbackRequest.');
        }

        let headers = this.defaultHeaders;

        // authentication (JWTbearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<InlineResponse20027>(`${this.basePath}/callback`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * (for Admin) Removes an existing note from an existing callback request
     * @param id The Callback request Id to add note for
     * @param noteId The Note Id to perform remove for
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public removeNoteFromCallbackRequest(id: string, noteId: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20027>;
    public removeNoteFromCallbackRequest(id: string, noteId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20027>>;
    public removeNoteFromCallbackRequest(id: string, noteId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20027>>;
    public removeNoteFromCallbackRequest(id: string, noteId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling removeNoteFromCallbackRequest.');
        }

        if (noteId === null || noteId === undefined) {
            throw new Error('Required parameter noteId was null or undefined when calling removeNoteFromCallbackRequest.');
        }

        let headers = this.defaultHeaders;

        // authentication (JWTbearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<InlineResponse20027>(`${this.basePath}/callback/remove-note/${encodeURIComponent(String(id))}/${encodeURIComponent(String(noteId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * (for Admin) Request feedback from callback request reporter.
     * @param id The Callback request Id to perform request feedback for
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public requestFeedbackToReporter(id: string, observe?: 'body', reportProgress?: boolean): Observable<BaseApiResponseDto>;
    public requestFeedbackToReporter(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BaseApiResponseDto>>;
    public requestFeedbackToReporter(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BaseApiResponseDto>>;
    public requestFeedbackToReporter(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling requestFeedbackToReporter.');
        }

        let headers = this.defaultHeaders;

        // authentication (JWTbearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<BaseApiResponseDto>(`${this.basePath}/callback/request-feedback/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * (for Admin) Updates the status, notes (optional) and assignee (optional) of an existing callback request
     * @param body Update payload object
     * @param id The Callback request Id to perform update for
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateCallbackRequest(body: UpdateCallbackDto, id: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20027>;
    public updateCallbackRequest(body: UpdateCallbackDto, id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20027>>;
    public updateCallbackRequest(body: UpdateCallbackDto, id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20027>>;
    public updateCallbackRequest(body: UpdateCallbackDto, id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateCallbackRequest.');
        }

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling updateCallbackRequest.');
        }

        let headers = this.defaultHeaders;

        // authentication (JWTbearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<InlineResponse20027>(`${this.basePath}/callback/${encodeURIComponent(String(id))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
