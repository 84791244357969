import {Injectable} from '@angular/core';
import {Unsubscriber} from '../utils/unsubscriber';
import {LoadingService} from './loading.service';
import {environment} from '../../environments/environment';
import {Platform} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {AuthService} from './auth.service';
import {
  CommonUserResponseDto as Profile,
  PushService as PushServiceApi,
  RecentInteractionDto as RecentInteraction,
  UserMetadataDto as User
} from '../../../swagger-client';
import {Router} from '@angular/router';
import {WsService} from './ws.service';
import {OneSignal as OneSignalNative} from '@ionic-native/onesignal/ngx';
import {ELocalNotificationTriggerUnit, LocalNotifications} from '@ionic-native/local-notifications/ngx';

declare var OneSignal;

@Injectable({
  providedIn: 'root'
})
export class PushService extends Unsubscriber {
  isEnabled = new BehaviorSubject<boolean>(null);
  notification = new Subject<any>();
  oneSignalPromise: Promise<any>;
  initPromise: Promise<any>;
  registeredPushKey: string = null;
  processingProfile = false;
  processProfileNeeded: Profile = undefined;

  constructor(
    public loadingService: LoadingService,
    public platform: Platform,
    public translate: TranslateService,
    public auth: AuthService,
    public router: Router,
    public ws: WsService,
    public oneSignal: OneSignalNative,
    public localNotifications: LocalNotifications,
    public pushService: PushServiceApi,
  ) {
    super(loadingService);
  }

  // leave this one as is to make sure we always return the same initPromise
  init() {
    if (!environment.oneSignalAppId) {
      return;
    }

    if (!this.initPromise) {
      this.initPromise = new Promise(async (resolve, reject) => {
        const timeout = environment.oneSignalTimeout ?
          setTimeout(() => reject('OneSignal timeout'), environment.oneSignalTimeout) :
          null;

        console.log('OneSignal init start');
        try {
          if (!this.platform.is('cordova')) {
            const webSDK = await this.getWebSDK();
            webSDK.log.setLevel(!environment.production ? 'trace' : '');

            // WARNING: for an unknown reason, having await in the pushed function fails
            const config = {
              appId: environment.oneSignalAppId,
              allowLocalhostAsSecureOrigin: true,
              requiresUserPrivacyConsent: false,
              persistNotification: true, // would like the notifications to stay on until dismissed by the user
              autoResubscribe: true, // resubscribe if user clears browser cache
              autoRegister: false, // we register only after logged in
              notificationClickHandlerMatch: 'origin', // use the existing browser tab instead of a new one
              notificationClickHandlerAction: 'focus', // focus instead of navigate; this is a SPA
              promptOptions: {
                actionMessage:
                  await this.translate.get('push.promptOptions.actionMessage').toPromise(),
                exampleNotificationTitleDesktop:
                  await this.translate.get('push.promptOptions.exampleNotificationTitleDesktop').toPromise(),
                exampleNotificationMessageDesktop:
                  await this.translate.get('push.promptOptions.exampleNotificationMessageDesktop').toPromise(),
                exampleNotificationTitleMobile:
                  await this.translate.get('push.promptOptions.exampleNotificationTitleMobile').toPromise(),
                exampleNotificationMessageMobile:
                  await this.translate.get('push.promptOptions.exampleNotificationMessageMobile').toPromise(),
                exampleNotificationCaption:
                  await this.translate.get('push.promptOptions.exampleNotificationCaption').toPromise(),
                acceptButtonText:
                  await this.translate.get('push.promptOptions.acceptButtonText').toPromise(),
                cancelButtonText:
                  await this.translate.get('push.promptOptions.cancelButtonText').toPromise(),
                showCredit: false, // Set to false to hide the OneSignal logo.
              },
              welcomeNotification: {
                disable: true,
                title: await this.translate.get('push.welcomeNotification.title').toPromise(),
                message: await this.translate.get('push.welcomeNotification.message').toPromise(),
                url: null, // By default, clicking the welcome notification does not open any link.
              },
              notifyButton: {
                enable: false, // the Subscription Bell (formerly known as the notify button)
              },
            };
            const title = await this.translate.get('push.defaultTitle').toPromise();

            webSDK.push(() => {
              try {
                // WARNING: for an unknown reason, clearing the cache (CTRL+F5) breaks OneSignal and one needs to open the page again.
                // WARNING: Do not call this method twice. Doing so results in an error.
                // WARNING: This call is required before any other function can be used.
                webSDK.init(config);
              } catch (e) {
                console.error(e);
                clearTimeout(timeout);
                reject(e);
              }
            });

            // WARNING: Must separate the init from anything else
            webSDK.push(async () => {
              try {
                // WARNING: for an unknown reason, having await in the pushed function fails

                webSDK.setDefaultTitle(title).then(null, e => console.error(e));

                webSDK.isPushNotificationsEnabled().then(isEnabled => {
                  console.log('OneSignal notifications enabled:', isEnabled);
                  this.isEnabled.next(isEnabled);
                }, e => console.error(e));
                webSDK.on('subscriptionChange', (isSubscribed) => {
                  console.log('OneSignal subscription change:', isSubscribed);
                  this.isEnabled.next(isSubscribed);
                });

                webSDK.on('notificationDisplay', (event) => {
                  console.log('OneSignal notification displayed:', event);
                });

                webSDK.on('notificationDismiss', (event) => {
                  console.log('OneSignal notification dismissed:', event);
                });

                // This is not documented. Picked it up from the OneSignal Web SDK Git.
                webSDK.on('notificationClick', async (event) => {
                  console.log('OneSignal notification clicked:', event);

                  if (!event) {
                    return;
                  }

                  await this.processData(event.data);
                  this.notification.next(event);
                });

                // This event occurs once only.
                // If you would this to occur continuously every time a notification is clicked,
                // please call this method again after your callback fires.
                const notificationOpenedHandler = async (event) => {
                  console.log('OneSignal notification opened:', event);

                  if (!event) {
                    return;
                  }

                  await this.processData(event.data);
                  this.notification.next(event);

                  webSDK.addListenerForNotificationOpened(notificationOpenedHandler);
                };

                webSDK.addListenerForNotificationOpened().then(notificationOpenedHandler, e => console.error(e));

                clearTimeout(timeout);
                resolve(webSDK);
              } catch (e) {
                console.error(e);
                clearTimeout(timeout);
                reject(e);
              }
            });
          } else {
            try {
              this.subscribe(this.localNotifications.on('click'), notification => {
                if (notification && notification.data) {
                  this.processData(notification.data).then();
                }
              });
            } catch (e) {
              console.error(e);
            }

            this.oneSignal.setLogLevel({
              logLevel: !environment.production ? 6 : 0,
              visualLevel: 0,
            });
            this.oneSignal.startInit(environment.oneSignalAppId, environment.oneSignalGoogleProjectNumber);

            this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.Notification); // push notifications will always show

            this.oneSignal.getPermissionSubscriptionState().then(fullState => {
              this.isEnabled.next(fullState && fullState.subscriptionStatus ? fullState.subscriptionStatus.subscribed : null);
            });
            this.subscribe(this.oneSignal.addSubscriptionObserver(), state => {
              this.isEnabled.next(state && state.to && state.to.subscribed);
            });

            this.oneSignal.handleNotificationReceived().subscribe(async event => {
              console.log('OneSignal notification received:', event);
              // await this.processData(event.payload.additionalData, true);
              // this.notification.next(event);
            });

            this.oneSignal.handleNotificationOpened().subscribe(async event => {
              console.log('OneSignal notification opened:', event);
              await this.processData(event.notification.payload.additionalData);
              this.notification.next(event.notification);
            });

            this.oneSignal.endInit();

            clearTimeout(timeout);
            resolve(this.oneSignal);
          }
        } catch (e) {
          console.error(e);
          clearTimeout(timeout);
          reject(e);
        }
        console.log('OneSignal init end');

        this.subscribe(this.auth.profile, profile => this.processProfile(profile));
      });
    }

    return this.initPromise;
  }

  async processProfile(profile: Profile) {
    if (this.processingProfile) {
      this.processProfileNeeded = profile;
      return;
    }

    this.processingProfile = true;

    try {
      if (profile && profile.id) {
        if (
          await this.isPushNotificationsSupported()
          && !await this.isPushNotificationsEnabled()
        ) {
          await this.registerForPushNotifications();
        }

        await this.setExternalUserId(profile.id);
        // not setting the email as well in case we decide to offer Wechat registration as well
        // await this.setEmail(profile.username);

        const userId = <string>await this.getUserId();
        const registeredPushKey = profile.id + ' - ' + userId;
        if (registeredPushKey !== this.registeredPushKey) {
          this.registeredPushKey = registeredPushKey;
          console.log('Sync push ' + this.registeredPushKey);

          if (userId) {
            try {
              await this.pushService.deleteSubscription(userId).toPromise();
              await this.pushService.registerSubscription({pushId: userId}).toPromise();
            } catch (e) {
              console.error(e);
            }
          }

          await this.ws.push(userId);
        }
      } else {
        await this.removeExternalUserId();
        // await this.logoutEmail();

        const userId = <string>await this.getUserId();
        const registeredPushKey = '';
        if (registeredPushKey !== this.registeredPushKey) {
          this.registeredPushKey = registeredPushKey;
          console.log('Sync push ' + this.registeredPushKey);

          if (userId) {
            try {
              await this.pushService.deleteSubscription(userId).toPromise();
            } catch (e) {
              console.error(e);
            }
          }

          await this.ws.push(userId);
        }
      }
    } catch (e) {
      console.error(e);
    }

    this.processingProfile = false;

    if (this.processProfileNeeded !== undefined) {
      profile = this.processProfileNeeded;
      this.processProfileNeeded = undefined;
      await this.processProfile(profile);
    }
  }

  async processData(data: any, observer = false) {
    if (!data || !data.type || !data.data) {
      return;
    }

    const obj = JSON.parse(data.data);

    let other: User;

    switch (data.type) {
      case WsService.TypeEnum.ConversationCreated:
      case RecentInteraction.TypeEnum.Chat:
        if (obj.id) {
          if (observer) {
            this.ws.chat.next(obj);
          } else {
            await this.router.navigate(['/chat/' + obj.id]);
          }
        }
        break;
      case WsService.TypeEnum.MessagePosted:
      case RecentInteraction.TypeEnum.Message:
        if (obj.chatId) {
          if (observer) {
            this.ws.message.next(obj);
          } else {
            await this.router.navigate(['/chat/' + obj.chatId]);
          }
        }
        break;
      case WsService.TypeEnum.CallJoinedBySender:
      case RecentInteraction.TypeEnum.Call:
        if (obj.id) {
          // We force this call
          this.ws.callJoined.next(obj);
        }
        break;
      case WsService.TypeEnum.ConnectionCreated:
      case RecentInteraction.TypeEnum.Connection:
        other = this.auth.other(obj.agent, obj.client);
        if (other) {
          if (observer) {
            this.ws.connection.next(obj);
          } else {
            await this.router.navigate(['/profile/' + other.id + (obj.id ? '/interaction/' + obj.id : '')]);
          }
        }
        break;
      case WsService.TypeEnum.ListingCreated:
      case RecentInteraction.TypeEnum.Listing:
        other = this.auth.other(obj.createdBy);
        if (obj.id) {
          if (observer) {
            this.ws.connection.next(obj);
          } else {
            await this.router.navigate(['/profile/' + other.id + (obj.id ? '/property/' + obj.id : '')]);
          }
        }
        break;
    }
  }

  getPromiseTimeout(resolve: Function, reject: Function) {
    return environment.oneSignalTimeout ?
      setTimeout(() => {
        console.error('OneSignal timeout');
        reject('OneSignal timeout');
      }, environment.oneSignalTimeout) :
      null;
  }

  // leave this one as is to make sure we always return the same oneSignalPromise
  getWebSDK(): Promise<any> {
    if (typeof OneSignal !== 'undefined' && OneSignal) {
      return Promise.resolve(OneSignal);
    }

    if (!this.oneSignalPromise) {
      this.oneSignalPromise = new Promise((resolve, reject) => {
        const timeout = this.getPromiseTimeout(resolve, reject);

        const script = document.createElement('script');
        script.src = 'https://cdn.onesignal.com/sdks/OneSignalSDK.js';
        script.async = true;
        document.body.appendChild(script);
        script.onload = () => {
          if (typeof OneSignal !== 'undefined' && OneSignal) {
            clearTimeout(timeout);
            resolve(OneSignal);
          } else {
            clearTimeout(timeout);
            reject('OneSignal not available');
          }
        };
      });
    }

    return this.oneSignalPromise;
  }

  async registerForPushNotifications() {
    return await new Promise(async (resolve, reject) => {
      const timeout = this.getPromiseTimeout(resolve, reject);

      try {
        const permission = await this.getNotificationPermission();
        if (permission) {
          clearTimeout(timeout);
          resolve(permission);
        }
      } catch (e) {
        console.error(e);
      }

      try {
        if (!this.platform.is('cordova')) {
          const webSDK = await this.getWebSDK();

          webSDK.push(async () => {
            try {
              console.log('OneSignal SDK registering');
              await webSDK.registerForPushNotifications();
              console.log('OneSignal SDK registered');

              const enabled = await webSDK.isPushNotificationsEnabled();
              this.isEnabled.next(enabled);

              clearTimeout(timeout);
              resolve(enabled);
            } catch (e) {
              console.error(e);
              clearTimeout(timeout);
              reject(e);
            }
          });
        } else {
          if (this.platform.is('ios')) {
            console.log('OneSignal SDK registering');
            const enabled = await this.oneSignal.promptForPushNotificationsWithUserResponse();
            console.log('OneSignal SDK registered');

            this.isEnabled.next(enabled);

            clearTimeout(timeout);
            resolve(enabled);
          } else {
            clearTimeout(timeout);
            resolve(true);
          }
        }
      } catch (e) {
        console.error(e);
        clearTimeout(timeout);
        reject(e);
      }
    });
  }

  async setEnabled(enabled: boolean) {
    return await new Promise(async (resolve, reject) => {
      const timeout = this.getPromiseTimeout(resolve, reject);

      try {
        if (enabled) {
          await this.registerForPushNotifications();
        }

        if (!this.platform.is('cordova')) {
          const webSDK = await this.getWebSDK();

          webSDK.push(async () => {
            try {
              // The user must already be subscribed for this function to have any effect.
              await webSDK.setSubscription(enabled);

              enabled = await webSDK.isPushNotificationsEnabled();
            } catch (e) {
              console.error(e);
              clearTimeout(timeout);
              reject(e);
            }
          });
        } else {
          this.oneSignal.setSubscription(enabled);

          if (enabled) {
            if (!await this.localNotifications.hasPermission()) {
              await this.localNotifications.requestPermission();
            }
          }
        }

        this.isEnabled.next(enabled);

        if (enabled) {
          // await this.processProfile(await this.auth.refreshProfile());
        }

        clearTimeout(timeout);
        resolve(enabled);
      } catch (e) {
        console.error(e);
        clearTimeout(timeout);
        reject(e);
      }
    });
  }

  // Use this to show the setting toggle
  async isPushNotificationsSupported() {
    return await new Promise(async (resolve, reject) => {
      const timeout = this.getPromiseTimeout(resolve, reject);

      try {
        if (!this.platform.is('cordova')) {
          const webSDK = await this.getWebSDK();

          webSDK.push(() => {
            try {
              clearTimeout(timeout);
              resolve(webSDK.isPushNotificationsSupported());
            } catch (e) {
              console.error(e);
              clearTimeout(timeout);
              reject(e);
            }
          });
        } else {
          clearTimeout(timeout);
          resolve(true);
        }
      } catch (e) {
        console.error(e);
        clearTimeout(timeout);
        reject(e);
      }
    });
  }

  async getNotificationPermission() {
    return await new Promise(async (resolve, reject) => {
      const timeout = this.getPromiseTimeout(resolve, reject);

      try {
        if (!this.platform.is('cordova')) {
          const webSDK = await this.getWebSDK();

          webSDK.push(() => {
            try {
              clearTimeout(timeout);
              resolve(webSDK.getNotificationPermission());
            } catch (e) {
              console.error(e);
              clearTimeout(timeout);
              reject(e);
            }
          });
        } else {
          const fullState = await this.oneSignal.getPermissionSubscriptionState();
          clearTimeout(timeout);
          resolve(
            fullState
            && fullState.permissionStatus
            && (fullState.permissionStatus.status === 2 || fullState.permissionStatus.state === 1)
          );
        }
      } catch (e) {
        console.error(e);
        clearTimeout(timeout);
        reject(e);
      }
    });
  }

  async isPushNotificationsEnabled() {
    return await new Promise(async (resolve, reject) => {
      const timeout = this.getPromiseTimeout(resolve, reject);

      try {
        if (!this.platform.is('cordova')) {
          const webSDK = await this.getWebSDK();

          webSDK.push(() => {
            try {
              clearTimeout(timeout);
              resolve(webSDK.isPushNotificationsEnabled());
            } catch (e) {
              console.error(e);
              clearTimeout(timeout);
              reject(e);
            }
          });
        } else {
          const fullState = await this.oneSignal.getPermissionSubscriptionState();
          clearTimeout(timeout);
          resolve(fullState && fullState.subscriptionStatus ? fullState.subscriptionStatus.subscribed : null);
        }
      } catch (e) {
        console.error(e);
        clearTimeout(timeout);
        reject(e);
      }
    });
  }

  async getUserId() {
    return new Promise(async (resolve, reject) => {
      const timeout = this.getPromiseTimeout(resolve, reject);

      try {
        if (!this.platform.is('cordova')) {
          const webSDK = await this.getWebSDK();

          webSDK.push(() => {
            try {
              clearTimeout(timeout);
              resolve(webSDK.getUserId());
            } catch (e) {
              console.error(e);
              clearTimeout(timeout);
              reject(e);
            }
          });
        } else {
          const fullState = await this.oneSignal.getPermissionSubscriptionState();
          clearTimeout(timeout);
          resolve(fullState && fullState.subscriptionStatus ? fullState.subscriptionStatus.userId : null);
        }
      } catch (e) {
        console.error(e);
        clearTimeout(timeout);
        reject(e);
      }
    });
  }

  async setEmail(email: string) {
    return new Promise(async (resolve, reject) => {
      const timeout = this.getPromiseTimeout(resolve, reject);

      try {
        if (!this.platform.is('cordova')) {
          const webSDK = await this.getWebSDK();

          webSDK.push(() => {
            try {
              clearTimeout(timeout);
              resolve(webSDK.setEmail(email));
            } catch (e) {
              console.error(e);
              clearTimeout(timeout);
              reject(e);
            }
          });
        } else {
          clearTimeout(timeout);
          resolve(this.oneSignal.setEmail(email));
        }
      } catch (e) {
        console.error(e);
        clearTimeout(timeout);
        reject(e);
      }
    });
  }

  async logoutEmail() {
    return new Promise(async (resolve, reject) => {
      const timeout = this.getPromiseTimeout(resolve, reject);

      try {
        if (!this.platform.is('cordova')) {
          const webSDK = await this.getWebSDK();

          webSDK.push(() => {
            try {
              clearTimeout(timeout);
              resolve(webSDK.logoutEmail());
            } catch (e) {
              console.error(e);
              clearTimeout(timeout);
              reject(e);
            }
          });
        } else {
          clearTimeout(timeout);
          resolve(this.oneSignal.logoutEmail());
        }
      } catch (e) {
        console.error(e);
        clearTimeout(timeout);
        reject(e);
      }
    });
  }

  async getExternalUserId() {
    return new Promise(async (resolve, reject) => {
      const timeout = this.getPromiseTimeout(resolve, reject);

      try {
        if (!this.platform.is('cordova')) {
          const webSDK = await this.getWebSDK();

          webSDK.push(() => {
            try {
              clearTimeout(timeout);
              resolve(webSDK.getExternalUserId());
            } catch (e) {
              console.error(e);
              clearTimeout(timeout);
              reject(e);
            }
          });
        } else {
          throw {message: 'OneSignal native SDK does not have support for getExternalUserId'};
        }
      } catch (e) {
        console.error(e);
        clearTimeout(timeout);
        reject(e);
      }
    });
  }

  async setExternalUserId(externalUserId: string) {
    return new Promise(async (resolve, reject) => {
      const timeout = this.getPromiseTimeout(resolve, reject);

      try {
        if (!this.platform.is('cordova')) {
          const webSDK = await this.getWebSDK();

          webSDK.push(async () => {
            try {
              const externalUserIdCurrent = await this.getExternalUserId();
              if (externalUserIdCurrent === externalUserId) {
                clearTimeout(timeout);
                resolve(null);
              } else {
                clearTimeout(timeout);
                resolve(webSDK.setExternalUserId(externalUserId));
              }
            } catch (e) {
              console.error(e);
              clearTimeout(timeout);
              reject(e);
            }
          });
        } else {
          clearTimeout(timeout);
          resolve(this.oneSignal.setExternalUserId(externalUserId));
        }
      } catch (e) {
        console.error(e);
        clearTimeout(timeout);
        reject(e);
      }
    });
  }

  async removeExternalUserId() {
    return new Promise(async (resolve, reject) => {
      const timeout = this.getPromiseTimeout(resolve, reject);

      try {
        if (!this.platform.is('cordova')) {
          const webSDK = await this.getWebSDK();

          webSDK.push(() => {
            try {
              clearTimeout(timeout);
              resolve(webSDK.removeExternalUserId());
            } catch (e) {
              console.error(e);
              clearTimeout(timeout);
              reject(e);
            }
          });
        } else {
          clearTimeout(timeout);
          resolve(this.oneSignal.removeExternalUserId());
        }
      } catch (e) {
        console.error(e);
        clearTimeout(timeout);
        reject(e);
      }
    });
  }

  async sendSelfNotification(
    title: string = '',
    message: string = '',
    url: string = '',
    icon: string = '',
    data?: any,
    buttons?: Array<{
      id: string,
      text: string,
      icon: string,
      url: string,
    }>
  ) {
    return new Promise(async (resolve, reject) => {
      const timeout = this.getPromiseTimeout(resolve, reject);

      if (!url) {
        url = location.href;
      }

      try {
        if (!this.platform.is('cordova')) {
          const webSDK = await this.getWebSDK();

          webSDK.push(() => {
            try {
              clearTimeout(timeout);
              resolve(webSDK.sendSelfNotification(title, message, url, icon, data, buttons));
            } catch (e) {
              console.error(e);
              clearTimeout(timeout);
              reject(e);
            }
          });
        } else {
          clearTimeout(timeout);
          resolve(this.localNotifications.schedule({
            title,
            text: message,
            icon,
            smallIcon: icon,
            // attachments: [icon],
            data,
            launch: true,
            trigger: {in: 1, unit: ELocalNotificationTriggerUnit.SECOND}
          }));
          /*
          const userId = await this.getUserId();
          resolve(this.oneSignal.postNotification({
            include_player_ids: [<string>userId],
            headings: {
              en: title,
            },
            contents: {
              en: message,
            },
            large_icon: icon,
            adm_large_icon: icon,
            chrome_web_icon: icon,
            data,
          }));
          */
        }
      } catch (e) {
        console.error(e);
        clearTimeout(timeout);
        reject(e);
      }
    });
  }
}
