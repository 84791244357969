const position: 'top' = 'top'; // tricking string enums
export const environment = {
  version: '1.22.0', // this should be the same version number as in config.xml

  production: false,
  serviceWorker: false,
  roster: null, // switch between client (false), agent (true), dynamic (null)

  // leave this api configuration structure alone; it is used as this by the Swagger client
  api: {
    basePath: 'https://agentonduty-dev.azurewebsites.net/api',
  },
  wsUrl: 'wss://agentonduty-dev.azurewebsites.net/api/', // trailing slash is required
  resetPasswordUrl: 'http://localhost:8100/reset/{code}',
  confirmAccountUrl: 'http://localhost:8100/confirm/{code}',
  customerUrl: '',
  rosterUrl: '',

  // TODO: Deployment - Generate credentials for each provider and each platform.
  // These require configurations in the package.json file as well
  googleMapsApiKey: 'AIzaSyD_SJqISVq_rsHNvabN_63USrezwDtdF4c', // this should handle multiple domains and native as well

  facebookAppId: '567059143920927', // this is for the browser only; in native mode, provider setup needs the config.xml id
  googleOauthClientId: '120927182515-plcenoc4bcedm7do1snurq40ihdv340o.apps.googleusercontent.com', // same as above
  weChatAppId: 'WeChatAppId',
  weChatRedirectUrl: 'http://localhost:8100/signup',
  stripePublishableKey: 'pk_test_4Mnu9WOqZOOhrEj6p1kZXa7Q00uN5sUavm',
  oneSignalTimeout: 10000, // this is a timeout in case the OneSignal service workers is not running
  oneSignalAppId: '9c7fd374-2fa2-4519-be9a-2080f4ae2401',
  oneSignalSafariWebId: 'web.onesignal.auto.0c90051e-4735-447d-b203-75e3767d91b9',
  oneSignalGoogleProjectNumber: '120927182515',

  positionFetchingTolerance: 50, // avoid re-fetching the position within this distance in meters
  countryFetchingTolerance: 5000, // avoid re-fetching the country within this distance in meters
  geolocationWithoutPreexistingLocationTimeout: 10000,
  geolocationWithPreexistingLocationTimeout: 3000,
  currencies: ['USD', 'CAD', 'EUR', 'CNY', 'AED', 'AFN', 'ALL', 'AMD', 'ANG', 'AOA', 'ARS', 'AUD', 'AWG', 'AZN', 'BAM', 'BBD', 'BDT', 'BGN', 'BIF', 'BMD', 'BND', 'BOB', 'BRL', 'BSD', 'BWP', 'BZD', 'CDF', 'CHF', 'CLP', 'COP', 'CRC', 'CVE', 'CZK', 'DJF', 'DKK', 'DOP', 'DZD', 'EGP', 'ETB', 'FJD', 'FKP', 'GBP', 'GEL', 'GIP', 'GMD', 'GNF', 'GTQ', 'GYD', 'HKD', 'HNL', 'HRK', 'HTG', 'HUF', 'IDR', 'ILS', 'INR', 'ISK', 'JMD', 'JPY', 'KES', 'KGS', 'KHR', 'KMF', 'KRW', 'KYD', 'KZT', 'LAK', 'LBP', 'LKR', 'LRD', 'LSL', 'MAD', 'MDL', 'MGA', 'MKD', 'MMK', 'MNT', 'MOP', 'MRO', 'MUR', 'MVR', 'MWK', 'MXN', 'MYR', 'MZN', 'NAD', 'NGN', 'NIO', 'NOK', 'NPR', 'NZD', 'PAB', 'PEN', 'PGK', 'PHP', 'PKR', 'PLN', 'PYG', 'QAR', 'RON', 'RSD', 'RUB', 'RWF', 'SAR', 'SBD', 'SCR', 'SEK', 'SGD', 'SHP', 'SLL', 'SOS', 'SRD', 'STD', 'SZL', 'THB', 'TJS', 'TOP', 'TRY', 'TTD', 'TWD', 'TZS', 'UAH', 'UGX', 'UYU', 'UZS', 'VND', 'VUV', 'WST', 'XAF', 'XCD', 'XOF', 'XPF', 'YER', 'ZAR', 'ZMW'],
  toast: {
    duration: 3000,
    showCloseButton: true,
    position,
  },
  wsReconnectInterval: 10000, // number of milliseconds to randomize the reconnect attempt
  conversationMarkAsReadAfter: 1000, // number of milliseconds to mark a conversation as read after opening it
  showSplashMenuOption: true,
  agentRadius: 30000, // radius in meters for searching an available agent
  agentReconnectInterval: 5000, // number of milliseconds to wait before reattempting to connect to agent
  agentReconnectAttempts: 10,
  checkForApplicationUpdateInterval: 60000, // number of milliseconds to wait before checking for application updates
  checkForProfileUpdateInterval: 60000, // number of milliseconds to wait before checking for profile updates
  checkForInteractionsUpdateInterval: 60000, // number of milliseconds to wait before checking for interactions updates
  checkForTransactionsUpdateInterval: 60000, // number of milliseconds to wait before checking for transactions updates
  callDropTimeout: 30000, // number of milliseconds to wait for a participant to connect in the call
  inAppCalls: false, // use in app calls or standard phone calls

  cameraFittingFull: true, // fit camera on entire screen cropping if necessary
  useMediaDevicesOnly: true, // if false, will use native camera if available

  swipeBackEnabled: false, // control the iOS swipe back feature

  propertiesLimit: 0, // how many properties we allow the agent to create
  propertyImagesLimit: 3, // how many images we allow the agent to upload per property
};
