/**
 * AgentOnDuty API Documentation
 * The official AgentOnDuty API, documented with the OpenAPI 3.0 specification
 *
 * OpenAPI spec version: THE API VERSION IS NOW PROGRAMMATICALLY SET AND RETRIEVED! Use the public endpoint for reading the current version.
 * Contact: demo@synergogroup.net
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { BaseApiResponseDto } from '../model/baseApiResponseDto';
import { IdsDto } from '../model/idsDto';
import { InlineResponse20020 } from '../model/inlineResponse20020';
import { InlineResponse20021 } from '../model/inlineResponse20021';
import { InlineResponse20022 } from '../model/inlineResponse20022';
import { StartFileUploadDto } from '../model/startFileUploadDto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class FileService {

    protected basePath = 'https://agentonduty-dev.azurewebsites.net/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * Cancels an upload process that was initialized
     * @param id The Upload task Id to cancel upload for
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public cancelUpload(id: string, observe?: 'body', reportProgress?: boolean): Observable<BaseApiResponseDto>;
    public cancelUpload(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BaseApiResponseDto>>;
    public cancelUpload(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BaseApiResponseDto>>;
    public cancelUpload(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling cancelUpload.');
        }

        let headers = this.defaultHeaders;

        // authentication (JWTbearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<BaseApiResponseDto>(`${this.basePath}/file/cancel/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Initiates a multiple files delete process.
     * @param body Array with file Ids to delete
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteFiles(body: IdsDto, observe?: 'body', reportProgress?: boolean): Observable<BaseApiResponseDto>;
    public deleteFiles(body: IdsDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BaseApiResponseDto>>;
    public deleteFiles(body: IdsDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BaseApiResponseDto>>;
    public deleteFiles(body: IdsDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling deleteFiles.');
        }

        let headers = this.defaultHeaders;

        // authentication (JWTbearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<BaseApiResponseDto>(`${this.basePath}/file/delete-files`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Returns the download URL for an already uploaded file attachment
     * @param id The File Id to retrieve download URL for
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFileDownloadUrl(id: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20022>;
    public getFileDownloadUrl(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20022>>;
    public getFileDownloadUrl(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20022>>;
    public getFileDownloadUrl(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getFileDownloadUrl.');
        }

        let headers = this.defaultHeaders;

        // authentication (JWTbearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<InlineResponse20022>(`${this.basePath}/file/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Marks the upload as finished and triggers upload task sanitation in the backend
     * @param id The Upload task Id to publish upload for
     * @param body Not relevant,
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public publishUpload(id: string, body?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20021>;
    public publishUpload(id: string, body?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20021>>;
    public publishUpload(id: string, body?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20021>>;
    public publishUpload(id: string, body?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling publishUpload.');
        }


        let headers = this.defaultHeaders;

        // authentication (JWTbearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'text/plain'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<InlineResponse20021>(`${this.basePath}/file/publish-upload/${encodeURIComponent(String(id))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Initiates a file upload task in the Azure Blob, to be used from the client-side when doing the raw upload. This will create a blob and grant access to it using a SAS token.
     * @param body Simple file upload information (file name and size).
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public startUpload(body: StartFileUploadDto, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20020>;
    public startUpload(body: StartFileUploadDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20020>>;
    public startUpload(body: StartFileUploadDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20020>>;
    public startUpload(body: StartFileUploadDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling startUpload.');
        }

        let headers = this.defaultHeaders;

        // authentication (JWTbearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<InlineResponse20020>(`${this.basePath}/file/start-upload`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
