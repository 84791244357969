import {Injectable} from '@angular/core';
import {Storage} from '@ionic/storage';
import {BehaviorSubject} from 'rxjs';
import {Unsubscriber} from '../utils/unsubscriber';
import {AuthService} from './auth.service';
import {environment} from '../../environments/environment';
import {CommonUserResponseDto as User} from '../../../swagger-client';
import {LoadingService} from './loading.service';

export const LAST_ROSTER_KEY = 'LAST_ROSTER';

@Injectable({
  providedIn: 'root'
})
export class RosterService extends Unsubscriber {
  roster = new BehaviorSubject<boolean>(null);

  constructor(
    public storage: Storage,
    public auth: AuthService,
    public loadingService: LoadingService,
  ) {
    super(loadingService);

    this.initRoster().then();
  }

  async initRoster() {
    let roster: boolean = environment.roster;
    console.log('Roster from environment', roster);

    if (roster === null || roster === undefined) {
      // Config is dynamic. We will use the storage value and the profile information to figure it out.
      roster = await this.storage.get(LAST_ROSTER_KEY);
      console.log('Roster from storage', roster);

      this.subscribe(this.auth.profile, profile => {
        console.log('Roster profile', profile);
        if (profile && profile.id) {
          this.setRoster(profile.role === User.RoleEnum.Agent);
        } else {
          this.setRoster(roster);
        }
      }, () => {
        this.setRoster(roster);
      });
    } else {
      // Config is set and leading.
      this.setRoster(roster);
    }

    return roster;
  }

  async getRoster() {
    let roster = this.roster.getValue();

    if (roster === null) {
      // Not yet initialized. Let's try the storage.
      roster = await this.storage.get(LAST_ROSTER_KEY);
      console.log('Roster from storage', roster);
    }

    const profile = this.auth.profile.getValue();
    if (profile && profile.id) {
      roster = profile.role === User.RoleEnum.Agent;
    }

    return roster;
  }

  setRoster(roster: boolean) {
    console.log('Roster set', roster);
    this.storage.set(LAST_ROSTER_KEY, roster).then();
    this.roster.next(roster);
  }
}
