import {Injectable} from '@angular/core';
import {Storage} from '@ionic/storage';
import {BehaviorSubject} from 'rxjs';
import {Unsubscriber} from '../utils/unsubscriber';
import {Platform} from '@ionic/angular';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {LoadingService} from './loading.service';

export const DARK_THEME_KEY = 'DARK_THEME';

@Injectable({
  providedIn: 'root'
})
export class DarkService extends Unsubscriber {
  darkTheme = new BehaviorSubject(false);

  constructor(
    public storage: Storage,
    public platform: Platform,
    public statusBar: StatusBar,
    public loadingService: LoadingService,
  ) {
    super(loadingService);
  }

  checkDarkTheme() {
    const darkMatcher = window.matchMedia('(prefers-color-scheme: dark)');

    this.storage.get(DARK_THEME_KEY).then(darkTheme => {
      if (darkMatcher && (darkTheme === undefined || darkTheme === null)) {
        darkTheme = darkMatcher.matches;
      }
      this.setDarkTheme(darkTheme);
    });

    // If the mode changes at the OS level, it should overwrite the user's choice
    if (darkMatcher && darkMatcher.addEventListener) {
      darkMatcher.addEventListener('change', mediaQuery => {
        this.setDarkTheme(mediaQuery.matches);
      });
    }
  }

  setDarkTheme(darkTheme = true) {
    this.darkTheme.next(darkTheme);
    this.storage.set(DARK_THEME_KEY, darkTheme).then();

    window.document.body.classList.toggle('dark', darkTheme);

    this.platform.ready().then(async () => {
      if (!darkTheme) {
        this.statusBar.styleDefault();
        this.statusBar.backgroundColorByName('white');
        this.setThemeColor('white');
      } else {
        this.statusBar.styleLightContent();
        this.statusBar.backgroundColorByName('black');
        this.setThemeColor('black');
      }
    });
  }

  setThemeColor(color = 'black') {
    try {
      const head = document.querySelectorAll('head').item(0);
      if (head) {
        document.querySelectorAll('meta[name=theme-color]').forEach(old1 => {
          old1.remove();
        });
        const new1 = document.createElement('meta');
        new1.name = 'theme-color';
        new1.content = color;
        head.append(new1);

        document.querySelectorAll('meta[name=apple-mobile-web-app-status-bar-style]').forEach(old2 => {
          old2.remove();
        });
        const new2 = document.createElement('meta');
        new2.name = 'apple-mobile-web-app-status-bar-style';
        new2.content = color === 'black' ? 'black' : 'default';
        head.append(new2);
      }
    } catch (e) {
      console.error(e);
    }
  }
}
