/**
 * AgentOnDuty API Documentation
 * The official AgentOnDuty API, documented with the OpenAPI 3.0 specification
 *
 * OpenAPI spec version: THE API VERSION IS NOW PROGRAMMATICALLY SET AND RETRIEVED! Use the public endpoint for reading the current version.
 * Contact: demo@synergogroup.net
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { RegisterUserDto } from './registerUserDto';

export interface RegisterAgentDto extends RegisterUserDto { 
    companyName: string;
    trackingId?: string;
    jobTitle?: string;
    description?: string;
    signature: string;
    interests: Array<RegisterAgentDto.InterestsEnum>;
}
export namespace RegisterAgentDto {
    export type InterestsEnum = 'real_estate' | 'mortgage';
    export const InterestsEnum = {
        RealEstate: 'real_estate' as InterestsEnum,
        Mortgage: 'mortgage' as InterestsEnum
    };
}