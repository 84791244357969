/**
 * AgentOnDuty API Documentation
 * The official AgentOnDuty API, documented with the OpenAPI 3.0 specification
 *
 * OpenAPI spec version: THE API VERSION IS NOW PROGRAMMATICALLY SET AND RETRIEVED! Use the public endpoint for reading the current version.
 * Contact: demo@synergogroup.net
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AcceptPaymentDto } from '../model/acceptPaymentDto';
import { BaseApiResponseDto } from '../model/baseApiResponseDto';
import { CreateStripeSessionDto } from '../model/createStripeSessionDto';
import { InlineResponse20025 } from '../model/inlineResponse20025';
import { InlineResponse20026 } from '../model/inlineResponse20026';
import { InlineResponse20036 } from '../model/inlineResponse20036';
import { RegisterTransactionDto } from '../model/registerTransactionDto';
import { UpdateTransactionDto } from '../model/updateTransactionDto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class TransactionService {

    protected basePath = 'https://agentonduty-dev.azurewebsites.net/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * Initiates a Credit Card payment upon a Referral Fee registration
     * @param body accept payment payload
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public chargeReferralFee(body: AcceptPaymentDto, observe?: 'body', reportProgress?: boolean): Observable<BaseApiResponseDto>;
    public chargeReferralFee(body: AcceptPaymentDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BaseApiResponseDto>>;
    public chargeReferralFee(body: AcceptPaymentDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BaseApiResponseDto>>;
    public chargeReferralFee(body: AcceptPaymentDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling chargeReferralFee.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<BaseApiResponseDto>(`${this.basePath}/transaction/charge`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Creates Stripe payment session for credit card
     * @param body payment info payload
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createStripeSession(body: CreateStripeSessionDto, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20036>;
    public createStripeSession(body: CreateStripeSessionDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20036>>;
    public createStripeSession(body: CreateStripeSessionDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20036>>;
    public createStripeSession(body: CreateStripeSessionDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling createStripeSession.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<InlineResponse20036>(`${this.basePath}/transaction/create-session`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Retrieves all transactions (for Admin), in a paginated manner, with filtering and sorting.
     * @param search The search term to filter Transactions by
     * @param page Requsted current page of results
     * @param size The amount of results one page must have
     * @param sortBy Attribute name to sort results by
     * @param isAscending Sorting order (true if Ascending, false is Descending)
     * @param agentId Get transactions for specific agent
     * @param status Get transactions having specific transaction status
     * @param method Get transactions having specific transaction method
     * @param clientType Get transactions registered for specific client type; use the key, not the displayAs
     * @param minAmount Get transactions with a fee greater than or equal to the supplied amount
     * @param maxAmount Get transactions with a fee less than or equal to the supplied amount
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllTransactions(search?: string, page?: number, size?: number, sortBy?: string, isAscending?: boolean, agentId?: string, status?: string, method?: string, clientType?: string, minAmount?: number, maxAmount?: number, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20025>;
    public getAllTransactions(search?: string, page?: number, size?: number, sortBy?: string, isAscending?: boolean, agentId?: string, status?: string, method?: string, clientType?: string, minAmount?: number, maxAmount?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20025>>;
    public getAllTransactions(search?: string, page?: number, size?: number, sortBy?: string, isAscending?: boolean, agentId?: string, status?: string, method?: string, clientType?: string, minAmount?: number, maxAmount?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20025>>;
    public getAllTransactions(search?: string, page?: number, size?: number, sortBy?: string, isAscending?: boolean, agentId?: string, status?: string, method?: string, clientType?: string, minAmount?: number, maxAmount?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {












        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (search !== undefined && search !== null) {
            queryParameters = queryParameters.set('search', <any>search);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sortBy !== undefined && sortBy !== null) {
            queryParameters = queryParameters.set('sortBy', <any>sortBy);
        }
        if (isAscending !== undefined && isAscending !== null) {
            queryParameters = queryParameters.set('isAscending', <any>isAscending);
        }
        if (agentId !== undefined && agentId !== null) {
            queryParameters = queryParameters.set('agentId', <any>agentId);
        }
        if (status !== undefined && status !== null) {
            queryParameters = queryParameters.set('status', <any>status);
        }
        if (method !== undefined && method !== null) {
            queryParameters = queryParameters.set('method', <any>method);
        }
        if (clientType !== undefined && clientType !== null) {
            queryParameters = queryParameters.set('clientType', <any>clientType);
        }
        if (minAmount !== undefined && minAmount !== null) {
            queryParameters = queryParameters.set('minAmount', <any>minAmount);
        }
        if (maxAmount !== undefined && maxAmount !== null) {
            queryParameters = queryParameters.set('maxAmount', <any>maxAmount);
        }

        let headers = this.defaultHeaders;

        // authentication (JWTbearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<InlineResponse20025>(`${this.basePath}/transaction/admin/all`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Retrieves all transactions created by the logged user, in a paginated manner, from most recent to oldest.
     * @param page Requsted current page of results
     * @param size The amount of results one page must have
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMyTransactions(page?: number, size?: number, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20025>;
    public getMyTransactions(page?: number, size?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20025>>;
    public getMyTransactions(page?: number, size?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20025>>;
    public getMyTransactions(page?: number, size?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }

        let headers = this.defaultHeaders;

        // authentication (JWTbearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<InlineResponse20025>(`${this.basePath}/transaction`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * For agents, register a new Transaction in the database.
     * @param body Transaction to register
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public registerTransaction(body: RegisterTransactionDto, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20026>;
    public registerTransaction(body: RegisterTransactionDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20026>>;
    public registerTransaction(body: RegisterTransactionDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20026>>;
    public registerTransaction(body: RegisterTransactionDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling registerTransaction.');
        }

        let headers = this.defaultHeaders;

        // authentication (JWTbearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<InlineResponse20026>(`${this.basePath}/transaction`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Updates the status and notes (optional) of an existing transaction
     * @param body Update payload object
     * @param id The Transaction Id to perform update for
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateTransactionStatus(body: UpdateTransactionDto, id: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20026>;
    public updateTransactionStatus(body: UpdateTransactionDto, id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20026>>;
    public updateTransactionStatus(body: UpdateTransactionDto, id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20026>>;
    public updateTransactionStatus(body: UpdateTransactionDto, id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateTransactionStatus.');
        }

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling updateTransactionStatus.');
        }

        let headers = this.defaultHeaders;

        // authentication (JWTbearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<InlineResponse20026>(`${this.basePath}/transaction/${encodeURIComponent(String(id))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
