import {Injectable} from '@angular/core';
import {Storage} from '@ionic/storage';
import {Platform} from '@ionic/angular';
import {ActivatedRoute, Router} from '@angular/router';
import {JwtHelperService} from '@auth0/angular-jwt';
import {BehaviorSubject, from, Observable, of, Subscription, throwError} from 'rxjs';
import {
  AccountService,
  AgentProfileDto as AgentProfile,
  AgentService,
  Body as LoginCredentials,
  CommonUserResponseDto as Profile,
  SocialProviderPayloadDto as SocialProvider,
  UserMetadataDto as User
} from '../../../swagger-client';
import {finalize, map, switchMap, take} from 'rxjs/operators';
import {Unsubscriber} from '../utils/unsubscriber';
import {GeolocationService} from './geolocation.service';
import {Geoposition} from '@ionic-native/geolocation/ngx';
import {LoadingService} from './loading.service';
import {environment} from '../../environments/environment';
import {AppleSignInResponse, ASAuthorizationAppleIDRequest, SignInWithApple} from '@ionic-native/sign-in-with-apple/ngx';

const helper = new JwtHelperService();

export const AUTH_TOKEN_KEY = 'AUTH_TOKEN';
export const PROFILE_KEY = 'PROFILE';
export const APPLE_SIGN_IN_KEY = 'APPLE_SIGN_IN';

export interface Credentials {
  username: string;
  password?: string;
  id?: string;
  token?: string;
  provider?: SocialProvider.ProviderEnum|string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService extends Unsubscriber {
  token = new BehaviorSubject('');
  profile = new BehaviorSubject<Profile>(null);
  firstInterest = new BehaviorSubject<string>(null);
  tracker: Subscription = null;
  loggingOut = false;
  lastUpdateAgentAvailabilityParams = '';
  lastProfileRefresh: Date;

  constructor(
    public storage: Storage,
    public account: AccountService,
    public agent: AgentService,
    public plt: Platform,
    public router: Router,
    public route: ActivatedRoute,
    public geolocation: GeolocationService,
    public loadingService: LoadingService,
    public signInWithApple: SignInWithApple,
  ) {
    super(loadingService);

    this.getToken().then();
    this.getProfile().then();
  }

  getToken(decoded = false) {
    return this.plt.ready().then(() => {
      return this.storage.get(AUTH_TOKEN_KEY).then(token => {
        console.log('Token from storage: ', token);

        try {
          if (helper.isTokenExpired(token)) {
            // TODO: API - We need the token to be long lived. The client gets logged out a lot.
            console.log('Token is expired', helper.getTokenExpirationDate(token));
            token = null;
            this.setToken(token).then();
          } else {
            this.token.next(token);
          }
        } catch (e) {
          console.log('Token is corrupted');
          token = null;
          this.setToken(token).then();
        }

        if (token && decoded) {
          decoded = helper.decodeToken(token);
          console.log('Decoded: ', decoded);
          return decoded;
        }

        return token;
      });
    });
  }

  setToken(token) {
    console.log('Set token: ', token);
    return this.storage.set(AUTH_TOKEN_KEY, token).then(() => {
      this.token.next(token);

      const hasToken = !!token;

      if (!hasToken) {
        this.setProfile(null).then();
      }
    });
  }

  updateAvailableAgentLocation(location?: Geoposition, isAvailable = true) {
    this.getProfile().then(async profile => {
      if (!profile || !profile.id) {
        return;
      }

      if (!location) {
        location = await this.geolocation.getCurrentPosition();
      }

      const updateAgentAvailabilityParams = {
        isAvailable: location ? isAvailable : false,
        latitude: location && location.coords ? location.coords.latitude : 0,
        longitude: location && location.coords ? location.coords.longitude : 0,
      };

      const updateAgentAvailabilityParamsJson = JSON.stringify({...updateAgentAvailabilityParams, id: profile.id});

      if (this.lastUpdateAgentAvailabilityParams === updateAgentAvailabilityParamsJson) {
        console.log('updateAvailableAgentLocation skipped');
        return;
      }

      this.lastUpdateAgentAvailabilityParams = updateAgentAvailabilityParamsJson;

      this.subscribe(this.agent.updateAgentAvailability(updateAgentAvailabilityParams), res => {
        if (res.isSuccess && res.data) {
          this.setProfile(res.data).then();
        }
      });
    });
  }

  trackLocation(track = true) {
    if (!track) {
      if (this.tracker) {
        this.tracker.unsubscribe();
        this.geolocation.trackLocation(false);
      }
      return;
    }

    if (this.tracker) {
      return;
    }

    this.tracker = this.subscribe(this.geolocation.watchPosition(), async location => {
      this.updateAvailableAgentLocation(location);
    });
  }

  getProfile() {
    return this.plt.ready().then(() => {
      return this.storage.get(PROFILE_KEY).then((profile: Profile) => {
        console.log('Profile from storage: ', profile);
        this.setProfile(profile).then();
        return profile;
      });
    });
  }

  getInterests(interests: string) {
    return interests ? interests.split(',').map(interest => interest.trim()) : [];
  }

  getFirstInterest(interests: string) {
    return this.getInterests(interests)[0];
  }

  getProfileInterests(profile: Profile | User) {
    return this.getInterests(profile ? profile.interests : null);
  }

  getProfileFirstInterest(profile: Profile | User) {
    return this.getProfileInterests(profile)[0];
  }

  setProfile(profile: Profile) {
    console.log('Set profile: ', profile);

    if (JSON.stringify(this.profile.getValue()) === JSON.stringify(profile)) {
      console.log('Set profile skipped because is the same.');
      return new Promise(() => {
      });
    }

    return this.storage.set(PROFILE_KEY, profile).then(() => {
      const profileOld = this.profile.getValue();

      this.profile.next(profile);
      this.firstInterest.next(this.getProfileFirstInterest(profile));

      if (
        profile
        && profile.id
        && profile.role === Profile.RoleEnum.Agent
        && profile.agentProfile
        && profile.agentProfile.status === AgentProfile.StatusEnum.Available
      ) {
        if (!profileOld || profileOld.id !== profile.id) {
          this.geolocation.getCurrentPosition().then(location => {
            this.updateAvailableAgentLocation(location);

            this.trackLocation();
          }, () => {
            this.updateAvailableAgentLocation();
          });
        }
      } else {
        this.trackLocation(false);
      }
    });
  }

  refreshProfile(force = false) {
    return this.getToken().then(token => {
      if (!token) {
        console.log('Profile refresh without a token!');
        return;
      }

      return this.getProfile().then(profile => {
        if (!profile || !profile.id) {
          return;
        }

        if (
          !force
          && environment.checkForProfileUpdateInterval
          && this.lastProfileRefresh
          && ((new Date()).getTime() - this.lastProfileRefresh.getTime()) <= environment.checkForProfileUpdateInterval
        ) {
          return profile;
        }

        return this.account.getAccountProfile().toPromise().then(res => {
          if (!res.isSuccess || !res.data) {
            return;
          }

          this.lastProfileRefresh = new Date();

          console.log('Profile from API: ', res.data);
          this.setProfile(res.data).then();

          return res.data;
        });
      });
    });
  }

  loginAndGetProfile(credentials: Credentials) {
    let login: Observable<any>;

    if (credentials.token) {
      if (credentials.provider) {
        if (credentials.provider.toUpperCase() === 'APPLE') {
          login = this.account.doAppleLogin({
            idToken: credentials.id,
            code: credentials.token,
          });
        } else {
          login = this.account.doSocialLogin({
            username: credentials.username,
            token: credentials.token,
            provider: <SocialProvider.ProviderEnum>credentials.provider,
          });
        }
      } else {
        console.log('Token from registration: ', credentials.token);
        login = of({isSuccess: true, data: credentials.token});
      }
    } else {
      login = this.account.loginUser({
        username: credentials.username,
        password: credentials.password,
        accessScope: LoginCredentials.AccessScopeEnum.Frontend,
      });
    }

    return login.pipe(
      switchMap(res => {
        const badRes = {isSuccess: false, data: null};
        if (res.isSuccess && res.data) {
          console.log('Token from API: ', res.data);
          return from(this.setToken(res.data).then(() => res, () => badRes));
        }

        return throwError(res);
      }),
      switchMap(res => {
        if (res.isSuccess && res.data) {
          return this.account.getAccountProfile();
        }

        return throwError(res);
      }),
      switchMap(res => {
        if (res.isSuccess && res.data) {
          console.log('Profile from API: ', res.data);
        }

        this.lastProfileRefresh = new Date();

        return this.setProfile(res.data).then(() => {
          return res;
        });
      })
    );
  }

  getReturnUrlParam() {
    return this.route.queryParams.pipe(
      take(1),
      map(params => {
        return params.returnUrl;
      })
    );
  }

  redirectToReturnUrl(defaultUrl = '/app') {
    this.subscribe(this.getReturnUrlParam().pipe(take(1)), returnUrlParam => {
      const parsedUrl = new URL(returnUrlParam ? returnUrlParam : defaultUrl, window.location.href);
      const extras = {queryParams: {}};
      if (parsedUrl.searchParams) {
        parsedUrl.searchParams.forEach((v, k) => {
          extras.queryParams[k] = v;
        });
      }
      if (parsedUrl.hash) {
        extras['fragment'] = parsedUrl.hash.indexOf('#') === 0 ? parsedUrl.hash.substr(1) : parsedUrl.hash;
      }
      this.router.navigate([parsedUrl.pathname], extras).then();
    });
  }

  redirectWithReturnUrl(url = '/login', returnUrl: boolean | string = null, replaceUrl = false) {
    const handler = () => {
      console.log('Return URL: ', returnUrl);

      this.router.navigate([url ? url : '/login'], {
        queryParams: !returnUrl || typeof returnUrl !== 'string' || returnUrl === '/' ? {} : {
          returnUrl: returnUrl
        },
        replaceUrl
      }).then();
    };

    if (returnUrl === true || returnUrl === null) {
      this.subscribe(this.getReturnUrlParam(), returnUrlParam => {
        if (returnUrlParam) {
          returnUrl = returnUrlParam;
        } else if (returnUrl) {
          returnUrl = window.location.pathname + window.location.search + window.location.hash;
        }
        handler();
      });
    } else {
      handler();
    }
  }

  logoutWithRedirectWithReturnUrl(url = '/login', returnUrl: boolean | string = true, replaceUrl = false) {
    // Avoid infinite loop with http interceptor 401
    if (this.loggingOut) {
      return;
    }

    const redirect = () => this.redirectWithReturnUrl(url, returnUrl, replaceUrl);

    return this.getToken().then(token => {
      if (!token) {
        redirect();
        return;
      }

      this.loggingOut = true;

      // TODO: API - Perhaps should reset agent availability when there are no more active sockets on it. What about Push? AOD-178
      return this.account.logoutUser().pipe(
        finalize(() => {
          this.loggingOut = false;

          console.log('Logged out');

          // Always dump the token and force client logout
          return this.setToken(null).then(redirect);
        })
      ).toPromise();
    });
  }

  other(...profiles: User[]) {
    return profiles.find(profile => !this.profile.getValue() || profile && this.profile.getValue().id !== profile.id);
  }

  getAppleSignIn(): Promise<AppleSignInResponse> {
    return new Promise((resolve, reject) => {
      try {
        console.log('Apple sign in ');
        this.signInWithApple.signin(
          {
            requestedScopes: [
              ASAuthorizationAppleIDRequest.ASAuthorizationScopeFullName,
              ASAuthorizationAppleIDRequest.ASAuthorizationScopeEmail,
            ]
          }
        ).then(user => {
            try {
              console.log('Get Apple sign in from API: ', user);

              if (user && (!user.email || !user.fullName || !user.fullName.givenName && !user.fullName.familyName)) {
                this.storage.get(APPLE_SIGN_IN_KEY).then((userOld: AppleSignInResponse) => {
                  try {
                    console.log('Get Apple sign in from session for email or name: ', userOld);

                    if (userOld) {
                      if (!user.email) {
                        user.email = userOld.email;
                      }
                      if (!user.fullName || !user.fullName.givenName && !user.fullName.familyName) {
                        user.fullName = userOld.fullName;
                      }
                    }

                    this.setAppleSignIn(user);

                    resolve(user);
                  } catch (e) {
                    console.error(e);

                    this.setAppleSignIn(user);

                    resolve(user);
                  }
                }, err => {
                  console.error(err);

                  this.setAppleSignIn(user);

                  resolve(user);
                });
              } else {
                this.setAppleSignIn(user);

                resolve(user);
              }
            } catch (e) {
              console.error(e);

              this.setAppleSignIn(user);

              resolve(user);
            }
          },
          e => {
            console.error(e);
            reject(e);
          });
      } catch (e) {
        console.error(e);
        reject(e);
      }
    });
  }

  setAppleSignIn(user: AppleSignInResponse) {
    if (user && (user.email || user.fullName && (user.fullName.givenName || user.fullName.familyName))) {
      console.log('Set Apple sign in: ', user);
      this.storage.set(APPLE_SIGN_IN_KEY, user).then();
    }
  }
}
