import {Injectable} from '@angular/core';
import {Storage} from '@ionic/storage';
import {Platform} from '@ionic/angular';
import {Unsubscriber} from '../utils/unsubscriber';
import {LoadingService} from './loading.service';

export const SPLASH_DONE_KEY = 'SPLASH_DONE';

@Injectable({
  providedIn: 'root'
})

export class SplashService extends Unsubscriber {
  constructor(
    public storage: Storage,
    public plt: Platform,
    public loadingService: LoadingService,
  ) {
    super(loadingService);
  }

  getSplashDone() {
    return this.plt.ready().then(() => {
      return this.storage.get(SPLASH_DONE_KEY).then(done => {
        console.log('Splash done from storage: ', done);
        return done;
      });
    });
  }

  setSplashDone(done = true) {
    return this.storage.set(SPLASH_DONE_KEY, done).then();
  }
}
