import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {Router} from '@angular/router';
import {ToastController} from '@ionic/angular';
import {environment} from '../../environments/environment';
import {TranslateService} from '@ngx-translate/core';
import {AuthService} from './auth.service';
import {Unsubscriber} from '../utils/unsubscriber';
import {LanguageService} from './language.service';
import {LoadingService} from './loading.service';

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService extends Unsubscriber implements HttpInterceptor {

  constructor(
    public router: Router,
    public toastCtrl: ToastController,
    public translate: TranslateService,
    public auth: AuthService,
    public language: LanguageService,
    public loadingService: LoadingService,
  ) {
    super(loadingService);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // TODO: App - Perhaps use cordova native requests plugin cordova-plugin-http for CORS issues with WKWebView?

    const language = this.language.language.getValue();
    if (language) {
      let headers = request.headers;
      const headerName = 'Accept-Language';
      const headerValue = headers.get(headerName);

      headers = headers.set(headerName, language + (headerValue ? ',' + headerValue : ''));

      request = request.clone({
        headers: headers
      });
    }

    if (!request.url.startsWith(environment.api.basePath)) {
      return next.handle(request);
    }

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          console.log('HttpInterceptorService event: ', event);
          if (request.url.indexOf(environment.api.basePath) !== -1) {
            if (event.body) {
              // TODO: App - Perhaps some of these POSTs might benefit from a toast message.
              if (event.body.message) {
                if (event.body.isSuccess) {
                  this.presentToast({message: event.body.message, color: 'success'}).then();
                } else if (event.body.isSuccess === undefined || event.body.isSuccess === null) {
                  this.presentToast({message: event.body.message, color: 'warning'}).then();
                } else {
                  this.presentToast({message: event.body.message, color: 'danger'}).then();
                }
              } else {
                if (event.body.isSuccess === undefined || event.body.isSuccess === null) {
                  if (typeof event.body === 'string') {
                    this.presentToast({message: event.body, color: 'warning'}).then();
                  } else {
                    this.subscribe(this.translate.get('http-interceptor.warning'), message => {
                      this.presentToast({message, color: 'warning'}).then();
                    });
                  }
                } else if (!event.body.isSuccess) {
                  this.subscribe(this.translate.get('http-interceptor.danger'), message => {
                    this.presentToast({message, color: 'danger'}).then();
                  });
                } else if (request.method.toUpperCase() !== 'GET') {
                  // Let's not pollute the UI with unnecessary messages
                  /*
                  this.subscribe(this.translate.get('http-interceptor.success'), message => {
                    this.presentToast({message, color: 'success'}).then();
                  });
                  */
                }
              }
            } else {
              this.subscribe(this.translate.get('http-interceptor.warning'), message => {
                this.presentToast({message, color: 'warning'}).then();
              });
            }
          }
        }

        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        console.error(error);

        if (error.status === 401 && !this.router.isActive('/login', false)) {
          console.log('Redirecting to login');
          this.then(this.auth.logoutWithRedirectWithReturnUrl(), null, null, true);
        }

        if (typeof error.error === 'string') {
          this.presentToast({message: error.error, color: 'danger'}).then();
        } else if (error.error && typeof error.error.message === 'string') {
          this.presentToast({message: error.error.message, color: 'danger'}).then();
        } else if (typeof error.statusText === 'string') {
          this.presentToast({message: error.statusText, color: 'danger'}).then();
        } else if (typeof error.message === 'string') {
          this.presentToast({message: error.message, color: 'danger'}).then();
        } else {
          this.subscribe(this.translate.get('http-interceptor.danger'), message => {
            this.presentToast({message, color: 'danger'}).then();
          });
        }

        if (error.error && error.error.errors && error.error.errors instanceof Array && error.error.errors.length) {
          const errors = new Map<string, string[]>();
          error.error.errors.forEach(err => {
            if (err.key) {
              if (!errors.has(err.key)) {
                errors.set(err.key, []);
              }
              errors.get(err.key).push(err.message);
            }
          });
          console.error(errors);
          error.error.errors = errors;
        }

        return throwError(error);
      })
    );
  }

  async presentToast(options) {
    console.log(options.message);
    options = {
      ...environment.toast,
      ...options,
      closeButtonText: await this.translate.get('toast.close').toPromise()
    };
    const toast = await this.toastCtrl.create(options);
    toast.present();
  }
}
