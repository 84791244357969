/**
 * AgentOnDuty API Documentation
 * The official AgentOnDuty API, documented with the OpenAPI 3.0 specification
 *
 * OpenAPI spec version: THE API VERSION IS NOW PROGRAMMATICALLY SET AND RETRIEVED! Use the public endpoint for reading the current version.
 * Contact: demo@synergogroup.net
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface RegisterTransactionDto { 
    clientId?: string;
    clientName: string;
    /**
     * use public endpoint to get available client types, for the select; send back the key, not the displayAs
     */
    clientType: RegisterTransactionDto.ClientTypeEnum;
    propertyAddress: string;
    closingDate: string;
    price: number;
    currency: string;
    fee: number;
    /**
     * use public endpoint to get available payment methods, for the select; send back the key, not the displayAs
     */
    method: RegisterTransactionDto.MethodEnum;
    notes?: string;
    /**
     * in the event an Agent is picking Credit card as his preferred payment method he will be prompted to complete the payment integration with Stripe; the resulting toke will be sent
     */
    stripeToken?: string;
}
export namespace RegisterTransactionDto {
    export type ClientTypeEnum = 'buyer' | 'seller' | 'landlord' | 'tenant' | 'mortgage';
    export const ClientTypeEnum = {
        Buyer: 'buyer' as ClientTypeEnum,
        Seller: 'seller' as ClientTypeEnum,
        Landlord: 'landlord' as ClientTypeEnum,
        Tenant: 'tenant' as ClientTypeEnum,
        Mortgage: 'mortgage' as ClientTypeEnum
    };
    export type MethodEnum = 'eft' | 'email_transfer' | 'wire_transfer' | 'credit_card' | 'cheque';
    export const MethodEnum = {
        Eft: 'eft' as MethodEnum,
        EmailTransfer: 'email_transfer' as MethodEnum,
        WireTransfer: 'wire_transfer' as MethodEnum,
        CreditCard: 'credit_card' as MethodEnum,
        Cheque: 'cheque' as MethodEnum
    };
}