/**
 * AgentOnDuty API Documentation
 * The official AgentOnDuty API, documented with the OpenAPI 3.0 specification
 *
 * OpenAPI spec version: THE API VERSION IS NOW PROGRAMMATICALLY SET AND RETRIEVED! Use the public endpoint for reading the current version.
 * Contact: demo@synergogroup.net
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { BaseApiResponseDto } from '../model/baseApiResponseDto';
import { DeleteListingDto } from '../model/deleteListingDto';
import { InlineResponse20023 } from '../model/inlineResponse20023';
import { InlineResponse20032 } from '../model/inlineResponse20032';
import { InlineResponse20033 } from '../model/inlineResponse20033';
import { InlineResponse20034 } from '../model/inlineResponse20034';
import { UpdateListingActiveStateDto } from '../model/updateListingActiveStateDto';
import { UpdateListingDisplayImageDto } from '../model/updateListingDisplayImageDto';
import { UpsertListingDto } from '../model/upsertListingDto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class PropertyService {

    protected basePath = 'https://agentonduty-dev.azurewebsites.net/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * Deletes an existing property listing
     * @param id The Property listing Id to delete object for
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteListing(id: string, observe?: 'body', reportProgress?: boolean): Observable<BaseApiResponseDto>;
    public deleteListing(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BaseApiResponseDto>>;
    public deleteListing(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BaseApiResponseDto>>;
    public deleteListing(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteListing.');
        }

        let headers = this.defaultHeaders;

        // authentication (JWTbearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<BaseApiResponseDto>(`${this.basePath}/property/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * (for Admin) Removes an existing listing
     * @param body Payload object
     * @param id The listing Id to perform deletion for
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteListingByAdmin(body: DeleteListingDto, id: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20034>;
    public deleteListingByAdmin(body: DeleteListingDto, id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20034>>;
    public deleteListingByAdmin(body: DeleteListingDto, id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20034>>;
    public deleteListingByAdmin(body: DeleteListingDto, id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling deleteListingByAdmin.');
        }

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteListingByAdmin.');
        }

        let headers = this.defaultHeaders;

        // authentication (JWTbearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<InlineResponse20034>(`${this.basePath}/property/${encodeURIComponent(String(id))}/remove-by-admin`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * (for Admin) Retrieves all sourced listings in a paginated manner, with filtering and sorting.
     * @param search The search term to filter Listings by
     * @param page Requsted current page of results
     * @param size The amount of results one page must have
     * @param sortBy Attribute name to sort results by
     * @param isAscending Sorting order (true if Ascending, false is Descending)
     * @param userId Get Listings for specific Agent or Customer
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllSourcedListings(search?: string, page?: number, size?: number, sortBy?: string, isAscending?: boolean, userId?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20023>;
    public getAllSourcedListings(search?: string, page?: number, size?: number, sortBy?: string, isAscending?: boolean, userId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20023>>;
    public getAllSourcedListings(search?: string, page?: number, size?: number, sortBy?: string, isAscending?: boolean, userId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20023>>;
    public getAllSourcedListings(search?: string, page?: number, size?: number, sortBy?: string, isAscending?: boolean, userId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (search !== undefined && search !== null) {
            queryParameters = queryParameters.set('search', <any>search);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sortBy !== undefined && sortBy !== null) {
            queryParameters = queryParameters.set('sortBy', <any>sortBy);
        }
        if (isAscending !== undefined && isAscending !== null) {
            queryParameters = queryParameters.set('isAscending', <any>isAscending);
        }
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }

        let headers = this.defaultHeaders;

        // authentication (JWTbearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<InlineResponse20023>(`${this.basePath}/property/admin/all`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Returns the details of a property listing
     * @param id The listing Id to retrieve data for
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getListing(id: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20033>;
    public getListing(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20033>>;
    public getListing(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20033>>;
    public getListing(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getListing.');
        }

        let headers = this.defaultHeaders;

        // authentication (JWTbearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<InlineResponse20033>(`${this.basePath}/property/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Retrieves all property listings created by the logged user.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMyListings(observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20032>;
    public getMyListings(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20032>>;
    public getMyListings(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20032>>;
    public getMyListings(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (JWTbearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<InlineResponse20032>(`${this.basePath}/property`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Register a new property listing in their portfolio.
     * @param body Property listing to register
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public registerListing(body: UpsertListingDto, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20033>;
    public registerListing(body: UpsertListingDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20033>>;
    public registerListing(body: UpsertListingDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20033>>;
    public registerListing(body: UpsertListingDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling registerListing.');
        }

        let headers = this.defaultHeaders;

        // authentication (JWTbearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<InlineResponse20033>(`${this.basePath}/property`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Aggregated search through specific agent properties for specific coordinates and purpose. First, agent own sourced listings in proximity, then sourced listings in proximity.
     * @param purpose Purpose values
     * @param agentId Corresponding Agent Id, from the application
     * @param latitude Latitude value
     * @param longitude Longitude value
     * @param distance Distance, in kilometers, default value is 30 kilometers
     * @param limit Results limit count; default is 5
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchPropertiesForAgentInProximity(purpose: string, agentId: string, latitude: number, longitude: number, distance?: number, limit?: number, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20032>;
    public searchPropertiesForAgentInProximity(purpose: string, agentId: string, latitude: number, longitude: number, distance?: number, limit?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20032>>;
    public searchPropertiesForAgentInProximity(purpose: string, agentId: string, latitude: number, longitude: number, distance?: number, limit?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20032>>;
    public searchPropertiesForAgentInProximity(purpose: string, agentId: string, latitude: number, longitude: number, distance?: number, limit?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (purpose === null || purpose === undefined) {
            throw new Error('Required parameter purpose was null or undefined when calling searchPropertiesForAgentInProximity.');
        }

        if (agentId === null || agentId === undefined) {
            throw new Error('Required parameter agentId was null or undefined when calling searchPropertiesForAgentInProximity.');
        }

        if (latitude === null || latitude === undefined) {
            throw new Error('Required parameter latitude was null or undefined when calling searchPropertiesForAgentInProximity.');
        }

        if (longitude === null || longitude === undefined) {
            throw new Error('Required parameter longitude was null or undefined when calling searchPropertiesForAgentInProximity.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (purpose !== undefined && purpose !== null) {
            queryParameters = queryParameters.set('purpose', <any>purpose);
        }
        if (agentId !== undefined && agentId !== null) {
            queryParameters = queryParameters.set('agentId', <any>agentId);
        }
        if (latitude !== undefined && latitude !== null) {
            queryParameters = queryParameters.set('latitude', <any>latitude);
        }
        if (longitude !== undefined && longitude !== null) {
            queryParameters = queryParameters.set('longitude', <any>longitude);
        }
        if (distance !== undefined && distance !== null) {
            queryParameters = queryParameters.set('distance', <any>distance);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }

        let headers = this.defaultHeaders;

        // authentication (JWTbearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<InlineResponse20032>(`${this.basePath}/property/search/for-agent`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Search perspective over logged user own listings. First, user own sourced listings, then sourced listings in proximity. Proximity being defined as passed coordinates or last recorded user coordinates.
     * @param latitude Latitude value, optional; if not provided, API will use agent last recorded latitude
     * @param longitude Longitude value, optional; if not provided, API will use agent last recorded latitude
     * @param distance Distance, in kilometers, default value is 30 kilometers
     * @param limit Results limit count; default is 5
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchPropertiesForLoggedUser(latitude?: number, longitude?: number, distance?: number, limit?: number, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20032>;
    public searchPropertiesForLoggedUser(latitude?: number, longitude?: number, distance?: number, limit?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20032>>;
    public searchPropertiesForLoggedUser(latitude?: number, longitude?: number, distance?: number, limit?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20032>>;
    public searchPropertiesForLoggedUser(latitude?: number, longitude?: number, distance?: number, limit?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (latitude !== undefined && latitude !== null) {
            queryParameters = queryParameters.set('latitude', <any>latitude);
        }
        if (longitude !== undefined && longitude !== null) {
            queryParameters = queryParameters.set('longitude', <any>longitude);
        }
        if (distance !== undefined && distance !== null) {
            queryParameters = queryParameters.set('distance', <any>distance);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }

        let headers = this.defaultHeaders;

        // authentication (JWTbearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<InlineResponse20032>(`${this.basePath}/property/search/for-me`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Aggregated search through all properties for specific coordinates and purpose. From sourced listings.
     * @param latitude Latitude value
     * @param longitude Longitude value
     * @param purpose Purpose values, optional
     * @param distance Distance, in kilometers, default value is 30 kilometers
     * @param limit Results limit count; default is 5
     * @param includeExternalListings Request external listings fetching
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchPropertiesInProximity(latitude: number, longitude: number, purpose?: string, distance?: number, limit?: number, includeExternalListings?: boolean, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20032>;
    public searchPropertiesInProximity(latitude: number, longitude: number, purpose?: string, distance?: number, limit?: number, includeExternalListings?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20032>>;
    public searchPropertiesInProximity(latitude: number, longitude: number, purpose?: string, distance?: number, limit?: number, includeExternalListings?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20032>>;
    public searchPropertiesInProximity(latitude: number, longitude: number, purpose?: string, distance?: number, limit?: number, includeExternalListings?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (latitude === null || latitude === undefined) {
            throw new Error('Required parameter latitude was null or undefined when calling searchPropertiesInProximity.');
        }

        if (longitude === null || longitude === undefined) {
            throw new Error('Required parameter longitude was null or undefined when calling searchPropertiesInProximity.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (purpose !== undefined && purpose !== null) {
            queryParameters = queryParameters.set('purpose', <any>purpose);
        }
        if (latitude !== undefined && latitude !== null) {
            queryParameters = queryParameters.set('latitude', <any>latitude);
        }
        if (longitude !== undefined && longitude !== null) {
            queryParameters = queryParameters.set('longitude', <any>longitude);
        }
        if (distance !== undefined && distance !== null) {
            queryParameters = queryParameters.set('distance', <any>distance);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (includeExternalListings !== undefined && includeExternalListings !== null) {
            queryParameters = queryParameters.set('includeExternalListings', <any>includeExternalListings);
        }

        let headers = this.defaultHeaders;

        // authentication (JWTbearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<InlineResponse20032>(`${this.basePath}/property/search/all`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Updates an existing property listing
     * @param body Update payload object
     * @param id The listing Id to perform update for
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateListing(body: UpsertListingDto, id: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20033>;
    public updateListing(body: UpsertListingDto, id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20033>>;
    public updateListing(body: UpsertListingDto, id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20033>>;
    public updateListing(body: UpsertListingDto, id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateListing.');
        }

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling updateListing.');
        }

        let headers = this.defaultHeaders;

        // authentication (JWTbearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<InlineResponse20033>(`${this.basePath}/property/${encodeURIComponent(String(id))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * (for Admin) Updates the active state of an existing property listing
     * @param body Update payload object
     * @param id The listing Id to perform update active state for
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateListingActiveState(body: UpdateListingActiveStateDto, id: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20033>;
    public updateListingActiveState(body: UpdateListingActiveStateDto, id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20033>>;
    public updateListingActiveState(body: UpdateListingActiveStateDto, id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20033>>;
    public updateListingActiveState(body: UpdateListingActiveStateDto, id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateListingActiveState.');
        }

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling updateListingActiveState.');
        }

        let headers = this.defaultHeaders;

        // authentication (JWTbearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<InlineResponse20033>(`${this.basePath}/property/${encodeURIComponent(String(id))}/active-state`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Updates the display image of an existing property listing
     * @param body Update payload object
     * @param id The listing Id to perform update display image for
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateListingDisplayImage(body: UpdateListingDisplayImageDto, id: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20033>;
    public updateListingDisplayImage(body: UpdateListingDisplayImageDto, id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20033>>;
    public updateListingDisplayImage(body: UpdateListingDisplayImageDto, id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20033>>;
    public updateListingDisplayImage(body: UpdateListingDisplayImageDto, id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateListingDisplayImage.');
        }

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling updateListingDisplayImage.');
        }

        let headers = this.defaultHeaders;

        // authentication (JWTbearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<InlineResponse20033>(`${this.basePath}/property/${encodeURIComponent(String(id))}/display-image`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
