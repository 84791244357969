/**
 * AgentOnDuty API Documentation
 * The official AgentOnDuty API, documented with the OpenAPI 3.0 specification
 *
 * OpenAPI spec version: THE API VERSION IS NOW PROGRAMMATICALLY SET AND RETRIEVED! Use the public endpoint for reading the current version.
 * Contact: demo@synergogroup.net
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ListingImageDto } from './listingImageDto';
import { ListingRulesDto } from './listingRulesDto';
import { UserMetadataDto } from './userMetadataDto';

export interface ListingDto { 
    id?: string;
    listingType?: ListingDto.ListingTypeEnum;
    purpose?: ListingDto.PurposeEnum;
    shortDescription?: string;
    videoLink?: string;
    commission?: string;
    address1?: string;
    address2?: string;
    computedAddress?: string;
    longDescription?: string;
    displayImageUrl?: string;
    /**
     * Used by Admin client, mobile client should not receive listings that are inactive
     */
    isActive?: boolean;
    /**
     * Used by Admin client
     */
    stateReason?: string;
    images?: Array<ListingImageDto>;
    price?: string;
    currency?: string;
    rawPrice?: number;
    latitude?: number;
    longitude?: number;
    beds?: number;
    halfBaths?: number;
    fullBaths?: number;
    sqFt?: string;
    lotSize?: string;
    createdOn?: string;
    createdBy?: UserMetadataDto;
    updatedOn?: string;
    rules?: ListingRulesDto;
    mlsPropertyId?: string;
    /**
     * Reference number for Canadian based properties
     */
    mlsReferenceNumber?: string;
}
export namespace ListingDto {
    export type ListingTypeEnum = 'sourced' | 'external';
    export const ListingTypeEnum = {
        Sourced: 'sourced' as ListingTypeEnum,
        External: 'external' as ListingTypeEnum
    };
    export type PurposeEnum = 'for_sale' | 'for_rent';
    export const PurposeEnum = {
        Sale: 'for_sale' as PurposeEnum,
        Rent: 'for_rent' as PurposeEnum
    };
}