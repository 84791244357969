import {Injectable} from '@angular/core';
import {Unsubscriber} from '../utils/unsubscriber';
import {ModalController, ToastController} from '@ionic/angular';
import {CallComponent} from '../components/call/call.component';
import {LoadingService} from './loading.service';
import {environment} from '../../environments/environment';
import {TranslateService} from '@ngx-translate/core';
import {
  CallSessionDto as CallSession,
  CommonUserResponseDto as User,
  UserMetadataDto as Profile,
  UserService
} from '../../../swagger-client';
import {InAppBrowser} from '@ionic-native/in-app-browser/ngx';

@Injectable({
  providedIn: 'root'
})
export class CallService extends Unsubscriber {
  callModal: HTMLIonModalElement;

  constructor(
    public modalCtrl: ModalController,
    public loadingService: LoadingService,
    public toastCtrl: ToastController,
    public translate: TranslateService,
    public iab: InAppBrowser,
    public user: UserService,
  ) {
    super(loadingService);
  }

  check(user: string | Profile | User = null, video: boolean = null) {
    if (environment.inAppCalls && (typeof user === 'string' || user.id)) {
      return true;
    }

    if (video) {
      return false;
    }

    if (user && typeof user !== 'string' && user.phoneNumber !== undefined) {
      return user.phoneNumber;
    }

    return false;
  }

  callPhoneNumber(phoneNumber: string) {
    phoneNumber = phoneNumber.replace(/\s/g, '');

    if (!phoneNumber) {
      return;
    }

    this.iab.create('tel:' + phoneNumber, '_system');
  }

  async call(user: string | Profile | User = null, video: boolean = null, initiator = true, session: string | CallSession = null) {
    if (!environment.inAppCalls) {
      if (video) {
        return;
      }

      if (user && typeof user !== 'string' && user.phoneNumber) {
        this.callPhoneNumber(user.phoneNumber);
        if (user.id) {
          this.subscribe(this.user.savePhoneCall({otherPartyId: user.id}));
        }
      }

      return;
    }

    await this.hangup();

    this.callModal = await this.modalCtrl.create({
      component: CallComponent,
      backdropDismiss: false, // prevent dismissing the call by clicking outside of it
      cssClass: 'call-modal',
      componentProps: {
        userId: !user || typeof user === 'string' ? user : user.id,
        profile: !user || typeof user !== 'string' ? user : null,
        sessionId: !session || typeof session === 'string' ? session : session.id,
        session: !session || typeof session !== 'string' ? session : null,
        initiator: initiator,
        video: video === null && session && typeof session !== 'string' ? session.withVideo : video,
      },
    });

    this.callModal.onDidDismiss().then(() => this.callModal = null);

    await this.callModal.present();

    return this.callModal;
  }

  async hangup() {
    try {
      if (this.callModal) {
        await this.callModal.dismiss();
      }
    } catch (err) {
      await this.modalDismissError(err);
    }
  }

  async modalDismissError(err) {
    if (err === 'overlay does not exist') {
      return;
    }

    const toast = await this.toastCtrl.create({
      ...environment.toast,
      color: 'danger',
      message: await this.translate.get('call.cannot-close').toPromise(),
      closeButtonText: await this.translate.get('toast.close').toPromise()
    });
    toast.present();
  }
}
