import {ElementRef, Injectable} from '@angular/core';
import {Unsubscriber} from '../utils/unsubscriber';
import {FileService, StartFileUploadDto as StartFileUpload} from '../../../swagger-client';
import {throwError} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {switchMap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {LoadingService} from './loading.service';

@Injectable({
  providedIn: 'root'
})
export class PictureService extends Unsubscriber {
  constructor(
    public file: FileService,
    public translate: TranslateService,
    public http: HttpClient,
    public loadingService: LoadingService,
  ) {
    super(loadingService);
  }

  validate(imageData: string): boolean {
    // data:image/png;base64,...
    const parts = imageData.split(';');

    let typeParts = parts[0].split(':');
    if (typeParts.length > 1) {
      typeParts = typeParts[1].split('/');
      if (typeParts[0] === 'image') {
        return true;
      }
    }

    return false;
  }

  upload(imageData: string, uploadType: StartFileUpload.UploadTypeEnum, propertyListingId?: string) {
    let extension = 'jpg';
    // let size = 0;
    let type = '';
    let content = '';

    // data:image/png;base64,...
    const parts = imageData.split(';');

    let typeParts = parts[0].split(':');
    if (typeParts.length > 1) {
      type = typeParts[1];
      typeParts = type.split('/');
      extension = typeParts[1] === 'jpeg' ? 'jpg' : typeParts[1];
    }

    if (parts.length > 1) {
      const contentParts = parts[1].split(',');
      if (contentParts.length > 1) {
        content = contentParts[1];
        /*
        if (contentParts[1].substr(-1) === '=') {
          size = size * 3 / 4 - 1;
          if (contentParts[1].substr(-1) === '==') {
            size -= 1;
          }
        }
        */
      }
    }

    const byteString = atob(content);
    const size = byteString.length;
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ab], {type: type});
    // const formData = new FormData();
    // formData.append('source', blob);

    const params: StartFileUpload = {
      fileName: (new Date()).getTime() + '_' + Math.round(Math.random() * Math.pow(10, 6)) + '.' + extension,
      uploadType,
      size: size,
    };

    if (propertyListingId) {
      params.propertyListingId = propertyListingId;
    }

    return this.file.startUpload(params).pipe(switchMap(uploadTaskRes => {
      if (uploadTaskRes.isSuccess && uploadTaskRes.data && uploadTaskRes.data.sasUrl) {
        return this.http.put(uploadTaskRes.data.sasUrl, blob, {headers: {'x-ms-blob-type': 'BlockBlob'}})
          .pipe(switchMap(() => {
            return this.file.publishUpload(uploadTaskRes.data.id);
          }));
      }

      return throwError(uploadTaskRes);
    }));
  }


  fit(video: ElementRef, wrapper: ElementRef, cameraFittingFull: boolean = null) {
    if (
      !video || !video.nativeElement || !video.nativeElement.srcObject
      || !wrapper || !wrapper.nativeElement
    ) {
      return;
    }

    const tracks = video.nativeElement.srcObject.getVideoTracks();
    if (!tracks || !tracks.length) {
      return;
    }

    const track = tracks[0];
    const settings = track.getSettings();
    let width = wrapper.nativeElement.clientWidth;
    let height = wrapper.nativeElement.clientHeight;
    console.log(width, height);
    console.log(settings.width, settings.height);
    let scale = 1;
    if (settings.width && settings.height) {
      if (cameraFittingFull === null) {
        cameraFittingFull = environment.cameraFittingFull;
      }

      scale = cameraFittingFull ?
        Math.max(width / settings.width, height / settings.height) :
        Math.min(width / settings.width, height / settings.height);
    }
    console.log(scale);
    width = settings.width * scale;
    height = settings.height * scale;
    console.log(width, height);
    video.nativeElement.style.width = (width ? width : 0) + 'px';
    video.nativeElement.style.height = (height ? height : 0) + 'px';
  }
}
