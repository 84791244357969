/**
 * AgentOnDuty API Documentation
 * The official AgentOnDuty API, documented with the OpenAPI 3.0 specification
 *
 * OpenAPI spec version: THE API VERSION IS NOW PROGRAMMATICALLY SET AND RETRIEVED! Use the public endpoint for reading the current version.
 * Contact: demo@synergogroup.net
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { BaseApiResponseDto } from '../model/baseApiResponseDto';
import { GetTwilioTokenDto } from '../model/getTwilioTokenDto';
import { InlineResponse20029 } from '../model/inlineResponse20029';
import { InlineResponse20030 } from '../model/inlineResponse20030';
import { InlineResponse20031 } from '../model/inlineResponse20031';
import { UpdateCallVideoStateDto } from '../model/updateCallVideoStateDto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class CallService {

    protected basePath = 'https://agentonduty-dev.azurewebsites.net/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * Mark a call session as finished when one party left
     * @param id The Call session Id
     * @param body Not relevant,
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public finishCall(id: string, body?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20030>;
    public finishCall(id: string, body?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20030>>;
    public finishCall(id: string, body?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20030>>;
    public finishCall(id: string, body?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling finishCall.');
        }


        let headers = this.defaultHeaders;

        // authentication (JWTbearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'text/plain'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<InlineResponse20030>(`${this.basePath}/call/finish/${encodeURIComponent(String(id))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Retrieves all call sessions (for Admin), in a paginated manner, with filtering and sorting.
     * @param search The search term to filter Transactions by
     * @param page Requsted current page of results
     * @param size The amount of results one page must have
     * @param sortBy Attribute name to sort results by
     * @param isAscending Sorting order (true if Ascending, false is Descending)
     * @param otherPartyId Get call sessions for a specific User Id in which he is either a sender or recipient
     * @param fromCreationDate Get call sessions with a creation date greater than or equal to the supplied filter
     * @param toCreationDate Get call sessions with a creation date less than or equal to the supplied filter
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllCallsForAdmin(search?: string, page?: number, size?: number, sortBy?: string, isAscending?: boolean, otherPartyId?: string, fromCreationDate?: string, toCreationDate?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20031>;
    public getAllCallsForAdmin(search?: string, page?: number, size?: number, sortBy?: string, isAscending?: boolean, otherPartyId?: string, fromCreationDate?: string, toCreationDate?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20031>>;
    public getAllCallsForAdmin(search?: string, page?: number, size?: number, sortBy?: string, isAscending?: boolean, otherPartyId?: string, fromCreationDate?: string, toCreationDate?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20031>>;
    public getAllCallsForAdmin(search?: string, page?: number, size?: number, sortBy?: string, isAscending?: boolean, otherPartyId?: string, fromCreationDate?: string, toCreationDate?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {









        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (search !== undefined && search !== null) {
            queryParameters = queryParameters.set('search', <any>search);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sortBy !== undefined && sortBy !== null) {
            queryParameters = queryParameters.set('sortBy', <any>sortBy);
        }
        if (isAscending !== undefined && isAscending !== null) {
            queryParameters = queryParameters.set('isAscending', <any>isAscending);
        }
        if (otherPartyId !== undefined && otherPartyId !== null) {
            queryParameters = queryParameters.set('otherPartyId', <any>otherPartyId);
        }
        if (fromCreationDate !== undefined && fromCreationDate !== null) {
            queryParameters = queryParameters.set('fromCreationDate', <any>fromCreationDate);
        }
        if (toCreationDate !== undefined && toCreationDate !== null) {
            queryParameters = queryParameters.set('toCreationDate', <any>toCreationDate);
        }

        let headers = this.defaultHeaders;

        // authentication (JWTbearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<InlineResponse20031>(`${this.basePath}/call/admin/all`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Returns the details of a call session
     * @param id The Call session Id to retrieve data for
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCall(id: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20030>;
    public getCall(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20030>>;
    public getCall(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20030>>;
    public getCall(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getCall.');
        }

        let headers = this.defaultHeaders;

        // authentication (JWTbearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<InlineResponse20030>(`${this.basePath}/call/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Retrieves all my call sessions in a paginated manner, with filtering and sorting.
     * @param search The search term to filter Transactions by
     * @param page Requsted current page of results
     * @param size The amount of results one page must have
     * @param sortBy Attribute name to sort results by
     * @param isAscending Sorting order (true if Ascending, false is Descending)
     * @param otherPartyId Get call sessions for a specific User Id in which he is either a sender or recipient
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMyCalls(search?: string, page?: number, size?: number, sortBy?: string, isAscending?: boolean, otherPartyId?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20031>;
    public getMyCalls(search?: string, page?: number, size?: number, sortBy?: string, isAscending?: boolean, otherPartyId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20031>>;
    public getMyCalls(search?: string, page?: number, size?: number, sortBy?: string, isAscending?: boolean, otherPartyId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20031>>;
    public getMyCalls(search?: string, page?: number, size?: number, sortBy?: string, isAscending?: boolean, otherPartyId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (search !== undefined && search !== null) {
            queryParameters = queryParameters.set('search', <any>search);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sortBy !== undefined && sortBy !== null) {
            queryParameters = queryParameters.set('sortBy', <any>sortBy);
        }
        if (isAscending !== undefined && isAscending !== null) {
            queryParameters = queryParameters.set('isAscending', <any>isAscending);
        }
        if (otherPartyId !== undefined && otherPartyId !== null) {
            queryParameters = queryParameters.set('otherPartyId', <any>otherPartyId);
        }

        let headers = this.defaultHeaders;

        // authentication (JWTbearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<InlineResponse20031>(`${this.basePath}/call`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Authorize endpoint that grants a new Twilio token for a new or existing call session.
     * @param body Payload for token retrieval
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTwilioToken(body: GetTwilioTokenDto, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20029>;
    public getTwilioToken(body: GetTwilioTokenDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20029>>;
    public getTwilioToken(body: GetTwilioTokenDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20029>>;
    public getTwilioToken(body: GetTwilioTokenDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling getTwilioToken.');
        }

        let headers = this.defaultHeaders;

        // authentication (JWTbearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<InlineResponse20029>(`${this.basePath}/call/authorize`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Join an existing call session
     * @param id The Call session Id
     * @param body Not relevant,
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public joinCall(id: string, body?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20030>;
    public joinCall(id: string, body?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20030>>;
    public joinCall(id: string, body?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20030>>;
    public joinCall(id: string, body?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling joinCall.');
        }


        let headers = this.defaultHeaders;

        // authentication (JWTbearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'text/plain'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<InlineResponse20030>(`${this.basePath}/call/join/${encodeURIComponent(String(id))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Reject a call session where you have been invited to
     * @param id The Call session Id
     * @param body Not relevant,
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public rejectCall(id: string, body?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20030>;
    public rejectCall(id: string, body?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20030>>;
    public rejectCall(id: string, body?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20030>>;
    public rejectCall(id: string, body?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling rejectCall.');
        }


        let headers = this.defaultHeaders;

        // authentication (JWTbearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'text/plain'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<InlineResponse20030>(`${this.basePath}/call/reject/${encodeURIComponent(String(id))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Set the call session video status value
     * @param id The Call session Id
     * @param body Payload for update operation
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateCallVideoState(id: string, body?: UpdateCallVideoStateDto, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20030>;
    public updateCallVideoState(id: string, body?: UpdateCallVideoStateDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20030>>;
    public updateCallVideoState(id: string, body?: UpdateCallVideoStateDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20030>>;
    public updateCallVideoState(id: string, body?: UpdateCallVideoStateDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling updateCallVideoState.');
        }


        let headers = this.defaultHeaders;

        // authentication (JWTbearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<InlineResponse20030>(`${this.basePath}/call/toggle-video-status/${encodeURIComponent(String(id))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
