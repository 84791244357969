/**
 * AgentOnDuty API Documentation
 * The official AgentOnDuty API, documented with the OpenAPI 3.0 specification
 *
 * OpenAPI spec version: THE API VERSION IS NOW PROGRAMMATICALLY SET AND RETRIEVED! Use the public endpoint for reading the current version.
 * Contact: demo@synergogroup.net
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface StartFileUploadDto { 
    fileName: string;
    size: number;
    uploadType: StartFileUploadDto.UploadTypeEnum;
    propertyListingId?: string;
}
export namespace StartFileUploadDto {
    export type UploadTypeEnum = 'user_profile' | 'property_listing';
    export const UploadTypeEnum = {
        UserProfile: 'user_profile' as UploadTypeEnum,
        PropertyListing: 'property_listing' as UploadTypeEnum
    };
}