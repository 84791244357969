import {Component, ElementRef, Input, ViewChild} from '@angular/core';
import {AlertController, IonSelect, Platform, ToastController} from '@ionic/angular';
import {environment} from '../../../environments/environment';
import {TranslateService} from '@ngx-translate/core';
import {Unsubscriber} from '../../utils/unsubscriber';
import {IonViewWillEnter} from '../../utils/ion-view-will-enter';
import {AndroidPermissionResponse, AndroidPermissions} from '@ionic-native/android-permissions/ngx';
import {
  AgentService,
  CallService,
  CallSessionDto as Session,
  GetTwilioTokenDto as TwilioTokenParams,
  UserMetadataDto as Profile,
  UserService
} from '../../../../swagger-client';
import {AuthService} from '../../providers/auth.service';
import {PictureService} from '../../providers/picture.service';
import * as twilio from 'twilio-video';
import {LoadingService} from '../../providers/loading.service';
import {Components} from '@ionic/core';
// https://github.com/twilio/twilio-video.js/issues/275#issuecomment-383707433
import 'zone.js/dist/zone.js';
import 'zone.js/dist/webapis-rtc-peer-connection';
import {WsService} from '../../providers/ws.service';

declare var AudioToggle;

@Component({
  selector: 'call',
  templateUrl: './call.component.html',
  styleUrls: ['./call.component.scss'],
})
export class CallComponent extends Unsubscriber implements IonViewWillEnter {
  @Input() modal: Components.IonModal;

  @Input() userId: string;
  @Input() sessionId: string;
  @Input() video: boolean;
  @Input() initiator: boolean;
  @Input() profile: Profile;
  @Input() session: Session;

  @ViewChild('toneAudio', {static: false}) toneAudio: ElementRef;
  @ViewChild('remoteAudio', {static: false}) remoteAudio: ElementRef;
  @ViewChild('remoteVideo', {static: false}) remoteVideo: ElementRef;
  @ViewChild('localVideo', {static: false}) localVideo: ElementRef;
  @ViewChild('localVideoWrapper', {static: false}) localVideoWrapper: ElementRef;
  @ViewChild('localAudioInputSelector', {static: false}) localAudioInputSelector: IonSelect;
  @ViewChild('localAudioOutputSelector', {static: false}) localAudioOutputSelector: IonSelect;
  @ViewChild('localVideoSelector', {static: false}) localVideoSelector: IonSelect;

  connecting: boolean;
  remoteVideoTracks: Map<twilio.Track.SID, twilio.RemoteVideoTrack>;
  remoteAudioTracks: Map<twilio.Track.SID, twilio.RemoteAudioTrack>;
  localAudioTracks: MediaStreamTrack[];
  localVideoTracks: MediaStreamTrack[];
  localAudioInputDeviceId: string;
  localAudioOutputDeviceId: string;
  localVideoDeviceId: string;
  facingMode: string;
  audioToggle: string;
  localAudioInputDevices: MediaDeviceInfo[];
  localAudioOutputDevices: MediaDeviceInfo[];
  localVideoDevices: MediaDeviceInfo[];
  toneAudioVolume: number;
  durationText: string;
  durationInterval: any;
  connectionTimeout: any;
  setAgentAvailableAtTheEnd: boolean;

  room: twilio.Room;

  constructor(
    public toastCtrl: ToastController,
    public translate: TranslateService,
    public platform: Platform,
    public wrapper: ElementRef,
    public androidPermissions: AndroidPermissions,
    public user: UserService,
    public auth: AuthService,
    public agent: AgentService,
    public picture: PictureService,
    public loadingService: LoadingService,
    public call: CallService,
    public ws: WsService,
    public alertController: AlertController,
  ) {
    super(loadingService);

    this.init();
  }

  init() {
    this.cancelAllSubscriptionsAndPromisesWithoutFinishingCall();

    if (this.session && (!this.sessionId || !this.profile)) {
      this.processSession(this.session);
    }

    if (this.profile && this.profile.id) {
      this.userId = this.profile.id;
    }

    this.connecting = true;
    this.remoteVideoTracks = new Map();
    this.remoteAudioTracks = new Map();
    this.localAudioTracks = null;
    this.localVideoTracks = null;
    this.localAudioInputDeviceId = null;
    this.localAudioOutputDeviceId = null;
    this.localVideoDeviceId = null;
    this.facingMode = 'user';
    this.audioToggle = this.platform.is('cordova') && AudioToggle ? (this.video ? 'speaker' : 'earpiece') : null;
    this.localAudioInputDevices = null;
    this.localAudioOutputDevices = null;
    this.localVideoDevices = null;
    this.toneAudioVolume = 0;
    this.durationText = '';
    this.setAgentAvailableAtTheEnd = false;

    window.addEventListener('resize', this.fit);

    window.addEventListener('beforeunload', this.leaver);
  }

  ionViewWillEnter(): void {
    this.init();

    this.subscribe(this.platform.backButton, async () => {
      const alert = await this.alertController.create({
        message: await this.translate.get('call.exit.text').toPromise(),
        buttons: [{
          text: await this.translate.get('call.exit.no').toPromise(),
          role: 'cancel',
        }, {
          text: await this.translate.get('call.exit.yes').toPromise(),
          handler: () => {
            this.endCall();
          }
        }]
      });
      alert.present();
    });

    this.subscribe(this.ws.callRejected, async session => {
      if (
        session && session.id
        && this.sessionId && session.id === this.sessionId
        && this.connecting
      ) {
        this.endCall();

        const toast = await this.toastCtrl.create({
          ...environment.toast,
          message: await this.translate.get('call.rejected').toPromise(),
          closeButtonText: await this.translate.get('toast.close').toPromise(),
          color: 'warning'
        });
        toast.present();
      }
    });

    this.subscribe(this.ws.callFinished, async session => {
      if (
        session && session.id
        && this.sessionId && session.id === this.sessionId
        && this.connecting // no need to listen for callFinished if already connected; we listen for participants
      ) {
        this.endCall();

        const toast = await this.toastCtrl.create({
          ...environment.toast,
          message: await this.translate.get('call.finished').toPromise(),
          closeButtonText: await this.translate.get('toast.close').toPromise(),
          color: 'warning'
        });
        toast.present();
      }
    });

    // We no longer control availability during the calls
    /*
    this.then(this.auth.refreshProfile(), profile => {
      if (
        profile
        && profile.id
        && profile.role === ProfileExtra.RoleEnum.Agent
        && profile.agentProfile
        && profile.agentProfile.status === AgentProfile.StatusEnum.Available
      ) {
        this.auth.updateAvailableAgentLocation(null, false);
        this.setAgentAvailableAtTheEnd = true;
      }
    });
    */

    if (this.userId) {
      this.subscribe(this.user.getUserProfile(this.userId), res => this.profile = res.data);
    }

    if (this.sessionId) {
      this.subscribe(this.call.getCall(this.sessionId), res => {
        if (!res.isSuccess || !res.data) {
          // this.connectionError().then();
          return;
        }

        this.processSession(res.data);
      });
    }

    this.startCall().then();
  }

  fit() {
    if (!this.picture) {
      return;
    }

    this.picture.fit(this.remoteVideo, this.wrapper);
    this.picture.fit(this.localVideo, this.localVideoWrapper);
  }

  leaver() {
    if (!this.room) {
      return;
    }

    this.room.disconnect();
  }

  processSession(session: Session) {
    this.session = session;
    if (!this.session) {
      this.sessionId = null;
      return;
    }

    this.sessionId = this.session.id;

    this.profile = this.auth.other(this.session.recipient, this.session.sender);
  }

  async startCall() {
    await this.processLocalDevices();

    const params: TwilioTokenParams = {};
    if (this.sessionId) {
      params.sessionId = this.sessionId;
    } else if (this.userId) {
      params.toUserId = this.userId;
    }

    this.subscribe(this.call.getTwilioToken(params), async res => {
      if (!res.isSuccess || !res.data) {
        console.error(res);

        this.connectionError().then();

        return;
      }

      const token = res.data.token;
      this.processSession(res.data.callSession);

      try {
        // TODO: App - Perhaps check all Twilio connect options.
        const options: twilio.ConnectOptions = {
          bandwidthProfile: {
            video: {
              mode: 'collaboration',
              renderDimensions: {
                high: {height: 1080, width: 1920},
                standard: {height: 90, width: 160},
                low: {height: 90, width: 160},
              },
            },
          },
          dominantSpeaker: true,
          maxAudioBitrate: 12000,
          networkQuality: {local: 1, remote: 1},
          preferredVideoCodecs: ['H264', 'VP8'],
          logLevel: 'warn', // 'debug',
        };

        this.room = await twilio.connect(token, {...options, tracks: []});
        console.log(this.room);

        if (this.sessionId) {
          if (this.video && this.localVideoTracks.length > 0) {
            this.subscribe(
              this.call.updateCallVideoState(this.sessionId, {withVideo: true}),
              () => {
                this.subscribe(this.call.joinCall(this.sessionId));
              }
            );
          } else {
            this.subscribe(this.call.joinCall(this.sessionId));
          }
        } else {
          console.error('No session id');
        }

        this.room.on('disconnected', async () => {
          console.log('disconnected');

          this.room = null;

          this.endCall();

          const toast = await this.toastCtrl.create({
            ...environment.toast,
            message: await this.translate.get('call.disconnected').toPromise(),
            closeButtonText: await this.translate.get('toast.close').toPromise(),
            color: 'warning'
          });
          toast.present();
        });

        this.room.on('trackSubscriptionFailed', error => {
          console.error(error);
        });

        // If the connection takes some time, let us wait before publishing the tracks
        if (this.localAudioTracks && this.localAudioTracks.length) {
          for (const track of this.localAudioTracks) {
            await this.room.localParticipant.publishTrack(new twilio.LocalAudioTrack(track));
          }
        }
        if (this.localVideoTracks && this.localVideoTracks.length) {
          for (const track of this.localVideoTracks) {
            await this.room.localParticipant.publishTrack(track, {logLevel: 'debug'});
          }
        }

        // Get ready to disconnect
        if (!this.room.participants.size) {
          // Perhaps wait for participants even if not initiator.
          /*
          if (!this.initiator) {
            console.error('Incoming empty call');

            this.connectionError().then();

            return;
          }
          */

          if (environment.callDropTimeout) {
            this.connectionTimeout = setTimeout(() => this.connectionError(), environment.callDropTimeout);
          }
        } else {
          this.connected();

          this.room.participants.forEach(participant => {
            this.processRemoteParticipant(participant);
          });
        }

        this.room.on('participantConnected', participant => {
          console.log('participantConnected', participant);

          if (this.connectionTimeout) {
            clearTimeout(this.connectionTimeout);
            this.connectionTimeout = null;
          }

          this.connected();

          this.processRemoteParticipant(participant);
        });

        this.room.on('participantDisconnected', async participant => {
          console.log('participantDisconnected', participant);

          if (!this.room.participants.size) {
            this.endCall();

            const toast = await this.toastCtrl.create({
              ...environment.toast,
              message: await this.translate.get('call.done').toPromise(),
              closeButtonText: await this.translate.get('toast.close').toPromise(),
              color: 'warning'
            });
            toast.present();
          }

          if (participant.remoteAudioTracks) {
            participant.remoteAudioTracks.forEach(publication => {
              if (publication.track) {
                publication.track.mediaStreamTrack.stop();
                if (this.remoteAudioTracks) {
                  this.remoteAudioTracks.delete(publication.track.sid);
                }
              }
              this.startRemoteAudio().then();
            });
          }

          if (participant.remoteAudioTracks) {
            participant.remoteVideoTracks.forEach(publication => {
              if (publication.track) {
                publication.track.mediaStreamTrack.stop();
                if (this.remoteVideoTracks) {
                  this.remoteVideoTracks.delete(publication.track.sid);
                }
              }
              this.startRemoteVideo().then();
            });
          }
        });
      } catch (err) {
        console.error(err);
        this.connectionError().then();
        return;
      }
    }, err => {
      console.error(err);
      this.connectionError().then();
    });
  }

  connected() {
    this.connecting = false;

    const connectedAt = (new Date()).getTime();

    this.durationInterval = setInterval(() => {
      let durationSeconds = Math.floor(((new Date()).getTime() - connectedAt) / 1000);
      const durationMinutes = Math.floor(durationSeconds / 60);
      const durationHours = Math.floor(durationSeconds / 3600);
      durationSeconds = durationSeconds % 60;
      this.durationText =
        (durationHours < 10 ? '0' : '') + durationHours + ':' +
        (durationMinutes < 10 ? '0' : '') + durationMinutes + ':' +
        (durationSeconds < 10 ? '0' : '') + durationSeconds;
    }, 500);
  }

  endCall() {
    console.log('endCall');

    if (this.modal && this.modal.dismiss) {
      this.modal.dismiss().then();
    }
  }

  processRemoteAudioPublication(publication: twilio.RemoteAudioTrackPublication) {
    if (!publication) {
      return;
    }

    if (publication.isSubscribed && publication.track) {
      this.remoteAudioTracks.set(publication.track.sid, publication.track);
    }

    publication.on('subscribed', (track: twilio.RemoteAudioTrack) => {
      console.log('subscribed', track);

      if (!track) {
        track = publication.track;
      }
      if (!track) {
        return;
      }
      this.remoteAudioTracks.set(track.sid, track);
      this.startRemoteAudio().then();
    });

    publication.on('unsubscribed', (track: twilio.RemoteAudioTrack) => {
      console.log('unsubscribed', track);

      if (!track) {
        track = publication.track;
      }
      if (track) {
        track.mediaStreamTrack.stop();
        if (this.remoteAudioTracks) {
          this.remoteAudioTracks.delete(track.sid);
        }
      }
      this.startRemoteAudio().then();
    });

    publication.on('subscriptionFailed', error => {
      console.error(error);
    });
  }

  processRemoteVideoPublication(publication: twilio.RemoteVideoTrackPublication) {
    if (!publication) {
      return;
    }

    if (publication.isSubscribed && publication.track) {
      this.remoteVideoTracks.set(publication.track.sid, publication.track);
    }

    publication.on('subscribed', (track: twilio.RemoteVideoTrack) => {
      console.log('subscribed', track);

      if (!track) {
        track = publication.track;
      }
      if (!track) {
        return;
      }
      this.remoteVideoTracks.set(track.sid, track);
      this.startRemoteVideo().then();
    });

    publication.on('unsubscribed', (track: twilio.RemoteVideoTrack) => {
      console.log('unsubscribed', track);

      if (!track) {
        track = publication.track;
      }
      if (!track) {
        return;
      }
      track.mediaStreamTrack.stop();
      if (this.remoteVideoTracks) {
        this.remoteVideoTracks.delete(track.sid);
      }
      this.startRemoteVideo().then();
    });

    publication.on('subscriptionFailed', error => {
      console.error(error);
    });
  }

  processRemoteParticipant(participant: twilio.RemoteParticipant) {
    participant.audioTracks.forEach(publication => this.processRemoteAudioPublication(publication));
    this.startRemoteAudio().then();

    participant.videoTracks.forEach(publication => this.processRemoteVideoPublication(publication));
    this.startRemoteVideo().then();

    participant.on('trackPublished', publication => {
      console.log('trackPublished', publication);

      if (publication && publication.kind === 'audio') {
        this.processRemoteAudioPublication(<twilio.RemoteAudioTrackPublication>publication);
      }
      if (publication && publication.kind === 'video') {
        this.processRemoteVideoPublication(<twilio.RemoteVideoTrackPublication>publication);
      }
    });

    participant.on('trackUnpublished', publication => {
      console.log('trackUnpublished', publication);

      if (!publication) {
        return;
      }

      if (publication.kind === 'audio') {
        publication = <twilio.RemoteAudioTrackPublication>publication;
        let sid = publication.trackSid;
        if (publication && publication.track) {
          publication.track.mediaStreamTrack.stop();
          sid = publication.track.sid;
        }
        if (sid && this.remoteAudioTracks) {
          this.remoteAudioTracks.delete(sid);
        }
        this.startRemoteAudio().then();
      }
      if (publication.kind === 'video') {
        publication = <twilio.RemoteVideoTrackPublication>publication;
        let sid = publication.trackSid;
        if (publication && publication.track) {
          publication.track.mediaStreamTrack.stop();
          sid = publication.track.sid;
        }
        if (sid && this.remoteVideoTracks) {
          this.remoteVideoTracks.delete(sid);
        }
        this.processRemoteVideoPublication(publication);
      }
    });

    participant.on('trackSubscriptionFailed', error => {
      console.error(error);
    });
  }

  async connectionError() {
    this.endCall();

    const toast = await this.toastCtrl.create({
      ...environment.toast,
      color: 'danger',
      message: await this.translate.get('call.connection-error').toPromise(),
      closeButtonText: await this.translate.get('toast.close').toPromise()
    });
    toast.present();
  }

  async audioStreamingError() {
    this.endCall();

    const toast = await this.toastCtrl.create({
      ...environment.toast,
      color: 'danger',
      message: await this.translate.get('call.cannot-record-audio').toPromise(),
      closeButtonText: await this.translate.get('toast.close').toPromise()
    });
    toast.present();
  }

  async videoStreamingWarning() {
    const toast = await this.toastCtrl.create({
      ...environment.toast,
      color: 'warn',
      message: await this.translate.get('call.cannot-stream-video').toPromise(),
      closeButtonText: await this.translate.get('toast.close').toPromise()
    });
    toast.present();
  }

  async processLocalDevices() {
    // Reset to trigger a new enumeration
    this.stopLocalAudio();
    this.stopLocalVideo();

    this.localAudioTracks = null;
    this.localVideoTracks = null;
    this.localVideoDevices = null;
    this.localAudioInputDevices = null;
    this.localAudioOutputDevices = null;

    if (!await this.startLocalAudio()) {
      this.audioStreamingError().then();
      return;
    }

    if (this.video) {
      await this.startLocalVideo();
    }

    if (this.audioToggle) {
      AudioToggle.setAudioMode(this.audioToggle);
    } else {
      this.localAudioOutputDevices = await this.collectLocalDevices('audiooutput');
    }

    setTimeout(() => this.toneAudioVolume = 0.1, 1000); // delay the start of the tone

    if (navigator.mediaDevices && !navigator.mediaDevices.ondevicechange) {
      navigator.mediaDevices.ondevicechange = async () => {
        await this.processLocalDevices();
      };
    }
  }

  async checkPermission(permission) {
    let result: AndroidPermissionResponse;
    try {
      result = await this.androidPermissions.checkPermission(permission);
    } catch (err) {
      console.error(err);
    }

    console.log('Checked permission ' + permission, result && result.hasPermission);

    if (!result || !result.hasPermission) {
      try {
        result = await this.androidPermissions.requestPermission(permission);
      } catch (err) {
        console.error(err);
      }
    }

    console.log('Requested permission ' + permission, result && result.hasPermission);

    return result && result.hasPermission;
  }

  async collectLocalDevices(kind: string) {
    const results: MediaDeviceInfo[] = [];

    if (kind === 'audiooutput' && this.audioToggle) {
      console.log('Using AudioToggle');
      return results;
    }

    console.log(navigator.mediaDevices);

    if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
      console.log('Enumerating devices');

      try {
        const mediaDevices = await navigator.mediaDevices.enumerateDevices();
        console.log(mediaDevices);

        if (mediaDevices && mediaDevices.length) {
          for (const mediaDevice of mediaDevices) {
            if (
              (!kind || kind === mediaDevice.kind)
              && (mediaDevice.kind !== 'audiooutput' || await this.setLocalAudioOutput(mediaDevice.deviceId))
            ) {
              results.push(mediaDevice);
            }
          }
        } else {
          console.log('No mediaDevices found');
        }
      } catch (err) {
        console.error(err);
      }
    }

    console.log(kind, results);

    if (kind === 'audiooutput') {
      if (results && results.length > 1) {
        let found = -1;
        results.forEach((mediaDevice, idx) => {
          if ((mediaDevice.deviceId + mediaDevice.label).toLowerCase().indexOf('communication') !== -1) {
            found = idx;
          }
          if (found === -1 && (mediaDevice.deviceId + mediaDevice.label).toLowerCase().indexOf('default') !== -1) {
            found = idx;
          }
        });
        found = found === -1 ? 0 : found;
        await this.setLocalAudioOutput(results[found].deviceId);
      }
    }

    return results;
  }

  stopTone() {
    if (this.toneAudio && this.toneAudio.nativeElement && this.toneAudio.nativeElement.srcObject) {
      const tracks = this.toneAudio.nativeElement.srcObject.getTracks();
      if (tracks) {
        tracks.forEach(track => track.stop());
      }
    }
  }

  stopLocalAudio() {
    if (this.localAudioTracks && this.localAudioTracks.length) {
      this.localAudioTracks.forEach(track => {
        if (this.room) {
          this.room.localParticipant.unpublishTrack(track);
        }
        track.stop();
      });
    }

    this.localAudioTracks = [];
  }

  async startLocalAudio() {
    if (this.localAudioInputDevices === null) {
      await this.checkPermission(this.androidPermissions.PERMISSION.RECORD_AUDIO);

      this.localAudioInputDevices = await this.collectLocalDevices('audioinput');
    }

    if (!navigator.mediaDevices) {
      return false;
    }

    this.stopLocalAudio();

    try {
      const constraints: MediaTrackConstraints = {};
      if (this.localAudioInputDeviceId) {
        constraints.deviceId = this.localAudioInputDeviceId;
      }
      console.log('Audio constraints', constraints);

      const stream = await navigator.mediaDevices.getUserMedia({
        video: false,
        audio: constraints,
      });

      const tracks = stream.getAudioTracks();
      console.log(tracks);
      if (tracks && tracks.length) {
        const track = tracks[0];

        this.localAudioInputDeviceId = track.getCapabilities().deviceId;
        this.localAudioTracks = tracks;

        if (this.room && this.localAudioTracks && this.localAudioTracks.length) {
          this.localAudioTracks.forEach(async trackx => {
            await this.room.localParticipant.publishTrack(new twilio.LocalAudioTrack(trackx));
          });
        }

        return true;
      }
    } catch (err) {
      console.error(err);
    }

    return false;
  }

  stopLocalVideo() {
    if (this.localVideo && this.localVideo.nativeElement && this.localVideo.nativeElement.srcObject) {
      const tracks = this.localVideo.nativeElement.srcObject.getTracks();
      if (tracks) {
        tracks.forEach(track => {
          if (this.room) {
            this.room.localParticipant.unpublishTrack(track);
          }

          track.stop();
        });
      }
    }

    if (this.localVideoTracks && this.localVideoTracks.length) {
      this.localVideoTracks.forEach(track => track.stop());
    }

    this.localVideoTracks = [];
  }

  async startLocalVideo() {
    // TODO: App - Starting local video immediately after the remote one starts breaks the remote one. May need several attempts.
    this.video = true;

    if (this.localVideoDevices === null) {
      await this.checkPermission(this.androidPermissions.PERMISSION.CAMERA);

      this.localVideoDevices = await this.collectLocalDevices('videoinput');
    }

    if (!navigator.mediaDevices) {
      return false;
    }

    this.stopLocalVideo();

    try {
      const constraints: MediaTrackConstraints = {};
      if (this.localVideoDeviceId) {
        constraints.deviceId = this.localVideoDeviceId;
      } else {
        constraints.facingMode = this.facingMode;
      }
      console.log('Video constraints', constraints);

      const stream = await navigator.mediaDevices.getUserMedia({
        video: constraints,
        audio: false,
      });

      const tracks = stream.getVideoTracks();
      console.log(tracks);
      if (tracks && tracks.length) {
        this.picture.fit(this.localVideo, this.localVideoWrapper);

        const video = this.localVideo.nativeElement;
        video.srcObject = stream;
        video.setAttribute('playsinline', true); // Required for Safari
        this.then(video.play(), () => {
          this.picture.fit(this.localVideo, this.localVideoWrapper);
        }, err1 => {
          console.error(err1);
        });

        const track = tracks[0];

        this.localVideoDeviceId = track.getCapabilities().deviceId;
        this.localVideoTracks = tracks;

        if (this.room && this.localVideoTracks && this.localVideoTracks.length) {
          this.localVideoTracks.forEach(async trackx => {
            await this.room.localParticipant.publishTrack(new twilio.LocalVideoTrack(trackx));
          });
        }

        if (this.sessionId && this.localVideoTracks.length > 0) {
          this.subscribe(this.call.updateCallVideoState(this.sessionId, {withVideo: true}));
        }

        return true;
      }
    } catch (err) {
      console.error(err);
      this.videoStreamingWarning().then();
    }

    return false;
  }

  stopRemoteAudio() {
    if (this.remoteAudio && this.remoteAudio.nativeElement && this.remoteAudio.nativeElement.srcObject) {
      const tracks = this.remoteAudio.nativeElement.srcObject.getTracks();
      if (tracks) {
        tracks.forEach(track => track.stop());
      }
    }
  }

  async startRemoteAudio() {
    this.stopRemoteAudio();

    console.log(this.remoteAudioTracks);
    if (!this.remoteAudioTracks || !this.remoteAudioTracks.size) {
      return;
    }

    try {
      let track: twilio.RemoteAudioTrack;
      this.remoteAudioTracks.forEach(potential => {
        if (potential && potential.isEnabled && potential.mediaStreamTrack) {
          track = potential;
        }
      });
      if (!track) {
        throw new Error('No valid remote Audio track');
      }

      const el = track ? track.attach(this.remoteAudio.nativeElement) : null;
      console.log(el); // just to avoid TS warning
      this.remoteAudio.nativeElement.setAttribute('playsinline', true); // Required for Safari
      if (!this.audioToggle) {
        // TODO: App - Perhaps check why is it needed to switch devices in order for this to work?
        const deviceId = this.localAudioOutputDeviceId;
        const deviceObj = this.localAudioOutputDevices.find(device => device.deviceId === deviceId);
        const groupId = deviceObj ? deviceObj.groupId : '';
        let potential: string;
        this.localAudioOutputDevices.forEach(device => {
          if (device.deviceId !== deviceId) {
            if (!potential || device.groupId === groupId) {
              potential = device.deviceId;
            }
          }
        });
        if (potential) {
          await this.setLocalAudioOutput(potential);
        }
        await this.setLocalAudioOutput(deviceId);
      }
      await this.remoteAudio.nativeElement.play();

      return true;
    } catch (err) {
      console.error(err);
    }

    return false;
  }

  stopRemoteVideo() {
    if (this.remoteVideo && this.remoteVideo.nativeElement && this.remoteVideo.nativeElement.srcObject) {
      const tracks = this.remoteVideo.nativeElement.srcObject.getTracks();
      if (tracks) {
        tracks.forEach(track => track.stop());
      }
    }
  }

  async startRemoteVideo() {
    this.stopRemoteVideo();

    console.log(this.remoteVideoTracks);
    if (!this.remoteVideoTracks || !this.remoteVideoTracks.size) {
      return;
    }

    try {
      let track: twilio.RemoteVideoTrack;
      this.remoteVideoTracks.forEach(potential => {
        if (potential && potential.isEnabled && potential.mediaStreamTrack) {
          track = potential;
        }
      });
      if (!track) {
        throw new Error('No valid remote video track');
      }

      this.picture.fit(this.remoteVideo, this.wrapper);
      const el = track ? track.attach(this.remoteVideo.nativeElement) : null;
      console.log(el);
      this.remoteVideo.nativeElement.setAttribute('playsinline', true); // Required for Safari
      await this.remoteVideo.nativeElement.play();
      this.picture.fit(this.remoteVideo, this.wrapper);

      return true;
    } catch (err) {
      console.error(err);
    }

    return false;
  }

  changeLocalAudioInput() {
    if (!this.localAudioInputDevices || this.localAudioInputDevices.length <= 1) {
      return;
    }

    this.localAudioInputSelector.open().then();
  }

  async setLocalAudioInput(deviceId?: string) {
    if (!deviceId) {
      deviceId = this.localAudioInputDeviceId;
    }

    if (!deviceId) {
      return false;
    }

    console.log('setLocalAudioInput', deviceId);

    this.startLocalAudio().then();

    return false;
  }

  changeLocalAudioOutput() {
    if (this.audioToggle) {
      this.audioToggle = this.audioToggle === 'speaker' ? 'earpiece' : 'speaker';
      AudioToggle.setAudioMode(this.audioToggle);
      return;
    }

    this.localAudioOutputSelector.open().then();
  }

  async setLocalAudioOutput(deviceId?: string) {
    if (!deviceId) {
      deviceId = this.localAudioOutputDeviceId;
    } else {
      this.localAudioOutputDeviceId = deviceId;
    }

    if (!deviceId) {
      return false;
    }

    let res = false;

    console.log('setLocalAudioOutput', deviceId);

    if (this.toneAudio && this.toneAudio.nativeElement && this.toneAudio.nativeElement.setSinkId) {
      try {
        await this.toneAudio.nativeElement.setSinkId(deviceId);

        await this.toneAudio.nativeElement.play();

        res = true;

        console.log('setLocalAudioOutput toneAudio');
      } catch (err) {
        console.error(err);
      }
    }

    if (this.remoteAudio && this.remoteAudio.nativeElement && this.remoteAudio.nativeElement.setSinkId) {
      try {
        await this.remoteAudio.nativeElement.setSinkId(deviceId);

        res = true;

        console.log('setLocalAudioOutput remoteAudio');
      } catch (err) {
        console.error(err);

        res = false;
      }
    }

    return res;
  }

  changeLocalVideo() {
    if (!this.localVideoDevices || this.localVideoDevices.length <= 1) {
      return;
    }

    if (this.localVideoDevices.length === 2) {
      if (!this.localVideoDeviceId) {
        this.facingMode = this.facingMode === 'user' ? 'environment' : 'user';
      } else {
        const otherDevice = this.localVideoDevices.find(device => device.deviceId !== this.localVideoDeviceId);
        if (otherDevice) {
          this.localVideoDeviceId = otherDevice.deviceId;

          this.startLocalVideo().then();
        }
      }

      return;
    }

    this.localVideoSelector.open().then();
  }

  async setLocalVideo(deviceId?: string) {
    if (!deviceId) {
      deviceId = this.localVideoDeviceId;
    }

    if (!deviceId) {
      return false;
    }

    console.log('setLocalVideo', deviceId);

    this.startLocalVideo().then();

    return false;
  }

  getDeviceLabel(device: MediaDeviceInfo) {
    return device.label ? device.label : device.deviceId;
  }

  cancelAllSubscriptionsAndPromisesWithoutFinishingCall() {
    console.log('cancelAllSubscriptionsAndPromises');

    this.connecting = false; // Prevent the tone from getting stuck after hanging up.


    if (this.room) {
      this.room.removeAllListeners('disconnected'); // prevent re-running endCall
      this.room.disconnect();
      this.room = null; // prevent re-running disconnect
    }

    this.stopTone();
    this.stopLocalAudio();
    this.stopLocalVideo();
    this.stopRemoteAudio();
    this.stopRemoteVideo();

    window.removeEventListener('resize', this.fit);

    window.removeEventListener('beforeunload', this.leaver);

    // We no longer control availability during the calls
    /*
    if (this.setAgentAvailableAtTheEnd) {
      this.auth.updateAvailableAgentLocation(null, true);
    }
    */

    if (navigator.mediaDevices && navigator.mediaDevices.ondevicechange) {
      navigator.mediaDevices.ondevicechange = null;
    }

    if (this.connectionTimeout) {
      clearTimeout(this.connectionTimeout);
    }

    if (this.durationInterval) {
      clearInterval(this.durationInterval);
    }
  }

  cancelAllSubscriptionsAndPromises() {
    super.cancelAllSubscriptionsAndPromises();

    this.cancelAllSubscriptionsAndPromisesWithoutFinishingCall();

    if (this.sessionId) {
      this.call.finishCall(this.sessionId).toPromise().then(); // this should run no matter what
      this.sessionId = null; // Prevent rerunning the finishCall
    }
  }
}
