/**
 * AgentOnDuty API Documentation
 * The official AgentOnDuty API, documented with the OpenAPI 3.0 specification
 *
 * OpenAPI spec version: THE API VERSION IS NOW PROGRAMMATICALLY SET AND RETRIEVED! Use the public endpoint for reading the current version.
 * Contact: demo@synergogroup.net
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { BaseApiResponseDto } from '../model/baseApiResponseDto';
import { InlineResponse20035 } from '../model/inlineResponse20035';
import { RegisterSubscriptionDto } from '../model/registerSubscriptionDto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class PushService {

    protected basePath = 'https://agentonduty-dev.azurewebsites.net/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * Deletes an existing subscription pairing
     * @param pushId The subscription id to delete pairing for
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteSubscription(pushId: string, observe?: 'body', reportProgress?: boolean): Observable<BaseApiResponseDto>;
    public deleteSubscription(pushId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BaseApiResponseDto>>;
    public deleteSubscription(pushId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BaseApiResponseDto>>;
    public deleteSubscription(pushId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (pushId === null || pushId === undefined) {
            throw new Error('Required parameter pushId was null or undefined when calling deleteSubscription.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<BaseApiResponseDto>(`${this.basePath}/push/subscription/${encodeURIComponent(String(pushId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Registers a push subscription id for the logged user
     * @param body the new subscription payload
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public registerSubscription(body: RegisterSubscriptionDto, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20035>;
    public registerSubscription(body: RegisterSubscriptionDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20035>>;
    public registerSubscription(body: RegisterSubscriptionDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20035>>;
    public registerSubscription(body: RegisterSubscriptionDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling registerSubscription.');
        }

        let headers = this.defaultHeaders;

        // authentication (JWTbearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<InlineResponse20035>(`${this.basePath}/push/subscription`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
