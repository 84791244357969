import {Injectable} from '@angular/core';
import {LoadingController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  loaders: HTMLIonLoadingElement[] = [];
  objects: any[] = [];

  constructor(
    public loadingController: LoadingController,
    public translate: TranslateService,
  ) {
    // Cannot extend unsubscriber due to circular dependency
    // super();

    setInterval(() => this.hide(), 1000); // just in case something fails
  }

  async show(object: any) {
    if (object) {
      if (this.objects.indexOf(object) === -1) {
        this.objects.push(object);
      }
    }

    const length = this.objects.length;

    if (this.loaders.length) {
      return;
    }

    let message = await this.translate.get('loading.message').toPromise();
    if (message === 'loading.message') {
      message = ''; // for those slow start-ups when the translations are not yet loaded
    }
    const loader = await this.loadingController.create({
      message,
    });

    if (!this.objects.length) {
      return;
    }

    this.loaders.push(loader);

    console.log('Loader present', this.loaders.length, length);
    await loader.present();
  }

  async hide(object?: any) {
    if (object) {
      const idx = this.objects.indexOf(object);
      if (idx !== -1) {
        this.objects.splice(idx, 1);
      }
    }

    if (!this.loaders.length) {
      return;
    }
    console.log('Loader dismiss', this.loaders.length, this.objects.length);

    if (!this.objects.length) {
      const loaders = this.loaders;
      this.loaders = [];
      for (const loader of loaders) {
        if (loader) {
          await loader.dismiss();
        }
      }
    }
  }
}
