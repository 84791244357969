/**
 * AgentOnDuty API Documentation
 * The official AgentOnDuty API, documented with the OpenAPI 3.0 specification
 *
 * OpenAPI spec version: THE API VERSION IS NOW PROGRAMMATICALLY SET AND RETRIEVED! Use the public endpoint for reading the current version.
 * Contact: demo@synergogroup.net
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface Body { 
    username: string;
    password: string;
    /**
     * Access scope:  * `admin` - Performs authentication for the Admin platform  * `frontend` - Performs authentication for the Frontend (Web & Mobile) platforms 
     */
    accessScope: Body.AccessScopeEnum;
}
export namespace Body {
    export type AccessScopeEnum = 'admin' | 'frontend';
    export const AccessScopeEnum = {
        Admin: 'admin' as AccessScopeEnum,
        Frontend: 'frontend' as AccessScopeEnum
    };
}