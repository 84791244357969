import {environment as environment_base} from './environment.base';

export const environment = {
  ...environment_base, ...{
    // Place your overwrites here. WARNING: This is not a deep merge!
    serviceWorker: true,
    api: {
      basePath: 'https://agentonduty-staging.azurewebsites.net/api',
    },
    wsUrl: 'wss://agentonduty-staging.azurewebsites.net/api/', // trailing slash is required
    resetPasswordUrl: 'https://staging.mobile.agentonduty.realestate/reset/{code}',
    confirmAccountUrl: 'https://staging.mobile.agentonduty.realestate/confirm/{code}',
    weChatRedirectUrl: 'https://staging.mobile.agentonduty.realestate/signup',
    oneSignalAppId: '387e6f0f-ae1a-47b5-a67a-20b3f9c96222',
    oneSignalSafariWebId: 'web.onesignal.auto.47d572d6-ef9d-4d9d-8962-815db43f4beb',
    showSplashMenuOption: false,
  }
};
