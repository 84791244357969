/**
 * AgentOnDuty API Documentation
 * The official AgentOnDuty API, documented with the OpenAPI 3.0 specification
 *
 * OpenAPI spec version: THE API VERSION IS NOW PROGRAMMATICALLY SET AND RETRIEVED! Use the public endpoint for reading the current version.
 * Contact: demo@synergogroup.net
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CallSessionDto } from './callSessionDto';
import { ConversationDto } from './conversationDto';
import { GenericClientToAgentConnectionDto } from './genericClientToAgentConnectionDto';
import { ListingDto } from './listingDto';
import { MessageDto } from './messageDto';
import { UserMetadataDto } from './userMetadataDto';

export interface RecentInteractionDto { 
    /**
     * the original identifier of this specific interaction object
     */
    id?: string;
    type?: RecentInteractionDto.TypeEnum;
    createdOn?: string;
    createdBy?: UserMetadataDto;
    otherParty?: UserMetadataDto;
    chat?: ConversationDto;
    message?: MessageDto;
    call?: CallSessionDto;
    connection?: GenericClientToAgentConnectionDto;
    listing?: ListingDto;
    phoneCall?: GenericClientToAgentConnectionDto;
}
export namespace RecentInteractionDto {
    export type TypeEnum = 'chat' | 'message' | 'call' | 'connection' | 'phone_call' | 'listing';
    export const TypeEnum = {
        Chat: 'chat' as TypeEnum,
        Message: 'message' as TypeEnum,
        Call: 'call' as TypeEnum,
        Connection: 'connection' as TypeEnum,
        PhoneCall: 'phone_call' as TypeEnum,
        Listing: 'listing' as TypeEnum
    };
}