/**
 * AgentOnDuty API Documentation
 * The official AgentOnDuty API, documented with the OpenAPI 3.0 specification
 *
 * OpenAPI spec version: THE API VERSION IS NOW PROGRAMMATICALLY SET AND RETRIEVED! Use the public endpoint for reading the current version.
 * Contact: demo@synergogroup.net
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface UpsertListingDto { 
    purpose: UpsertListingDto.PurposeEnum;
    shortDescription: string;
    price: number;
    currency: string;
    address1?: string;
    address2?: string;
    latitude: number;
    longitude: number;
    longDescription: string;
    videoLink?: string;
    commission?: string;
}
export namespace UpsertListingDto {
    export type PurposeEnum = 'for_sale' | 'for_rent';
    export const PurposeEnum = {
        Sale: 'for_sale' as PurposeEnum,
        Rent: 'for_rent' as PurposeEnum
    };
}