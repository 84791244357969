/**
 * AgentOnDuty API Documentation
 * The official AgentOnDuty API, documented with the OpenAPI 3.0 specification
 *
 * OpenAPI spec version: THE API VERSION IS NOW PROGRAMMATICALLY SET AND RETRIEVED! Use the public endpoint for reading the current version.
 * Contact: demo@synergogroup.net
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { UpdateUserDto } from './updateUserDto';

export interface UpdateClientFromAdminDto extends UpdateUserDto { 
    interests?: Array<UpdateClientFromAdminDto.InterestsEnum>;
    username: string;
}
export namespace UpdateClientFromAdminDto {
    export type InterestsEnum = 'buyer' | 'seller' | 'landlord' | 'tenant' | 'mortgage';
    export const InterestsEnum = {
        Buyer: 'buyer' as InterestsEnum,
        Seller: 'seller' as InterestsEnum,
        Landlord: 'landlord' as InterestsEnum,
        Tenant: 'tenant' as InterestsEnum,
        Mortgage: 'mortgage' as InterestsEnum
    };
}