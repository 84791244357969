import {Injectable} from '@angular/core';
import {Unsubscriber} from '../utils/unsubscriber';
import {LoadingService} from './loading.service';
import {AlertController, Platform} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {Facebook} from '@ionic-native/facebook/ngx';
import {Storage} from '@ionic/storage';

export const ATE_KEY = 'ATE';

@Injectable({
  providedIn: 'root'
})
export class PixelService extends Unsubscriber {
  constructor(
    public loadingService: LoadingService,
    public platform: Platform,
    public fb: Facebook,
    public storage: Storage,
    public alertController: AlertController,
    public translate: TranslateService,
  ) {
    super(loadingService);
  }

  getAdvertiserTracking() {
    return this.storage.get(ATE_KEY);
  }

  async setAdvertiserTracking(enabled: boolean) {
    if (this.platform.is('ios') && this.fb.setAdvertiserTrackingEnabled) {
      await this.fb.setAdvertiserTrackingEnabled(enabled);
    }

    await this.storage.set(ATE_KEY, enabled);

    if (enabled) {
      await this.logPageView();
    }
  }

  async checkAndEnableAdvertiserTracking() {
    if ((await this.getAdvertiserTracking()) === null) {
      await this.enableAdvertiserTracking();
    }
  }

  async enableAdvertiserTracking(enable = true) {
    // Show confirmation only when enabling on ios. Otherwise just set accordingly.
    if (!this.platform.is('cordova') || !this.platform.is('ios') || !enable) {
      await this.setAdvertiserTracking(enable);
      return;
    }

    // Leave this here to force the translations loading before the modal is rendered
    await this.translate.get('pixel.enable.message').toPromise();
    const alert = await this.alertController.create({
      message: await this.translate.get('pixel.enable.message').toPromise(),
      buttons: [{
        text: await this.translate.get('pixel.enable.no').toPromise(),
        handler: async () => {
          await this.setAdvertiserTracking(false);
        }
      }, {
        text: await this.translate.get('pixel.enable.yes').toPromise(),
        handler: async () => {
          await this.setAdvertiserTracking(true);
        }
      }]
    });
    alert.present();
  }

  async logPageView() {
    // Browser app uses the automatic PageView events. Native apps only trigger that once so using ViewContent.
    if (!this.platform.is('cordova') || !window['fbq'] || !(await this.getAdvertiserTracking())) {
      return;
    }

    const url = window.location.href;

    if (!url) {
      return;
    }

    console.log('Pixel ViewContent', url);

    window['fbq']('track', 'ViewContent', {
      content_url: url,
      content_type: 'page',
    });
  }
}
