import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';

if (environment.production) {
  enableProdMode();
  // TODO: App - Perhaps enable these overrides if the application is flooding the logs.
  // window.console.log = function () {};
  // window.console.debug = function () {};
  // window.console.warn = function () {};
  // window.console.error = function () {};
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
