/**
 * AgentOnDuty API Documentation
 * The official AgentOnDuty API, documented with the OpenAPI 3.0 specification
 *
 * OpenAPI spec version: THE API VERSION IS NOW PROGRAMMATICALLY SET AND RETRIEVED! Use the public endpoint for reading the current version.
 * Contact: demo@synergogroup.net
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface UpdateUserInterestDto { 
    interests: Array<UpdateUserInterestDto.InterestsEnum>;
}
export namespace UpdateUserInterestDto {
    export type InterestsEnum = 'buyer' | 'seller' | 'landlord' | 'tenant' | 'mortgage' | 'real_estate';
    export const InterestsEnum = {
        Buyer: 'buyer' as InterestsEnum,
        Seller: 'seller' as InterestsEnum,
        Landlord: 'landlord' as InterestsEnum,
        Tenant: 'tenant' as InterestsEnum,
        Mortgage: 'mortgage' as InterestsEnum,
        RealEstate: 'real_estate' as InterestsEnum
    };
}