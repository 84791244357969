import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {FormsModule} from '@angular/forms';
import {ContentHandlersDirective} from './content-handlers.directive';
import {ClickCursorDirective} from './click-cursor.directive';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule
  ],
  declarations: [
    ContentHandlersDirective,
    ClickCursorDirective,
  ],
  exports: [
    ContentHandlersDirective,
    ClickCursorDirective,
  ],
})
export class DirectivesModule {
}
