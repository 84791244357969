import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanLoad, Route} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from '../providers/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AnonymousGuard implements CanActivate, CanLoad {
  constructor(
    public auth: AuthService,
  ) {
  }

  handler(
    guard: string,
    path: string,
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.auth.getToken().then(token => {
      console.log('AnonymousGuard ' + guard + ' route:', path);
      console.log('AnonymousGuard ' + guard + ' token:', token);
      if (token) {
        this.auth.redirectWithReturnUrl('/app', false, true);
        return false;
      } else {
        return true;
      }
    });
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    // state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.handler('canActivate', '/' + route.url.join('/'));
  }

  canLoad(
    route: Route,
    // segments: UrlSegment[],
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.handler('canLoad', route.path);
  }
}
