import {Injectable} from '@angular/core';
import {Storage} from '@ionic/storage';
import {Platform, ToastController} from '@ionic/angular';
import {ActivatedRoute, Router} from '@angular/router';
import {BehaviorSubject, of, Subscription} from 'rxjs';
import {AccountService} from '../../../swagger-client';
import {Unsubscriber} from '../utils/unsubscriber';
import {TranslateService} from '@ngx-translate/core';
import {Geolocation, GeolocationOptions, Geoposition} from '@ionic-native/geolocation/ngx';
import {environment} from '../../environments/environment';
import getDistance from 'geolib/es/getDistance';
import {HttpClient} from '@angular/common/http';
import {switchMap} from 'rxjs/operators';
import {LocationAccuracy} from '@ionic-native/location-accuracy/ngx';
import {LoadingService} from './loading.service';

declare var google;

export const LAST_LOCATION_KEY = 'LAST_LOCATION';

export const GOOGLE_API_URL = 'https://maps.googleapis.com/maps/api/geocode/json?';

export interface AddressComponent {
  long_name?: string;
  short_name?: string;
}

@Injectable({
  providedIn: 'root'
})
export class GeolocationService extends Unsubscriber {
  location = new BehaviorSubject<Geoposition>(null);
  tracker: Subscription;
  googleMapsPromise: Promise<any>;

  constructor(
    public storage: Storage,
    public account: AccountService,
    public router: Router,
    public route: ActivatedRoute,
    public translate: TranslateService,
    public toastCtrl: ToastController,
    public geolocation: Geolocation,
    public http: HttpClient,
    public locationAccuracy: LocationAccuracy,
    public loadingService: LoadingService,
    public platform: Platform,
  ) {
    super(loadingService);
  }

  processLocation(location?: Geoposition) {
    if (!location || !location.coords) {
      return location;
    }

    const {latitude, longitude, ...coords} = location.coords;
    return {
      coords: {
        ...coords,
        latitude: latitude ? Math.round(latitude * 10000) / 10000 : latitude,
        longitude: longitude ? Math.round(longitude * 10000) / 10000 : longitude,
      },
      timestamp: location.timestamp,
    };
  }

  async getCurrentPosition(options?: GeolocationOptions) {
    let location: Geoposition = null;

    try {
      location = this.location.getValue();

      if (!location) {
        location = await this.storage.get(LAST_LOCATION_KEY);
      }

      if (this.platform.is('cordova')) {
        try {
          const canRequest = await this.locationAccuracy.canRequest();
          if (canRequest) {
            // the accuracy option will be ignored by iOS
            await this.locationAccuracy.request(this.locationAccuracy.REQUEST_PRIORITY_BALANCED_POWER_ACCURACY);
          }
        } catch (error) {
          console.error(error);
        }
      }

      if (!options) {
        options = {
          enableHighAccuracy: true,
        };
      }

      options.timeout = !location || !location.coords ?
        environment.geolocationWithoutPreexistingLocationTimeout :
        environment.geolocationWithPreexistingLocationTimeout;

      location = await this.geolocation.getCurrentPosition(options);

      // Ionic native geolocation wraps around the navigator.geolocation
      /*
      if (navigator.geolocation) {
        location = await new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(result => resolve(result), error => reject(error), options);
        });
      }
      */

      location = this.processLocation(location);

      this.setLocation(location).then();
    } catch (error) {
      console.error(error);

      if (error && error.code && error.code === 3) {
        console.log('Geolocation timed out. Using previous.');
      }

      if (!location) {
        const toast = await this.toastCtrl.create({
          ...environment.toast,
          color: 'danger',
          message: await this.translate.get('location.geolocation-failed').toPromise(),
          closeButtonText: await this.translate.get('toast.close').toPromise()
        });
        toast.present();

        throw error;
      }
    }

    this.setLocation(location).then();

    return location;
  }

  watchPosition(options?: GeolocationOptions) {
    this.trackLocation(true, options);
    return this.location;
  }

  async setLocation(location?: Geoposition) {
    const old = this.location.getValue();

    if (
      environment.positionFetchingTolerance
      && getDistance({
        latitude: old && old.coords ? old.coords.latitude : 0,
        longitude: old && old.coords ? old.coords.longitude : 0,
      }, {
        latitude: location && location.coords ? location.coords.latitude : 0,
        longitude: location && location.coords ? location.coords.longitude : 0,
      }) <= environment.positionFetchingTolerance
    ) {
      console.log('Location set triggered but coords are within range.');
      return;
    }

    this.storage.set(LAST_LOCATION_KEY, location).then();
    this.location.next(location);
  }

  trackLocation(track = true, options?: GeolocationOptions) {
    if (!track) {
      if (this.tracker) {
        console.log('Not tracking');
        this.tracker.unsubscribe();
        this.tracker = null;
      }
      return;
    }

    if (this.tracker) {
      return;
    }

    console.log('Tracking');
    this.tracker = this.subscribe(this.geolocation.watchPosition(options), async location => {
      location = this.processLocation(location);

      this.setLocation(location).then();
    }, error => {
      console.error(error);
    });
  }

  processGeocode(res: any) {
    console.log(res);
    const data = {
      country: <AddressComponent>null,
      administrative_area_level_1: <AddressComponent>null,
      administrative_area_level_2: <AddressComponent>null,
      locality: <AddressComponent>null,
      postal_code: <AddressComponent>null,
      street_address: <AddressComponent>null,
      route: <AddressComponent>null,
      street_number: <AddressComponent>null,
      coords: {
        lat: <number>null,
        lng: <number>null,
      },
    };

    const types = [];
    for (const type in data) {
      if (data.hasOwnProperty(type)) {
        types.push(type);
      }
    }

    if (res.results) {
      res.results.forEach(result => {
        if (!result.types) {
          return;
        }

        if (
          result.types
          // there are some other types (premise, airport, etc)
          // && result.types.some
          // && result.types.some(type => types.indexOf(type) !== -1)
        ) {
          if (
            result.address_components
            && result.address_components.forEach
          ) {
            result.address_components.forEach(component => {
              if (component.types && component.types.indexOf) {
                types.forEach(type => {
                  if (component.types.indexOf(type) !== -1) {
                    data[type] = component;
                  }
                });
              }
            });
          }

          if (
            result.geometry
            && result.geometry.location
          ) {
            data.coords = result.geometry.location;
          }
        }
      });
    }

    console.log(data);

    return data;
  }

  getCoords(address: string) {
    return this.http.get(
      GOOGLE_API_URL +
      `&address=` + encodeURIComponent(address) +
      (environment.googleMapsApiKey ? '&key=' + environment.googleMapsApiKey : '')
    ).pipe(switchMap((res: any) => {
      const data = this.processGeocode(res);

      return of(data.coords);
    }));
  }

  getAddress(coords: { latitude: number, longitude: number }) {
    return this.http.get(
      GOOGLE_API_URL +
      `&latlng=${coords.latitude},${coords.longitude}` +
      (environment.googleMapsApiKey ? '&key=' + environment.googleMapsApiKey : '')
    ).pipe(switchMap((res: any) => {
      const data = this.processGeocode(res);

      return of({
        street_address: data.street_address ? data.street_address.long_name : '',
        street_number: data.street_number ? data.street_number.long_name : '',
        route: data.route ? data.route.long_name : '',
        locality: data.locality ? data.locality.long_name : '',
        administrative_area_level_1: data.administrative_area_level_1 ? data.administrative_area_level_1.long_name : '',
        administrative_area_level_2: data.administrative_area_level_2 ? data.administrative_area_level_2.long_name : '',
        postal_code: data.postal_code ? data.postal_code.long_name : '',
        country: data.country ? data.country.long_name : '',
      });
    }));
  }

  getCountryShortCode(coords: { latitude: number, longitude: number }) {
    return this.http.get(
      GOOGLE_API_URL +
      `&latlng=${coords.latitude},${coords.longitude}` +
      (environment.googleMapsApiKey ? '&key=' + environment.googleMapsApiKey : '')
    ).pipe(switchMap((res: any) => {
      const data = this.processGeocode(res);

      // TODO: App - Perhaps default to ca (Canada).
      return of(data && data.country && data.country.short_name ? data.country.short_name : null);
    }));
  }

  getGoogleMaps(): Promise<any> {
    if (typeof google !== 'undefined' && google.maps) {
      return Promise.resolve(google.maps);
    }

    if (!this.googleMapsPromise) {
      this.googleMapsPromise = new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = 'https://maps.googleapis.com/maps/api/js' +
          (environment.googleMapsApiKey ? '?key=' + environment.googleMapsApiKey : '');
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);
        script.onload = () => {
          if (typeof google !== 'undefined' && google.maps) {
            resolve(google.maps);
          } else {
            reject('google maps not available');
          }
        };
      });
    }

    return this.googleMapsPromise;
  }

  normalizeLongitude(longitude: number) {
    if (!longitude) {
      return longitude;
    }

    while (longitude < -180) {
      longitude += 360;
    }

    while (longitude > 180) {
      longitude -= 360;
    }

    return longitude;
  }
}
