import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Unsubscriber} from '../utils/unsubscriber';
import {LoadingService} from './loading.service';
import {Platform} from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class PausedService extends Unsubscriber {
  paused = new BehaviorSubject(false);

  constructor(
    public loadingService: LoadingService,
    public platform: Platform,
  ) {
    super(loadingService);

    this.init();
  }

  init() {
    const checkDocumentVisibilityState = () => {
      const paused = document.visibilityState && document.visibilityState !== 'visible';
      if (this.paused.getValue() !== paused) {
        this.paused.next(paused);
      }
    };

    checkDocumentVisibilityState();

    document.addEventListener('visibilitychange', checkDocumentVisibilityState);

    this.then(this.platform.ready(), () => {
      this.subscribe(this.platform.pause, () => {
        if (!this.paused.getValue()) {
          this.paused.next(true);
        }
      });

      this.subscribe(this.platform.resume, () => {
        if (this.paused.getValue()) {
          this.paused.next(false);
        }
      });
    });
  }
}
