/**
 * AgentOnDuty API Documentation
 * The official AgentOnDuty API, documented with the OpenAPI 3.0 specification
 *
 * OpenAPI spec version: THE API VERSION IS NOW PROGRAMMATICALLY SET AND RETRIEVED! Use the public endpoint for reading the current version.
 * Contact: demo@synergogroup.net
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { BaseApiResponseDto } from '../model/baseApiResponseDto';
import { InlineResponse20023 } from '../model/inlineResponse20023';
import { InlineResponse20024 } from '../model/inlineResponse20024';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class MlsService {

    protected basePath = 'https://agentonduty-dev.azurewebsites.net/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * Get agent contact info for a specific property listed in 3rd party MLS.
     * @param mlsPropertyId The property ID for a property, as it appears in 3rd party MLS.
     * @param mlsReferenceNumber The reference number for Canadian listed properties, as it appears in 3rd party MLS, Realtor Canada.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAgentContactInfoForProperty(mlsPropertyId: string, mlsReferenceNumber?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20024>;
    public getAgentContactInfoForProperty(mlsPropertyId: string, mlsReferenceNumber?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20024>>;
    public getAgentContactInfoForProperty(mlsPropertyId: string, mlsReferenceNumber?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20024>>;
    public getAgentContactInfoForProperty(mlsPropertyId: string, mlsReferenceNumber?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (mlsPropertyId === null || mlsPropertyId === undefined) {
            throw new Error('Required parameter mlsPropertyId was null or undefined when calling getAgentContactInfoForProperty.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (mlsPropertyId !== undefined && mlsPropertyId !== null) {
            queryParameters = queryParameters.set('mlsPropertyId', <any>mlsPropertyId);
        }
        if (mlsReferenceNumber !== undefined && mlsReferenceNumber !== null) {
            queryParameters = queryParameters.set('mlsReferenceNumber', <any>mlsReferenceNumber);
        }

        let headers = this.defaultHeaders;

        // authentication (JWTbearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<InlineResponse20024>(`${this.basePath}/mls/property-agent-info`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Search properties for rent in a specific area, responding in a paginated manner.
     * @param latitude Latitude value
     * @param longitude Longitude value
     * @param offset Offset value, default is 0
     * @param limit Limit value, default is 5
     * @param sortBy Sorting value, default is price_low
     * @param distance Distance, in kilometers, default value is 30 kilometers
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchPropertiesForRent(latitude: number, longitude: number, offset?: number, limit?: number, sortBy?: string, distance?: number, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20023>;
    public searchPropertiesForRent(latitude: number, longitude: number, offset?: number, limit?: number, sortBy?: string, distance?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20023>>;
    public searchPropertiesForRent(latitude: number, longitude: number, offset?: number, limit?: number, sortBy?: string, distance?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20023>>;
    public searchPropertiesForRent(latitude: number, longitude: number, offset?: number, limit?: number, sortBy?: string, distance?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (latitude === null || latitude === undefined) {
            throw new Error('Required parameter latitude was null or undefined when calling searchPropertiesForRent.');
        }

        if (longitude === null || longitude === undefined) {
            throw new Error('Required parameter longitude was null or undefined when calling searchPropertiesForRent.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (latitude !== undefined && latitude !== null) {
            queryParameters = queryParameters.set('latitude', <any>latitude);
        }
        if (longitude !== undefined && longitude !== null) {
            queryParameters = queryParameters.set('longitude', <any>longitude);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (sortBy !== undefined && sortBy !== null) {
            queryParameters = queryParameters.set('sortBy', <any>sortBy);
        }
        if (distance !== undefined && distance !== null) {
            queryParameters = queryParameters.set('distance', <any>distance);
        }

        let headers = this.defaultHeaders;

        // authentication (JWTbearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<InlineResponse20023>(`${this.basePath}/mls/properties-for-rent`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Search properties for sale in a specific area, responding in a paginated manner.
     * @param latitude Latitude value
     * @param longitude Longitude value
     * @param offset Offset value, default is 0
     * @param limit Limit value, default is 5
     * @param sortBy Sorting value, default is relevance
     * @param distance Distance, in kilometers, default value is 30 kilometers
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchPropertiesForSale(latitude: number, longitude: number, offset?: number, limit?: number, sortBy?: string, distance?: number, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20023>;
    public searchPropertiesForSale(latitude: number, longitude: number, offset?: number, limit?: number, sortBy?: string, distance?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20023>>;
    public searchPropertiesForSale(latitude: number, longitude: number, offset?: number, limit?: number, sortBy?: string, distance?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20023>>;
    public searchPropertiesForSale(latitude: number, longitude: number, offset?: number, limit?: number, sortBy?: string, distance?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (latitude === null || latitude === undefined) {
            throw new Error('Required parameter latitude was null or undefined when calling searchPropertiesForSale.');
        }

        if (longitude === null || longitude === undefined) {
            throw new Error('Required parameter longitude was null or undefined when calling searchPropertiesForSale.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (latitude !== undefined && latitude !== null) {
            queryParameters = queryParameters.set('latitude', <any>latitude);
        }
        if (longitude !== undefined && longitude !== null) {
            queryParameters = queryParameters.set('longitude', <any>longitude);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (sortBy !== undefined && sortBy !== null) {
            queryParameters = queryParameters.set('sortBy', <any>sortBy);
        }
        if (distance !== undefined && distance !== null) {
            queryParameters = queryParameters.set('distance', <any>distance);
        }

        let headers = this.defaultHeaders;

        // authentication (JWTbearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<InlineResponse20023>(`${this.basePath}/mls/properties-for-sale`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
