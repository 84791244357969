import {Directive, HostListener} from '@angular/core';
import {Router} from '@angular/router';
import {InAppBrowser} from '@ionic-native/in-app-browser/ngx';
import {Events, ModalController} from '@ionic/angular';

@Directive({
  selector: '[contentHandlers]'
})
export class ContentHandlersDirective {
  constructor(
    public router: Router,
    public iab: InAppBrowser,
    public events: Events,
    public modalCtrl: ModalController,
  ) {
  }

  @HostListener('click', ['$event']) onClick(event: MouseEvent) {
    const element: any = event.target || event.srcElement || event.currentTarget;

    if (element && element.tagName && element.tagName.toLowerCase() === 'a') {
      let href = String(element.href);
      if (element.getAttribute) {
        href = element.getAttribute('href');
      }

      // TODO: App - Perhaps find a nicer way to get these events working.
      let target = String(element.target);
      if (element.getAttribute) {
        target = element.getAttribute('target');
      }

      if (!href && !target) {
        return;
      }

      event.preventDefault();

      console.log(event, element, href, target);

      if (href) {
        const hrefLower = href.trim().toLowerCase();

        if (
          hrefLower.indexOf('://') === -1
          && !hrefLower.match(/^[a-z0-9-_]+:/)
        ) {
          this.router.navigate([href]).then();
          this.modalCtrl.dismiss().then();
        } else {
          this.iab.create(href, '_system');
        }
      }

      if (target) {
        this.events.publish('modal', target);
      }
    }
  }
}
