import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {InAppBrowser} from '@ionic-native/in-app-browser/ngx';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {IonicModule} from '@ionic/angular';
import {IonicStorageModule, Storage} from '@ionic/storage';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import {FormsModule} from '@angular/forms';

import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
// We use the relative path non-dist sources to be able to debug.
import {ApiModule, Configuration} from '../../swagger-client';
import {JWT_OPTIONS, JwtModule} from '@auth0/angular-jwt';
import {AUTH_TOKEN_KEY} from './providers/auth.service';
import {HttpInterceptorService} from './providers/http-interceptor.service';
import {WsService} from './providers/ws.service';
import {Camera} from '@ionic-native/camera/ngx';
import {Facebook} from '@ionic-native/facebook/ngx';
import {AuthServiceConfig, FacebookLoginProvider, GoogleLoginProvider, SocialLoginModule} from 'angularx-social-login';
import {GooglePlus} from '@ionic-native/google-plus/ngx';
import {Wechat} from '@ionic-native/wechat/ngx';
import {Geolocation} from '@ionic-native/geolocation/ngx';
import {Deeplinks} from '@ionic-native/deeplinks/ngx';
import {AndroidPermissions} from '@ionic-native/android-permissions/ngx';
import {LocationAccuracy} from '@ionic-native/location-accuracy/ngx';
import {CallModule} from './components/call/call.module';
import {CallComponent} from './components/call/call.component';
import {DirectivesModule} from './directives/directives.module';
import {AnswerModule} from './components/answer/answer.module';
import {AnswerComponent} from './components/answer/answer.component';
import {Stripe as StripeNative} from '@ionic-native/stripe/ngx';
import {OneSignal as OneSignalNative} from '@ionic-native/onesignal/ngx';
import {LocalNotifications} from '@ionic-native/local-notifications/ngx';
import {SignInWithApple} from '@ionic-native/sign-in-with-apple/ngx';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

export function createApiConfiguration() {
  return new Configuration(environment.api);
}

export function jwtOptionsFactory(storage) {
  const parser = new URL(environment.api.basePath);
  return {
    tokenGetter: () => {
      return storage.get(AUTH_TOKEN_KEY);
    },
    whitelistedDomains: [parser.host + ([80, 443].indexOf(parseInt(parser.port, null)) === -1 ? '' : (':' + parser.port))]
  };
}

export function createFacebookConfiguration() {
  return new AuthServiceConfig([
    {
      id: FacebookLoginProvider.PROVIDER_ID,
      provider: new FacebookLoginProvider(environment.facebookAppId)
    },
    {
      id: GoogleLoginProvider.PROVIDER_ID,
      provider: new GoogleLoginProvider(environment.googleOauthClientId)
    },
  ]);
}

@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    IonicModule.forRoot({
      mode: 'md',
      swipeBackEnabled: environment.swipeBackEnabled,
    }),
    IonicStorageModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient],
      }
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.serviceWorker,
      registrationStrategy: 'registerImmediately',
    }),
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: [Storage],
      },
    }),
    ApiModule.forRoot(createApiConfiguration),
    SocialLoginModule,
    CallModule,
    DirectivesModule,
    AnswerModule,
  ],
  declarations: [
    AppComponent,
  ],
  providers: [
    InAppBrowser,
    SplashScreen,
    StatusBar,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true
    },
    Geolocation,
    WsService,
    Camera,
    Facebook,
    {
      provide: AuthServiceConfig,
      useFactory: createFacebookConfiguration
    },
    GooglePlus,
    Wechat,
    Deeplinks,
    AndroidPermissions,
    LocationAccuracy,
    StripeNative,
    OneSignalNative,
    LocalNotifications,
    SignInWithApple,
  ],
  entryComponents: [
    CallComponent,
    AnswerComponent,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
