import {Component, ViewChild, ViewEncapsulation} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {SwUpdate} from '@angular/service-worker';

import {IonMenu, ModalController, Platform, ToastController} from '@ionic/angular';

import {SplashScreen} from '@ionic-native/splash-screen/ngx';

import {Storage} from '@ionic/storage';

import {AuthService} from './providers/auth.service';
import {LanguageService} from './providers/language.service';
import {SplashService} from './providers/splash.service';
import {
  AgentProfileDto as AgentProfile,
  CallSessionDto,
  CommonUserResponseDto as Profile,
  RecentInteractionDto as RecentInteraction,
  UserMetadataDto as User
} from '../../swagger-client';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../environments/environment';
import {DarkService} from './providers/dark.service';
import {Title} from '@angular/platform-browser';
import {Unsubscriber} from './utils/unsubscriber';
import {WsService} from './providers/ws.service';
import {distinctUntilChanged, filter} from 'rxjs/operators';
import {RosterService} from './providers/roster.service';
import {Deeplinks} from '@ionic-native/deeplinks/ngx';
import {LoadingService} from './providers/loading.service';
import {CallService} from './providers/call.service';
import {AnswerComponent} from './components/answer/answer.component';
import {PushService} from './providers/push.service';
import {PausedService} from './providers/paused.service';
import {PixelService} from './providers/pixel.service';

// import {FakerService} from './providers/faker.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent extends Unsubscriber {
  roster: boolean = null;
  environment = environment;
  loggedIn = false;
  profile: Profile;
  firstInterest: string;
  availableStatus = AgentProfile.StatusEnum.Available;
  checkForApplicationUpdateInterval: any;
  checkForProfileUpdateInterval: any;

  @ViewChild('menu', {static: false}) menu: IonMenu;

  // TODO: App - Perhaps refactor all injected service variable names for (?<!Service)\: [a-zA-Z0-9]+Service
  constructor(
    public platform: Platform,
    public router: Router,
    public splashScreen: SplashScreen,
    public storage: Storage,
    public auth: AuthService,
    public swUpdate: SwUpdate,
    public toastCtrl: ToastController,
    public language: LanguageService,
    public splash: SplashService,
    public translate: TranslateService,
    public dark: DarkService,
    public title: Title,
    public ws: WsService,
    public rosterService: RosterService,
    public deeplinks: Deeplinks,
    public loadingService: LoadingService,
    public call: CallService,
    public modalCtrl: ModalController,
    // public faker: FakerService,
    public push: PushService,
    public pausedService: PausedService,
    public pixelService: PixelService,
  ) {
    super(loadingService);

    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(async () => {
      // leave this here to open the socket as soon as the application starts
      this.ws.init();

      // leave this here to init push notifications as soon as the application starts
      this.then(this.push.init(), null, e => console.error(e));

      // leave this here to init advertiser tracking as soon as the application starts
      this.pixelService.checkAndEnableAdvertiserTracking().then();
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.pixelService.logPageView().then();
        }
      });

      this.dark.checkDarkTheme();

      this.then(this.language.setInitialAppLanguage(), null, null, true);

      this.subscribe(this.rosterService.roster, async roster => {
        this.roster = roster;

        window.document.body.classList.toggle('roster', this.roster);

        if (!this.roster) {
          this.title.setTitle(await this.translate.get('app.document-title.customer').toPromise());
        } else {
          this.title.setTitle(await this.translate.get('app.document-title.roster').toPromise());
        }
      }, null, null, true);

      this.subscribe(this.auth.profile, profile => {
        this.profile = profile;
        this.loggedIn = !!(profile && profile.id);
      }, null, null, true);

      this.subscribe(this.auth.firstInterest, firstInterest => {
        this.firstInterest = firstInterest;
      }, null, null, true);

      this.then(this.auth.refreshProfile(), null, null, true);
      const checkForProfileUpdateHandler = () => {
        console.log('checkForProfileUpdateInterval');
        if (!navigator.onLine) {
          console.warn('Offline');
          return;
        }

        this.auth.refreshProfile().then();
      };
      if (environment.checkForProfileUpdateInterval) {
        this.checkForProfileUpdateInterval = setInterval(
          checkForProfileUpdateHandler,
          environment.checkForProfileUpdateInterval
        );
      }
      window.addEventListener('online', checkForProfileUpdateHandler);

      if (environment.serviceWorker) {
        this.subscribe(this.swUpdate.available, async () => {
          const toast = await this.toastCtrl.create({
            ...environment.toast,
            duration: 0,
            message: await this.translate.get('app.update-available').toPromise(),
            closeButtonText: await this.translate.get('app.reload').toPromise()
          });
          toast.present();

          toast
            .onDidDismiss()
            .then(() => this.swUpdate.activateUpdate().then(() => window.location.reload(), e => console.error(e)), e => console.error(e));
        });

        if (this.swUpdate.isEnabled) {
          console.log('checkForUpdate');
          this.then(this.swUpdate.checkForUpdate());

          if (environment.checkForApplicationUpdateInterval) {
            this.checkForApplicationUpdateInterval = setInterval(() => {
              console.log('checkForApplicationUpdateInterval');
              this.swUpdate.checkForUpdate().then();
            }, environment.checkForApplicationUpdateInterval);
          }
        }
      }

      let url = '';

      this.subscribe(this.router.events.pipe(filter(event => {
        return event instanceof NavigationEnd;
      })), (event: NavigationEnd) => {
        url = event.url;
      });

      const modals: Map<string, any> = new Map<string, any>();

      const showAnswerModal = async (
        type = RecentInteraction.TypeEnum.Call,
        from: User,
        session?: CallSessionDto,
        message?: string,
        data?: any
      ) => {
        // Show modal if app is not paused or if push is not enabled
        let shouldShowAnswerModal = !this.pausedService.paused.getValue() || !this.push.isEnabled.getValue();

        if (!shouldShowAnswerModal) {
          let name = '';
          if (from) {
            name = [from.firstName, from.lastName].join(' ');
            const firstInterest = this.auth.getProfileFirstInterest(from);
            if (firstInterest) {
              name += ' ' +
                '(' + await this.translate.get('interest.' + (firstInterest ? firstInterest : 'undecided') + '.label').toPromise() + ')';
            }
          }

          let content = '';
          switch (type) {
            case RecentInteraction.TypeEnum.Call:
              content = await this.translate.get('answer.text.call').toPromise();
              break;
            case RecentInteraction.TypeEnum.Chat:
              content = await this.translate.get('answer.text.chat').toPromise();
              break;
            case RecentInteraction.TypeEnum.Message:
              content = await this.translate.get('answer.text.message', {message: message}).toPromise();
              break;
            case RecentInteraction.TypeEnum.Connection:
              content = await this.translate.get('answer.text.connection').toPromise();
              break;
            case RecentInteraction.TypeEnum.Listing:
              content = await this.translate.get('answer.text.listing').toPromise();
              break;
          }

          try {
            throw new Error('Skipping local notifications; they do not work properly on iOS.');

            await this.push.sendSelfNotification(
              name,
              content,
              null,
              from.displayImageUrl,
              {
                type,
                data: JSON.stringify(data)
              }
            ).then();
          } catch (e) {
            console.error(e);

            // Tried to trigger a push notifications but that failed so falling back to the modal for whatever it is worth
            shouldShowAnswerModal = true;
          }
        }

        if (shouldShowAnswerModal) {
          const id = (from ? from.id : '');

          // If a call modal is on, silence everything else
          const keyCall = id + '-' + RecentInteraction.TypeEnum[RecentInteraction.TypeEnum.Call];
          if (modals.has(keyCall)) {
            return;
          }

          const key = type === RecentInteraction.TypeEnum.Call ? keyCall : id;

          const actuallyShowAnswerModal = async () => {
            // WARNING: modals do not show up if the page/document is not visible
            modals.set(key, await this.modalCtrl.create({
              component: AnswerComponent,
              showBackdrop: false, // no backdrop
              cssClass: 'answer-modal',
              componentProps: {
                userId: from ? from.id : '',
                profile: from ? from : null,
                sessionId: session ? session.id : null,
                connectionId: type === RecentInteraction.TypeEnum.Connection && data && data.id ? data.id : null,
                propertyId: type === RecentInteraction.TypeEnum.Listing && data && data.id ? data.id : null,
                session: session,
                video: session ? session.withVideo : null,
                type,
                message,
              },
            }));
            modals.get(key).onDidDismiss().then(() => modals.delete(key));
            modals.get(key).present();
          };

          if (modals.has(key)) {
            modals.get(key).dismiss().then(actuallyShowAnswerModal);
          } else {
            actuallyShowAnswerModal().then();
          }
        }
      };

      this.subscribe(this.ws.callJoined.pipe(distinctUntilChanged()), async session => {
        if (!session || !session.id) {
          return;
        }

        showAnswerModal(
          RecentInteraction.TypeEnum.Call,
          this.auth.other(session.sender, session.recipient),
          session,
          null,
          session
        ).then();
      });

      // Uncomment this to get the incoming call popup always on for testing.
      // this.ws.callJoined.next(this.faker.callSession());

      this.subscribe(this.ws.connection.pipe(distinctUntilChanged()), async connection => {
        if (!connection || !connection.id) {
          return;
        }

        showAnswerModal(
          RecentInteraction.TypeEnum.Connection,
          this.auth.other(connection.agent, connection.client),
          null,
          null,
          connection
        ).then();
      });

      this.subscribe(this.ws.chat.pipe(distinctUntilChanged()), async chat => {
        if (
          (!chat || !chat.id || url === ('/chat/' + chat.id))
          && !this.pausedService.paused.getValue()
        ) {
          return;
        }

        showAnswerModal(
          RecentInteraction.TypeEnum.Chat,
          this.auth.other(chat.fromUser, chat.toUser),
          null,
          null,
          chat
        ).then();
      });

      this.subscribe(this.ws.message.pipe(distinctUntilChanged()), async message => {
        if (
          (!message || !message.chatId || url === ('/chat/' + message.chatId))
          && !this.pausedService.paused.getValue()
        ) {
          return;
        }

        showAnswerModal(RecentInteraction.TypeEnum.Message,
          this.auth.other(message.from, message.to),
          null,
          message.body,
          message
        ).then();
      });

      this.subscribe(this.ws.property.pipe(distinctUntilChanged()), async property => {
        if (!property || !property.id) {
          return;
        }

        showAnswerModal(
          RecentInteraction.TypeEnum.Listing,
          this.auth.other(property.createdBy),
          null,
          null,
          property
        ).then();
      });

      this.splashScreen.hide();

      this.subscribe(this.deeplinks.route({}), match => {
        console.log('Successfully matched deeplink route', match);
      }, error => {
        console.error('Could not match deeplink route', error);

        if (!error.$link) {
          return;
        }

        this.router.navigate([this._getRealPath(error.$link)]).then();
      }); // This one does not need a loader as it is not implicitly triggered
    });
  }

  logout() {
    // Always go to the login page
    this.then(this.auth.logoutWithRedirectWithReturnUrl(null, false), null, null, true);
  }

  goToPage(page = '') {
    if (!page) {
      return;
    }

    this.router.navigate([page]).then();
    this.menu.setOpen(false).then();
  }

  openSplash() {
    this.splash.setSplashDone(false).then(() => {
      this.router.navigate(['/splash']).then();
      this.menu.setOpen(false).then();
    });
  }

  cancelAllSubscriptionsAndPromises(): void {
    super.cancelAllSubscriptionsAndPromises();

    if (this.checkForProfileUpdateInterval) {
      clearInterval(this.checkForProfileUpdateInterval);
    }

    if (this.checkForApplicationUpdateInterval) {
      clearInterval(this.checkForApplicationUpdateInterval);
    }
  }

  //region adaptation from node_modules/ionic-plugin-deeplinks/www/deeplink.js
  _getRealPath(data) {

    // 1. Let's just do the obvious and return the parsed 'path' first, if available.
    if (!!data.path && data.path !== '') {
      return data.path;
    }

    // 2. Now, are we using a non-standard scheme?
    const isCustomScheme = data.scheme.indexOf('http') === -1;

    // 3. Nope so we'll go fragment first if available as that should be what comes after
    if (!isCustomScheme) {
      if (!!data.fragment) {
        return this._stripFragmentLeadingHash(data.fragment);
      }
    }

    // 4. Now fall back to host / authority
    if (!!data.host) {
      if (data.host.charAt(0) !== '/') {
        data.host = '/' + data.host;
      }

      return data.host;
    }

    // 5. We'll use fragment next if we're in a custom scheme, though this might need a little more thought
    if (isCustomScheme && !!data.fragment) {
      return this._stripFragmentLeadingHash(data.fragment);
    }

    // 6. Last resort - no obvious path, fragment or host, so we
    // slice out the scheme and any query string or fragment from the full url.
    let restOfUrl = data.url;
    let separator = data.url.indexOf('://');

    if (separator !== -1) {
      restOfUrl = data.url.slice(separator + 3);
    } else {
      separator = data.url.indexOf(':/');
      if (separator !== -1) {
        restOfUrl = data.url.slice(separator + 2);
      }
    }

    const qs = restOfUrl.indexOf('?');
    if (qs > -1) {
      restOfUrl = restOfUrl.slice(0, qs);
    }

    const hs = restOfUrl.indexOf('#');
    if (hs > -1) {
      restOfUrl = restOfUrl.slice(0, hs);
    }

    return restOfUrl;
  }

  _stripFragmentLeadingHash(fragment) {
    const hs = fragment.indexOf('#');

    if (hs > -1) {
      fragment.slice(0, hs);
    }

    return fragment;
  }

  //endregion


  // TODO: App - Perhaps change rgb(0, 138, 235) app wide theme and bg color to something else. Maybe transparent or white.
  // TODO: App - Deep links plugin for password reset, confirm, etc. Needs setup and test. See https://www.youtube.com/watch?v=DG7TMredeUs
  // TODO: App - Perhaps intercept and control the PWA installation. See https://web.dev/customize-install/#android_intent_filters
  // TODO: App - Perhaps use the Ionic "soft" deployment plugin.
  // TODO: App - Perhaps use the Ionic CI/CD Appflow.
  // TODO: App - Perhaps update to Ionic 5 (https://www.youtube.com/watch?v=851HkqX3YQ4).
}
