/**
 * AgentOnDuty API Documentation
 * The official AgentOnDuty API, documented with the OpenAPI 3.0 specification
 *
 * OpenAPI spec version: THE API VERSION IS NOW PROGRAMMATICALLY SET AND RETRIEVED! Use the public endpoint for reading the current version.
 * Contact: demo@synergogroup.net
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AppleProviderRegistrationPayloadDto } from '../model/appleProviderRegistrationPayloadDto';
import { BaseApiResponseDto } from '../model/baseApiResponseDto';
import { Body5 } from '../model/body5';
import { ConnectToAgentDto } from '../model/connectToAgentDto';
import { InlineResponse2004 } from '../model/inlineResponse2004';
import { InlineResponse2005 } from '../model/inlineResponse2005';
import { InlineResponse2006 } from '../model/inlineResponse2006';
import { InlineResponse2007 } from '../model/inlineResponse2007';
import { InlineResponse2008 } from '../model/inlineResponse2008';
import { RegisterClientDto } from '../model/registerClientDto';
import { SocialProviderPayloadDto } from '../model/socialProviderPayloadDto';
import { UpdateClientDto } from '../model/updateClientDto';
import { UpdateClientFromAdminDto } from '../model/updateClientFromAdminDto';
import { UpdateUserInterestDto } from '../model/updateUserInterestDto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ClientService {

    protected basePath = 'https://agentonduty-dev.azurewebsites.net/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * Create persisted connection between the logged client and an agent specified through data payload
     * @param body Data to be used in the connection creation process
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public connectToAgent(body: ConnectToAgentDto, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse2008>;
    public connectToAgent(body: ConnectToAgentDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse2008>>;
    public connectToAgent(body: ConnectToAgentDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse2008>>;
    public connectToAgent(body: ConnectToAgentDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling connectToAgent.');
        }

        let headers = this.defaultHeaders;

        // authentication (JWTbearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<InlineResponse2008>(`${this.basePath}/client/connect-to-agent`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Handles client registration followed by automatic login via Apple SSO. Duplicates are not allowed. Username must be unique.
     * @param body Apple provider registration data payload
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public doAppleRegisterForClient(body: AppleProviderRegistrationPayloadDto, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse2004>;
    public doAppleRegisterForClient(body: AppleProviderRegistrationPayloadDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse2004>>;
    public doAppleRegisterForClient(body: AppleProviderRegistrationPayloadDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse2004>>;
    public doAppleRegisterForClient(body: AppleProviderRegistrationPayloadDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling doAppleRegisterForClient.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<InlineResponse2004>(`${this.basePath}/client/apple-register`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Handles client registration followed by automatic login via social platforms (SSO). Duplicates are not allowed. Username must be unique.
     * @param body Client data to register (extracted from Social platform)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public doSocialRegisterForClient(body: SocialProviderPayloadDto, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse2004>;
    public doSocialRegisterForClient(body: SocialProviderPayloadDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse2004>>;
    public doSocialRegisterForClient(body: SocialProviderPayloadDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse2004>>;
    public doSocialRegisterForClient(body: SocialProviderPayloadDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling doSocialRegisterForClient.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<InlineResponse2004>(`${this.basePath}/client/social`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Retrieves all registered Clients (for Admin), in a paginated manner, with filtering and sorting.
     * @param search The search term to filter Clients by
     * @param page Requsted current page of results
     * @param size The amount of results one page must have
     * @param sortBy Attribute name to sort results by
     * @param isAscending Sorting order (true if Ascending, false is Descending)
     * @param isActive True if querying only Active Clients, false if querying only inactive Clients, null if querying all
     * @param country The country name to filter Clients by
     * @param fromCreationDate Get Clients with a creation date greater than or equal to the supplied filter
     * @param toCreationDate Get Clients with a creation date less than or equal to the supplied filter
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllClients(search?: string, page?: number, size?: number, sortBy?: string, isAscending?: boolean, isActive?: boolean, country?: string, fromCreationDate?: string, toCreationDate?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse2006>;
    public getAllClients(search?: string, page?: number, size?: number, sortBy?: string, isAscending?: boolean, isActive?: boolean, country?: string, fromCreationDate?: string, toCreationDate?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse2006>>;
    public getAllClients(search?: string, page?: number, size?: number, sortBy?: string, isAscending?: boolean, isActive?: boolean, country?: string, fromCreationDate?: string, toCreationDate?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse2006>>;
    public getAllClients(search?: string, page?: number, size?: number, sortBy?: string, isAscending?: boolean, isActive?: boolean, country?: string, fromCreationDate?: string, toCreationDate?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {










        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (search !== undefined && search !== null) {
            queryParameters = queryParameters.set('search', <any>search);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sortBy !== undefined && sortBy !== null) {
            queryParameters = queryParameters.set('sortBy', <any>sortBy);
        }
        if (isAscending !== undefined && isAscending !== null) {
            queryParameters = queryParameters.set('isAscending', <any>isAscending);
        }
        if (isActive !== undefined && isActive !== null) {
            queryParameters = queryParameters.set('isActive', <any>isActive);
        }
        if (country !== undefined && country !== null) {
            queryParameters = queryParameters.set('country', <any>country);
        }
        if (fromCreationDate !== undefined && fromCreationDate !== null) {
            queryParameters = queryParameters.set('fromCreationDate', <any>fromCreationDate);
        }
        if (toCreationDate !== undefined && toCreationDate !== null) {
            queryParameters = queryParameters.set('toCreationDate', <any>toCreationDate);
        }

        let headers = this.defaultHeaders;

        // authentication (JWTbearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<InlineResponse2006>(`${this.basePath}/client/admin/all`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Registers a new Client in the database. Duplicates are not allowed. Username must be unique.
     * @param body Client to register
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public registerClient(body: RegisterClientDto, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse2005>;
    public registerClient(body: RegisterClientDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse2005>>;
    public registerClient(body: RegisterClientDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse2005>>;
    public registerClient(body: RegisterClientDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling registerClient.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<InlineResponse2005>(`${this.basePath}/client`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Search Clients by first name, last name, username or phone.
     * @param term The search term to filter Clients by
     * @param limit Limit parameter to specify the maximum number of results to fetch.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchClients(term?: string, limit?: number, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse2007>;
    public searchClients(term?: string, limit?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse2007>>;
    public searchClients(term?: string, limit?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse2007>>;
    public searchClients(term?: string, limit?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (term !== undefined && term !== null) {
            queryParameters = queryParameters.set('term', <any>term);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }

        let headers = this.defaultHeaders;

        // authentication (JWTbearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<InlineResponse2007>(`${this.basePath}/client/search`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Updates the active state of a Client (Soft Delete).
     * @param body Toggle state data payload
     * @param id The Client Id to update Active State for
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public toggleClientActiveState(body: Body5, id: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse2005>;
    public toggleClientActiveState(body: Body5, id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse2005>>;
    public toggleClientActiveState(body: Body5, id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse2005>>;
    public toggleClientActiveState(body: Body5, id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling toggleClientActiveState.');
        }

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling toggleClientActiveState.');
        }

        let headers = this.defaultHeaders;

        // authentication (JWTbearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<InlineResponse2005>(`${this.basePath}/client/admin/toggle-active-state/${encodeURIComponent(String(id))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Updates the details for an existing Client
     * @param body Client data to update
     * @param id The Client Id to perform update for
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateClient(body: UpdateClientDto, id: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse2005>;
    public updateClient(body: UpdateClientDto, id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse2005>>;
    public updateClient(body: UpdateClientDto, id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse2005>>;
    public updateClient(body: UpdateClientDto, id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateClient.');
        }

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling updateClient.');
        }

        let headers = this.defaultHeaders;

        // authentication (JWTbearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<InlineResponse2005>(`${this.basePath}/client/${encodeURIComponent(String(id))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Updates the details for an existing Client, from Admin side
     * @param body Client data to update
     * @param id The Client Id to perform update for
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateClientFromAdmin(body: UpdateClientFromAdminDto, id: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse2005>;
    public updateClientFromAdmin(body: UpdateClientFromAdminDto, id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse2005>>;
    public updateClientFromAdmin(body: UpdateClientFromAdminDto, id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse2005>>;
    public updateClientFromAdmin(body: UpdateClientFromAdminDto, id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateClientFromAdmin.');
        }

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling updateClientFromAdmin.');
        }

        let headers = this.defaultHeaders;

        // authentication (JWTbearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<InlineResponse2005>(`${this.basePath}/client/admin/update/${encodeURIComponent(String(id))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Set client interests for the logged client
     * @param body Data to use in the update
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateInterest(body: UpdateUserInterestDto, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse2005>;
    public updateInterest(body: UpdateUserInterestDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse2005>>;
    public updateInterest(body: UpdateUserInterestDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse2005>>;
    public updateInterest(body: UpdateUserInterestDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateInterest.');
        }

        let headers = this.defaultHeaders;

        // authentication (JWTbearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<InlineResponse2005>(`${this.basePath}/client/profile/interest`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
