import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Storage} from '@ionic/storage';
import {Unsubscriber} from '../utils/unsubscriber';
import {BehaviorSubject} from 'rxjs';
import {LoadingService} from './loading.service';
import {registerLocaleData} from '@angular/common';
import localeEn from '@angular/common/locales/en';
import localeFr from '@angular/common/locales/fr';
import localeEs from '@angular/common/locales/es';
import localeIt from '@angular/common/locales/it';
import localePl from '@angular/common/locales/pl';
import localePt from '@angular/common/locales/pt';
import localeRo from '@angular/common/locales/ro';
import localeZh from '@angular/common/locales/zh';
import localeEnExtra from '@angular/common/locales/extra/en';
import localeFrExtra from '@angular/common/locales/extra/fr';
import localeEsExtra from '@angular/common/locales/extra/es';
import localeItExtra from '@angular/common/locales/extra/it';
import localePlExtra from '@angular/common/locales/extra/pl';
import localePtExtra from '@angular/common/locales/extra/pt';
import localeRoExtra from '@angular/common/locales/extra/ro';
import localeZhExtra from '@angular/common/locales/extra/zh';

const SELECTED_LANGUAGE_KEY = 'SELECTED_LANGUAGE';

@Injectable({
  providedIn: 'root'
})
export class LanguageService extends Unsubscriber {
  default = 'en';
  language: BehaviorSubject<string>;
  languages: Map<string, string>;
  languageFlags: Map<string, string>;
  locales: Map<string, any>;
  localesExtra: Map<string, any>;

  constructor(
    public translate: TranslateService,
    public storage: Storage,
    public loadingService: LoadingService,
  ) {
    super(loadingService);

    this.languageFlags = new Map();
    this.languageFlags.set('en', 'assets/img/flags/en.svg');
    this.languageFlags.set('fr', 'assets/img/flags/fr.svg');
    this.languageFlags.set('es', 'assets/img/flags/es.svg');
    this.languageFlags.set('it', 'assets/img/flags/it.svg');
    this.languageFlags.set('pl', 'assets/img/flags/pl.svg');
    this.languageFlags.set('pt', 'assets/img/flags/pt.svg');
    this.languageFlags.set('ro', 'assets/img/flags/ro.svg');
    this.languageFlags.set('zh', 'assets/img/flags/zh.svg');

    this.languages = new Map();

    this.locales = new Map();
    this.locales.set('en', localeEn);
    this.locales.set('fr', localeFr);
    this.locales.set('es', localeEs);
    this.locales.set('it', localeIt);
    this.locales.set('pl', localePl);
    this.locales.set('pt', localePt);
    this.locales.set('ro', localeRo);
    this.locales.set('zh', localeZh);

    this.localesExtra = new Map();
    this.localesExtra.set('en', localeEnExtra);
    this.localesExtra.set('fr', localeFrExtra);
    this.localesExtra.set('es', localeEsExtra);
    this.localesExtra.set('it', localeItExtra);
    this.localesExtra.set('pl', localePlExtra);
    this.localesExtra.set('pt', localePtExtra);
    this.localesExtra.set('ro', localeRoExtra);
    this.localesExtra.set('zh', localeZhExtra);

    this.language = new BehaviorSubject(this.default);
    this.setLanguages().then();
  }

  async setLanguages() {
    this.languages.set('en' , await this.translate.get('language.en').toPromise());
    this.languages.set('fr' , await this.translate.get('language.fr').toPromise());
    this.languages.set('es' , await this.translate.get('language.es').toPromise());
    this.languages.set('it' , await this.translate.get('language.it').toPromise());
    this.languages.set('pl' , await this.translate.get('language.pl').toPromise());
    this.languages.set('pt' , await this.translate.get('language.pt').toPromise());
    this.languages.set('ro' , await this.translate.get('language.ro').toPromise());
    this.languages.set('zh' , await this.translate.get('language.zh').toPromise());
  }

  checkLanguage(language: string) {
    if (!language) {
      language = '';
    }

    language = language.toLowerCase();

    if (this.languages.has(language)) {
      return language;
    }

    const parts = language.split(/[^A-Za-z0-9]+/);

    if (this.languages.has(parts[0])) {
      return parts[0];
    }

    return this.default;
  }

  setInitialAppLanguage() {
    const language = this.translate.getBrowserCultureLang();

    this.translate.setDefaultLang(this.checkLanguage(language));

    return this.storage.get(SELECTED_LANGUAGE_KEY).then((language2: string) => {
      this.setLanguage(language2 ? language2 : language);
    });
  }

  setLanguage(language: string) {
    language = this.checkLanguage(language);
    this.subscribe(this.translate.use(language), () => {
      registerLocaleData(this.locales.get(language), language, this.localesExtra.get(language));

      this.storage.set(SELECTED_LANGUAGE_KEY, language).then();
      this.setLanguages().then();
      this.language.next(language);
    });
  }
}
