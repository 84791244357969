import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {RosterService} from '../providers/roster.service';

@Injectable({
  providedIn: 'root'
})
export class RosterGuard implements CanActivate, CanLoad {
  constructor(
    public roster: RosterService,
    public router: Router,
  ) {
  }

  handler(
    guard: string,
    path: string,
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.roster.getRoster().then(roster => {
      console.log('RosterGuard ' + guard + ' route:', path);
      console.log('RosterGuard ' + guard + ' roster:', roster);
      if (!roster) {
        this.router.navigate(['/app']).then();
        return false;
      } else {
        return true;
      }
    });
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    // state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.handler('canActivate', '/' + route.url.join('/'));
  }

  canLoad(
    route: Route,
    // segments: UrlSegment[],
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.handler('canLoad', route.path);
  }
}
