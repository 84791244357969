/**
 * AgentOnDuty API Documentation
 * The official AgentOnDuty API, documented with the OpenAPI 3.0 specification
 *
 * OpenAPI spec version: THE API VERSION IS NOW PROGRAMMATICALLY SET AND RETRIEVED! Use the public endpoint for reading the current version.
 * Contact: demo@synergogroup.net
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface AgentProfileDto { 
    companyName?: string;
    trackingId?: string;
    jobTitle?: string;
    description?: string;
    /**
     * Agent Status values:  * `Available` - Agent is using the Platform and he is ready to communicate with existing and new Clients  * `Unavailable` - Agent is not available 
     */
    status?: AgentProfileDto.StatusEnum;
}
export namespace AgentProfileDto {
    export type StatusEnum = 'Available' | 'Unavailable';
    export const StatusEnum = {
        Available: 'Available' as StatusEnum,
        Unavailable: 'Unavailable' as StatusEnum
    };
}