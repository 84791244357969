import {Component, ElementRef, Input, ViewChild} from '@angular/core';
import {Unsubscriber} from '../../utils/unsubscriber';
import {IonViewWillEnter} from '../../utils/ion-view-will-enter';
import {
  CallService as CallApi,
  CallSessionDto as Session,
  ChatService,
  ListingDto as Listing,
  RecentInteractionDto as RecentInteraction,
  UserMetadataDto as Profile,
  UserService
} from '../../../../swagger-client';
import {LoadingService} from '../../providers/loading.service';
import {Components} from '@ionic/core';
import {WsService} from '../../providers/ws.service';
import {CallService} from '../../providers/call.service';
import {AuthService} from '../../providers/auth.service';
import {environment} from '../../../environments/environment';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {Platform, ToastController} from '@ionic/angular';

@Component({
  selector: 'answer',
  templateUrl: './answer.component.html',
  styleUrls: ['./answer.component.scss'],
})
export class AnswerComponent extends Unsubscriber implements IonViewWillEnter {
  @Input() modal: Components.IonModal;

  @Input() userId: string;
  @Input() sessionId: string;
  @Input() connectionId: string;
  @Input() propertyId: string;
  @Input() video: boolean;
  @Input() profile: Profile;
  @Input() session: Session;
  @Input() type: RecentInteraction.TypeEnum;
  @Input() message: string;

  @ViewChild('toneAudio', {static: false}) toneAudio: ElementRef;

  firstInterest: string;
  toneAudioVolume: number;
  toneInterval: any;
  apple: boolean;

  // TODO: App - Implement swipe to close https://ionicframework.com/docs/utilities/gestures, https://www.npmjs.com/package/ionic-swipe-all
  constructor(
    public user: UserService,
    public loadingService: LoadingService,
    public callService: CallService,
    public callApi: CallApi,
    public ws: WsService,
    public auth: AuthService,
    public chatter: ChatService,
    public translate: TranslateService,
    public router: Router,
    public toastCtrl: ToastController,
    public platform: Platform,
  ) {
    super(loadingService);

    this.init();
  }

  get recentInteractionTypeEnum() {
    return RecentInteraction.TypeEnum;
  }

  init() {
    this.cancelAllSubscriptionsAndPromises();

    if (this.session && (!this.sessionId || !this.profile)) {
      this.processSession(this.session);
    }

    if (this.profile && this.profile.id) {
      this.userId = this.profile.id;
    }

    this.firstInterest = null;
    this.toneAudioVolume = 0;
    this.toneInterval = null;
    this.apple = null;
  }

  ionViewWillEnter(): void {
    this.init();

    this.apple = this.platform.is('ios') || false;

    const rejectCall = session => {
      if (
        session && session.id
        && this.sessionId && session.id === this.sessionId
      ) {
        this.rejectCall();
      }
    };

    this.subscribe(this.ws.callRejected, rejectCall);
    this.subscribe(this.ws.callFinished, rejectCall);

    if (this.userId) {
      this.subscribe(this.user.getUserProfile(this.userId), res => this.setProfile(res.data));
    }

    if (this.sessionId) {
      this.subscribe(this.callApi.getCall(this.sessionId), res => {
        if (!res.isSuccess || !res.data) {
          // this.rejectCall();
          return;
        }

        this.processSession(res.data);
      });
    }

    this.toneAudioVolume = 0.5;
    this.toneInterval = setInterval(() => {
      const potential = (Math.round(this.toneAudioVolume * 10) + 1) / 10;

      if (potential <= 1) {
        this.toneAudioVolume = potential;
      }

      if (this.toneAudioVolume >= 1) {
        clearInterval(this.toneInterval);
        this.toneInterval = null;
        return;
      }
    }, 1000); // increase the tone volume
  }

  setProfile(profile: Profile) {
    this.profile = profile;
    this.firstInterest = this.auth.getProfileFirstInterest(profile);
  }

  processSession(session: Session) {
    this.session = session;
    if (!this.session) {
      this.sessionId = null;
      return;
    }

    this.sessionId = this.session.id;

    this.setProfile(this.auth.other(this.session.recipient, this.session.sender));
  }

  async reactToUserClick() {
    if (this.propertyId) {
      this.router.navigate(['/profile/' + this.profile.id + (this.connectionId ? '/property/' + this.propertyId : '')]).then();
    } else {
      this.router.navigate(['/profile/' + this.profile.id + (this.connectionId ? '/interaction/' + this.connectionId : '')]).then();
    }

    this.closeModal();
  }

  startCall(video = false, initiator = false) {
    this.callService.call(
      this.profile ? this.profile : this.userId,
      video,
      initiator,
      this.session ? this.session : this.sessionId
    ).then();

    this.closeModal();
  }

  rejectCall() {
    this.callApi.rejectCall(this.sessionId).toPromise().then(); // this should run no matter what

    this.closeModal();
  }

  closeModal() {
    if (this.modal && this.modal.dismiss) {
      this.modal.dismiss().then();
    }
  }

  stopTone() {
    if (this.toneAudio && this.toneAudio.nativeElement && this.toneAudio.nativeElement.srcObject) {
      const tracks = this.toneAudio.nativeElement.srcObject.getTracks();
      if (tracks) {
        tracks.forEach(track => track.stop());
      }
    }

    if (this.toneInterval) {
      clearInterval(this.toneInterval);
    }
  }

  openChat() {
    this.subscribe(this.chatter.registerConversation({toUserId: this.profile.id}), async chatResponse => {
      if (!chatResponse.data || !chatResponse.data.id) {
        const toast = await this.toastCtrl.create({
          ...environment.toast,
          color: 'danger',
          message: await this.translate.get('answer.chat-failed').toPromise(),
          closeButtonText: await this.translate.get('toast.close').toPromise()
        });
        toast.present();
        return;
      }

      this.router.navigate(['/chat/' + chatResponse.data.id]).then();

      this.closeModal();
    }, null, null, true);
  }

  cancelAllSubscriptionsAndPromises() {
    super.cancelAllSubscriptionsAndPromises();

    console.log('cancelAllSubscriptionsAndPromises');

    this.stopTone();
  }
}
